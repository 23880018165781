import React, { useState } from "react";

// import ZonesDraw from "components/Config/ZonesDraw.jsx";
import { definitionForm } from "components/Config/DefinitionForm.jsx";
import { DeleteConfirmOverlayButton } from "components/Config/DeleteConfirmOverlayButton.jsx";
import { bakeryConfigSchema } from "variables/schemas/app_configs/bakery.jsx";
import { checkoutConfigSchema,fullnessCheckoutConfigSchema } from "variables/schemas/app_configs/checkout.jsx";
import { entranceConfigSchema } from "variables/schemas/app_configs/entrance.jsx";
import { fullnessEntranceConfigSchema } from "variables/schemas/app_configs/fullness_entrance.jsx";
import { retoureCheckConfigSchema } from "variables/schemas/app_configs/retoure_check.jsx";
import { scoConfigSchema } from "variables/schemas/app_configs/sco.jsx";
import { appDefinitionSchema, createAppModelField } from "variables/schemas/app_definition.jsx";
import { evidenceParamsSchema } from "variables/schemas/evidence_params.jsx";
import { NotificationManager } from "react-notifications";
import Api from "modules/api.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Edit from "@material-ui/icons/Edit";
import Notifications from "@material-ui/icons/Notifications";
import PhotoSizeSelectSmallIcon from "@material-ui/icons/PhotoSizeSelectSmall";
import SettingsIcon from "@material-ui/icons/Settings";
import "@recogito/annotorious/dist/annotorious.min.css";
import cardStyle from "assets/jss/material-dashboard-pro-react/components/cardStyle.jsx";
import classNames from "classnames";
import CardBody from "components/Card/CardBody.jsx";
import StateButton from "components/Config/StateButton.jsx";
import TestAlarmButton from "components/Config/TestAlarmButton.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import Overlay from "react-overlay-component";
import AlarmProfile from "views/AlarmProfile.jsx";
import Zones from "views/Zones.jsx";


const appParamsSchemaByType = {
  bakery: bakeryConfigSchema,
  entrance: entranceConfigSchema,
  fullness_entrance: fullnessEntranceConfigSchema,
  exit_control: entranceConfigSchema,
  checkout: checkoutConfigSchema,
  checkout_on_exit: checkoutConfigSchema,
  fullness_checkout: fullnessCheckoutConfigSchema,
  checkout_old: checkoutConfigSchema,
  sco: scoConfigSchema,
  retoure_check: retoureCheckConfigSchema,
};

const overlayConfigs = {
  animate: true,
  clickDismiss: true,
  escapeDismiss: true,
  focusOutline: true,
  contentClass: "overlay-container",
};


function AppCard({ ...props }) {
  const [isOpen, setOverlay] = useState(false);
  const {
    classes,
    className,
    plain,
    background,
    color,
    instance_id,
    definitions_file_id,
    app,
    perspectiveInfo,
    updateFunction,
    deleteFunction,
    statusToggleFunction,
    startTestAlarmFunction,
    availableAppModels,
    availableFaceModels,
    availableSources,
    availableAlarmProfiles,
    availableZonesFiles,
    ...rest
  } = props;
  if (!app) {
    return null;
  }
  const cardClasses = classNames({
    [classes.card]: true,
    [classes.cardPlain]: plain,
    [classes.cardBackground]: background,
    [classes[color]]: color,
    [className]: className !== undefined
  });
  const closeOverlay = () => setOverlay(false);

  function updateApp(appID, appDefinition) {
    // uglyyy fix
    if (typeof(appDefinition.app_params.client_id) == "number") {
      appDefinition.app_params.client_id = appDefinition.app_params.client_id.toString();
    }
    updateFunction(appID, appDefinition);
    closeOverlay();
  }

  function updatePerspectiveID(sourceID, newPerspectiveID) {
    const key = "perspective_id";
    console.log("Updating perspective ID for source: " + sourceID + " to " + newPerspectiveID + ".");
    Api.put(`/api/instances/${instance_id}/${definitions_file_id}/sources/${sourceID}/update/${key}`, {value: newPerspectiveID}).then(() => {
      NotificationManager.success(`Successfully updated ${key} to ${newPerspectiveID}`, `Source ${sourceID} Updated`);
    }).catch((e) => {NotificationManager.error(e.message, `Could not update ${key} for ${sourceID}.`);});
  }

  function createZonesFileFunction(zonesFile) {
    const newAppDefinition = {...app};
    newAppDefinition.zones = zonesFile;
    updateFunction(app.name, newAppDefinition);
  }

  const updateStatus = () => {statusToggleFunction(app);};

  function showAppModel(model_use, model_name) {
    return (<span key={model_name}><b>{model_use}: </b> {model_name} <br/> </span>);}

  function showAppModels() {
    let elements = [];
    if (app.app_models) {
      for (const [key, value] of Object.entries(app.app_models)) {
        elements.push(showAppModel(key, value));
      }
    }
    return elements;
  }

  let backgroundColor = "#fafafa";
  let fontColor = "#AAAAAA";
  if (app.enabled !== false) {
    backgroundColor = "#FFFFFF";
    fontColor = "#333333";
  }

  fixAbsolutePaths(app, availableZonesFiles, availableAlarmProfiles);
  removeDeprecatedSourcesField(app);
  const appID = app.name;
  let schema = appDefinitionSchema;
  schema.properties.app_models.properties = createAppModelField(app.type, availableAppModels);
  schema.properties.face_model.allowedValues = availableFaceModels;
  schema.properties.source.allowedValues = availableSources;
  schema.properties.alarm_profile.allowedValues = availableAlarmProfiles;
  schema.properties.zones.allowedValues = availableZonesFiles;
  schema.properties.app_params = appParamsSchemaByType[app.type];
  schema.properties.evidence_params = evidenceParamsSchema;

  // const zonesDrawConfig = {
  //   image: document.getElementById('my-image'),
  //   locale: 'auto',
  // }
  // const anno = new Annotorious.init(zonesDrawConfig)

  const configTabs = [
    {
      tabName: " Config ",
      tabIcon: SettingsIcon,
      tabContent: (
        <div>
          {definitionForm(schema, appID, app, updateApp)}
        </div>
      )
    }];

  configTabs.push({
    tabName: " Zones ",
    tabIcon: PhotoSizeSelectSmallIcon,
    tabContent: (
      <Zones {...props} instance_id={instance_id} definitions_file_id={definitions_file_id} app_id={app.name} zones_path={app.zones} availableZonesFiles={availableZonesFiles} createZonesFileFunction={createZonesFileFunction} updatePerspectiveIDFunction={updatePerspectiveID} perspectiveInfo={perspectiveInfo}/>
    )
  });
  configTabs.push({
    tabName: " Alarms ",
    tabIcon: Notifications,
    tabContent: (
      <AlarmProfile {...props} instance_id={instance_id} definitions_file_id={definitions_file_id} app_id={app.name} profile_path={app.alarm_profile} availableProfilesFiles={availableAlarmProfiles}/>
    )
  });
  return (
    <GridItem xs={12} sm={12} md={6}>
      <div className={cardClasses} {...rest} style={{ background: backgroundColor}}>
        <GridItem
          xs={12}
          style={{
            width: "100%",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <StateButton
            key={app.name + "State"}
            status={app.enabled !== false}
            updateFunction={updateStatus}
          />
          <h5 className={classes.cardCategory} style={{fontWeight: 600, color: fontColor, display:"flex", overflow: "hidden", boxShadow: "inset 6px 6px 10px 0 rgba(50,50, 50, 0.05), inset -6px -6px 10px 0 rgba(255, 255, 255, 0.48)", background: "linear-gradient(-45deg, rgba(80, 80, 80, 0.03), rgba(255,255, 255, 0.4))", borderRadius: "5px", width: "82%", marginLeft: "auto", marginRight: "5%", marginTop: "-3%", marginBottom: "2%", paddingLeft: "4%", paddingTop:"2%", paddingBottom: "2%"}}>
            {app.name}
          </h5>
        </GridItem>
        <CardBody
          style={{
            width: "92%",
            marginLeft: "4%",
            marginRight: "10%",
            marginBottom: "2%",
          }}>
          <span style={{fontFamily: "'Fira Mono', 'Helvetica'", color: fontColor, fontSize: "12px"}}>
            <p><b>type: </b>{app.type}</p>
            <p><b>source: </b>{app.source}</p>
            {showAppModels()}
            <br />
          </span>
          <DeleteConfirmOverlayButton
            item={app}
            deleteFunction={deleteFunction}
          />
          <TestAlarmButton
            app={app}
            startTestAlarmFunction={startTestAlarmFunction}
          />
          <Button
            style={{ float: "right", marginRight: "-2%",  width: "23%", background: "#333333", boxShadow: "inset 5px 5px 8px 0 rgba(0, 0, 0, 0.25), inset -5px -5px 8px 0 rgba(255, 255, 255, 0.15)"}}
            onClick={() => setOverlay(true)}
          >
            <Edit style={{ float: "center", color: "#FFFFFF" }} />
          </Button>
        </CardBody>
      </div>
      <div>
        <Overlay
          configs={overlayConfigs}
          isOpen={isOpen}
          closeOverlay={closeOverlay}
        >
          <h3> {appID} </h3>
          <br />
          <CustomTabs
            headerColor="info"
            tabs={configTabs}
          />
        </Overlay>
      </div>
    </GridItem>
  );
}


AppCard.propTypes = {
  classes: PropTypes.object.isRequired,
  // t: PropTypes.func.isRequired,
  className: PropTypes.string,
  plain: PropTypes.bool,
  background: PropTypes.bool,
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.elementType
  ]),
  instance_id: PropTypes.string.isRequired,
  definitions_file_id: PropTypes.string.isRequired,
  app: PropTypes.object,
  perspectiveInfo: PropTypes.object,
  updateFunction: PropTypes.func,
  deleteFunction: PropTypes.func,
  statusToggleFunction: PropTypes.func,
  startTestAlarmFunction: PropTypes.func,
  availableAppModels: PropTypes.array,
  availableFaceModels: PropTypes.array,
  availableSources: PropTypes.array,
  availableAlarmProfiles: PropTypes.array,
  availableZonesFiles: PropTypes.array,
  // required: PropTypes.oneOfType([
  //   PropTypes.bool,
  //   PropTypes.array
  // ]),
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ]),
};

function makePathRelativeIfAvailable(path, availablePaths) {
  if (path && path.startsWith("/")) {
    for (const availablePath of availablePaths) {
      if (path.endsWith(availablePath)) {
        return availablePath;
      }
    }
  }
  return path;
}


function fixAbsolutePaths(app, availableZonesFiles, availableAlarmProfiles) {
  if (app.zones) {
    app.zones = makePathRelativeIfAvailable(app.zones, availableZonesFiles);
  }

  if (app.alarm_profile) {
    app.alarm_profile = makePathRelativeIfAvailable(app.alarm_profile, availableAlarmProfiles);
  }
}

function removeDeprecatedSourcesField(app) {
  // use of sources: will be converted to source - should likely not be here...
  if (app.sources) {
    if (app.sources.length > 0) {
      app.source = app.sources[0];
    }
    delete app.sources;
  }
}

export default withStyles(cardStyle)(AppCard);
