const entranceConfigSchema = {
  "title": "Entrance App Params",
  // "description": "app_params for entrance app_type",
  "type": "object",
  "properties": {
    "log_level": {
      "title": "Log Level",
      "default": "INFO",
      "type": "string"
    },
    "fps": {
      "title": "Target FPS",
      // "default": 30.0,
      "type": "number"
    },
    "incremental_frame_ids": {
      "title": "Incremental Frame Ids (non real-time)",
      "type": "boolean"
    },
    "alarm_on_zone_enter": {
      "title": "Alarm On Zone Enter",
      // "default": false,
      "type": "boolean"
    },
    "wait_for_next_cls": {
      "title": "Wait For Next Classification",
      // "default": 0.4,
      "type": "number"
    },
    "classification_limit": {
      "title": "Classification Limit",
      // "default": 4,
      "type": "integer"
    },
    "match_tracklet_trajectories": {
      "title": "Match Tracklet Trajectories",
      // "default": false,
      "type": "boolean"
    },
    // "evidence": {
    //   "title": "Evidence",
    //   // "default": "./evidence",
    //   "type": "string"
    // },
    "save_video": {
      "title": "Save Video",
      "type": "string"
    },
    "save_non_alarms": {
      "title": "Save Non Alarms",
      // "default": false,
      "type": "boolean"
    },
    "save_test_images": {
      "title": "Save Test Images",
      // "default": false,
      "type": "boolean"
    },
    "write_all_tracklets_in_cvat": {
      "title": "Write all tracklets Cvat",
      // "default": false,
      "type": "boolean"
    },
    "save_extra_seconds": {
      "title": "Save Extra Seconds",
      // "default": 0,
      "type": "integer"
    },
    "display": {
      "title": "Display",
      // "default": false,
      "type": "boolean"
    },
    "visualiser_rtsp_stream": {
      "title": "Visualiser Rtsp Stream",
      // "default": false,
      "type": "boolean"
    },
    "fullscreen": {
      "title": "Fullscreen",
      // "default": false,
      "type": "boolean"
    },
    "display_width": {
      "title": "Display Width",
      // "default": 1920,
      "type": "integer"
    },
    "display_height": {
      "title": "Display Height",
      // "default": 1080,
      "type": "integer"
    },
    "visualize_detections": {
      "title": "Visualize Detections",
      // "default": false,
      "type": "boolean"
    },
    "evaluate_tracker": {
      "title": "Evaluate Tracker",
      "type": "string"
    },
    "redemption_secs": {
      "title": "Redemption Secs",
      // "default": 120,
      "type": "integer"
    },
    "full_cls_threshold": {
      "title": "Full Cls Threshold",
      // "default": 0.6,
      "type": "number"
    },
    "extra_cls_threshold": {
      "title": "Extra Cls Threshold",
      // "default": 0.5,
      "type": "number"
    },
    "min_hits": {
      "title": "Min Hits",
      // "default": 3,
      "type": "integer"
    },
    "max_age": {
      "title": "Max Age",
      // "default": 1.0,
      "type": "number"
    },
    "cls_avg_on_exit": {
      "title": "Cls Avg On Exit",
      // "default": false,
      "type": "boolean"
    },
    "http_proxy": {
      "title": "Http Proxy",
      "type": "string"
    },
    "alarm_triggering_classes": {
      "title": "Alarm Triggering Classes",
      // "default": [
      //   "basket",
      //   "cart"
      // ],
      "type": "array",
      "items": {
        "type": "string"
      },

    },
    "required_object_classes": {
      "title": "Required Object Classes",
      "type": "array",
      "items": {
        "type": "string"
      },
    },
    // "supported_object_classes": {
    //   "title": "Supported Object Classes",
    //   // "default": [
    //   //   "person",
    //   //   "basket",
    //   //   "cart"
    //   // ],
    //   "type": "array",
    //   "items": {
    //     "type": "string"
    //   },
    // },
    // "supported_zones": {
    //   "title": "Supported Zones",
    //   // "default": [
    //   //   "bad",
    //   //   "good",
    //   //   "trigger",
    //   //   "bad_exit",
    //   //   "redemption",
    //   //   "ignore_dets"
    //   // ],
    //   "type": "array",
    //   "items": {
    //     "type": "string"
    //   }
    // },
    // "supported_app_model_types": {
    //   "title": "Supported App Model Types",
    //   // "default": [
    //   //   "classifier"
    //   // ],
    //   "type": "array",
    //   "items": {
    //     "type": "string"
    //   },
    // },
  },
  "required": []
};

export {entranceConfigSchema};
