import LoginPage from "views/Pages/LoginPage.jsx";

// @material-ui/icons
import Fingerprint from "@material-ui/icons/Fingerprint";

const loginRoutes = [
  // {
  //   path: "/pages/register-page",
  //   name: "Register Page",
  //   short: "Register",
  //   mini: "RP",
  //   icon: PersonAdd,
  //   component: RegisterPage
  // },
  {
    path: "/login",
    name: "Login Page",
    short: "Login",
    mini: "LP",
    icon: Fingerprint,
    component: LoginPage,
    public: true,
  },
  {
    path: "/pages/login",
    redirect: true,
    pathTo: "/login",
    name: "Login Page",
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/login",
    name: "Login Page",
  },
];

export default loginRoutes;
