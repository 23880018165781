const evidenceStrategySchema = {
  title: "Evidence Upload Strategy",
  type: "object",
  properties: {
    selection_strategy: {
      type: "string",
      title: "Selection Strategy",
      description: "Selection strategy",
      allowedValues: ["always", "random", "uncertainty", "confidence", "only_nightly"],
    },
    filter_by: {
      type: "string",
      title: "Filter By",
      description: "kind/tags/reported/sco product types",
      allowedValues: ["kind", "tags", "reported", "matched_products", "lost_products", "swapped_products",
        "unmatched_products"],
    },
    max_evidence_per_day_per_kind:{
      anyOf: [
        {
          "type": "number",
          "minimum": 0,
          "maximum": 360,
        },
        {
          "type": "null"
        }
      ]
    },
  },
  required: ["selection_strategy", "filter_by"],
};

export { evidenceStrategySchema };
