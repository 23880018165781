const checkoutConfigSchema = {
  "title": "Checkout Params",
  "description": "app_params for checkout app_type",
  "type": "object",
  "properties": {
    "log_level": {
      "title": "Log Level",
      "default": "INFO",
      "type": "string"
    },
    "fps": {
      "title": "Fps",
      // "default": 30.0,
      "type": "number"
    },
    "show_only_crop": {
      "title": "Show Only Crop",
      // "default": false,
      "type": "boolean"
    },
    "alarm_on_zone_enter": {
      "title": "Alarm On Zone Enter",
      // "default": false,
      "type": "boolean"
    },
    "wait_for_next_cls": {
      "title": "Wait For Next Classification",
      // "default": 0.4,
      "type": "number"
    },
    "classification_limit": {
      "title": "Classification Limit",
      // "default": 4,
      "type": "integer"
    },
    "match_tracklet_trajectories": {
      "title": "Match Tracklet Trajectories",
      // "default": false,
      "type": "boolean"
    },
    // "evidence": {
    //   "title": "Evidence",
    //   // "default": "./evidence",
    //   "type": "string"
    // },
    "write_all_tracklets_in_cvat": {
      "title": "Write all tracklets Cvat",
      // "default": false,
      "type": "boolean"
    },
    "save_video": {
      "title": "Save Video",
      "type": "string"
    },
    "save_non_alarms": {
      "title": "Save Non Alarms",
      // "default": false,
      "type": "boolean"
    },
    "save_test_images": {
      "title": "Save Test Images",
      // "default": false,
      "type": "boolean"
    },
    "save_all_evidences": {
      "title": "Save All Evidences",
      // "default": false,
      "type": "boolean"
    },
    "save_receipt_lines": {
      "title": "Save Receipt Lines",
      // "default": false,
      "type": "boolean"
    },
    "sync_report_with_transaction": {
      "title": "Sync Report With Transaction",
      // "default": false,
      "type": "boolean"
    },
    "save_extra_seconds": {
      "title": "Save Extra Seconds",
      // "default": 0,
      "type": "integer"
    },
    "display": {
      "title": "Display",
      // "default": false,
      "type": "boolean"
    },
    "visualiser_rtsp_stream": {
      "title": "Visualiser Rtsp Stream",
      // "default": false,
      "type": "boolean"
    },
    "fullscreen": {
      "title": "Fullscreen",
      // "default": false,
      "type": "boolean"
    },
    "display_width": {
      "title": "Display Width",
      // "default": 1920,
      "type": "integer"
    },
    "display_height": {
      "title": "Display Height",
      // "default": 1080,
      "type": "integer"
    },
    "visualize_detections": {
      "title": "Visualize Detections",
      // "default": false,
      "type": "boolean"
    },
    "http_proxy": {
      "title": "Http Proxy",
      "type": "string"
    },
    "client_id": {
      "title": "Client ID",
      // "default": "client1",
      "type": "string"
      // "type" : "object",
      // "oneOf" : [{"type": "string"},  {"type": "integer"}]
    },
    "time_to_display": {
      "title": "Time To Display",
      // "default": -1,
      "type": "integer"
    },
    "full_cls_threshold": {
      "title": "Full Cls Threshold",
      // "default": 0.6,
      "type": "number"
    },
    "max_age": {
      "title": "Max Age",
      // "default": 1.0,
      "type": "number"
    },
  },
  "required": []
};

let fullnessCheckoutConfigSchema = checkoutConfigSchema;
fullnessCheckoutConfigSchema.properties.fullness_trigger_threshold = {
  "title": "Fullness Trigger Threshold",
  "type": "number"
};
fullnessCheckoutConfigSchema.properties.extra_cls_threshold= {
  "title": "Extra Cls Threshold",
  "type": "number"
};
export {checkoutConfigSchema, fullnessCheckoutConfigSchema};
