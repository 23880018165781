import Api from "./api";
import {NotificationManager} from "react-notifications";

function isSafePath(path, type) {
  return (path && path.match(type) &&
        path.match(/\.json$/, "i") &&
        !path.match(/\.\./, "i") &&
        !path.match(/^\//, "i"));
}

function saveJSON(raw_json, path, type, endpoint) {
  if (!isSafePath(path, type)) {
    NotificationManager.error(path, "Invalid file path!");
    return false;
  }
  let parsed_json;
  try {
    parsed_json = JSON.parse(raw_json);
  } catch (e) {
    NotificationManager.error(e.message, "Could not parse json!");
    return false;
  }
  parsed_json && Api.post(endpoint, {filename: path, raw_json: JSON.stringify(parsed_json)}).then(() => {
    NotificationManager.success(`Saved ${type} file at ${path}`);
  });
  return true;

  // NotificationManager.error(e.message, "Could not save json file!");
}

export {isSafePath, saveJSON};
