const evidenceUploadSchema = {
  title: "Evidence Upload Global Config",
  type: "object",
  properties: {
    upload_data_limit:{
      type: "integer",
      title: "Maximum amount of evidence to upload per month (Gigabyte)",
      description:"",
      default: 3
    },
    selective_upload_data_limit:{
      type: "number",
      title: "Percentage to stop selective uploading (float)",
      description:"Stop uploading extra data if data usage is above this value",
      default: 0.8,
      minimum: 0.0,
      maximum: 1.0
    }
  },
  required: ["upload_data_limit", "selective_upload_data_limit"],
};

export {evidenceUploadSchema};
