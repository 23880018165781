const appDefinitionSchema = {
  title: "App Definition",
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "App Name/App ID",
      description: "App ID",
      min: 1,
    },
    type: {
      type: "string",
      title: "App Type",
      description: "App Type (when changing this, press Submit before changing other fields)",
      allowedValues: ["entrance", "fullness_entrance", "exit_control", "checkout", "checkout_on_exit", "fullness_checkout", "checkout_old", "sco", "retoure_check", "bakery"],
    },
    source: {
      type: "string",
      title: "Source",
      description: "The Source the App runs on",
      allowedValues: []
    },
    app_models: {
      type: "object",
      title: "App Models",
      properties: {},
      default: {},
      required: []
    },
    face_model: {
      type: "string",
      title: "Face Model",
      description: "The Face Detection Model for Face Anonymization",
      allowedValues: []
    },
    alarm_profile: {
      type: "string",
      title: "Alarm Profile Path",
      description: "To configure alarms",
      min: 1,
      allowedValues: []
    },
    zones: {
      type: "string",
      title: "Zones File Path",
      description: "Path to the zones file",
      min: 1,
      allowedValues: []
    },
    app_params: {
      type: "object",
      title: "App Params",
      properties: {},
      required: []
    },
    enabled: {
      type: "boolean",
      title: "Enabled",
      description: "Should this app run?",
      // default: true,
    },
    evidence_params: {
      type: "object",
      title: "Evidence Params",
      properties: {},
      required: []
    },
  },
  required: [
    "name",
    "type"
  ],
};

const appModelEntrySchema = {
  title: "App Model",
  type: "string",
  min: 1,
  allowedValues: []
};

function createAppModelField(appType, availableAppModels) {
  let appModelsFieldEntry = appModelEntrySchema;
  appModelsFieldEntry.allowedValues = availableAppModels;
  if (appType === "entrance" || appType === "exit_control" || appType === "checkout" || appType === "checkout_on_exit" || appType === "checkout_old") {
    appModelsFieldEntry.title = "Full/Empty Classifier";
    return {full_empty_classifier: appModelsFieldEntry};
  }
  if (appType === "fullness_entrance" || appType === "fullness_checkout") {
    appModelsFieldEntry.title = "Fullness Classifier";
    return {fullness_classifier: appModelsFieldEntry};
  }

  if( appType === "bakery") {
    appModelsFieldEntry.title = "Bakery Shelf Fullness Classifier";
    return {bakery_shelf_fullness_classifier: appModelsFieldEntry};
  }
  if(appType === "retoure_check") {
    appModelsFieldEntry.title =  "Person Detector";
    return {person_detector: appModelsFieldEntry};
  }
  if( appType === "sco" ) {
    appModelEntrySchema.title = "Dummy Classifier";
    return {full_empty_classifier: appModelsFieldEntry};
  }

  return {
    full_empty_classifier: appModelEntrySchema,
    fullness_classifier: appModelEntrySchema,
    person_detector: appModelEntrySchema,
    bakery_shelf_fullness_classifier: appModelEntrySchema,
  };
}
export {appDefinitionSchema, createAppModelField};
