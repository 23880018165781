import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import Add from "@material-ui/icons/Add";
import Clear from "@material-ui/icons/Clear";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { SessionContext } from "layouts/Dashboard.jsx";
import Api from "modules/api.js";
import withI18n from "modules/withI18n";
import PropTypes from "prop-types";
import React from "react";
import { NotificationManager } from "react-notifications";


class Config extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      globalConfig: [],
      cameraConfig: [],
      baseURL: `/api/config/${props.context.instance_id}`
    };
    this.handleCameraConfigAddLine = this.handleCameraConfigAddLine.bind(this);
    this.handleCameraConfigLineChange = this.handleCameraConfigLineChange.bind(this);
    this.handleCameraConfigRemoveLine = this.handleCameraConfigRemoveLine.bind(this);
    this.handleGlobalConfigAddLine = this.handleGlobalConfigAddLine.bind(this);
    this.handleGlobalConfigLineChange = this.handleGlobalConfigLineChange.bind(this);
    this.handleGlobalConfigRemoveLine = this.handleGlobalConfigRemoveLine.bind(this);
    this.saveGlobalConfig = this.saveGlobalConfig.bind(this);
    this.saveCameraConfig = this.saveCameraConfig.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.loadData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.context.instance_id !== prevProps.context.instance_id) {
      this._isMounted && this.setState({baseURL: `/api/config/${this.props.context.instance_id}`}, this.loadData);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  loadGlobalConfig() {
    Api.get(`${this.state.baseURL}/global_config`).then(res => {
      if (this._isMounted) {
        this.setState({
          ...this.state,
          ...{ globalConfig: res.data }
        }, this.loadCameraConfig);
      }
    }).catch((e) => {
      this.setState({globalConfig: [], cameraConfig: []});
      NotificationManager.error(e.message, `Could not fetch global config of ${this.props.context.instance_id}.`);
    });
  }

  loadCameraConfig() {
    Api.get(`${this.state.baseURL}/camera_config`).then(res => {
      if (this._isMounted) {
        this.setState({
          ...this.state,
          ...{ cameraConfig: res.config }
        });
      }
    }).catch(() => {
      this.setState({cameraConfig: []});
      // NotificationManager.error(e.message, `Could not fetch camera config of ${this.props.context.instance_id}.`);
    });
  }
  loadData() {
    if (typeof(this.props.context.instance_id) !== "undefined") {
      this.loadGlobalConfig();
    }
  }


  saveGlobalConfig() {
    Api.post(`${this.state.baseURL}/global_config`, {
      config: this.state.globalConfig
    }).then(() => {
      NotificationManager.success(`Global config of ${this.props.context.instance_id} was saved.`, "Success");
      this.loadGlobalConfig();
    }).catch((e) => {
      NotificationManager.error(e.message, `Could not save global config of ${this.props.context.instance_id}.`);
    });
  }

  saveCameraConfig() {
    Api.post(`${this.state.baseURL}/camera_config`, {
      config: this.state.cameraConfig
    }).then(() => {
      NotificationManager.success(`Camera config of ${this.props.context.instance_id} was saved.`, "Success");
      this.loadCameraConfig();
    }).catch((e) => {
      NotificationManager.error(e.message, `Could not save camera config of ${this.props.context.instance_id}.`);
    });
  }

  handleCameraConfigAddLine() {
    let newConfig = this.state.cameraConfig;
    (newConfig = newConfig || []).push("");
    this.setState({ cameraConfig: newConfig });
  }

  handleCameraConfigLineChange(idx, event) {
    let newConfig = this.state.cameraConfig;
    newConfig[idx] = event.target.value;
    this.setState({ cameraConfig: newConfig });
  }

  handleCameraConfigRemoveLine(idx) {
    let newConfig = this.state.cameraConfig;
    newConfig.splice(idx, 1);
    this.setState({ cameraConfig: newConfig });
  }
  handleGlobalConfigAddLine() {
    let newConfig = this.state.globalConfig;
    (newConfig = newConfig || []).push({});
    this.setState({ globalConfig: newConfig });
  }

  handleGlobalConfigLineChange(idx, event) {
    let newConfig = this.state.globalConfig;
    newConfig[idx].local = event.target.value;
    this.setState({ globalConfig: newConfig });
  }

  handleGlobalConfigRemoveLine(idx) {
    let newConfig = this.state.globalConfig;
    newConfig.splice(idx, 1);
    this.setState({ globalConfig: newConfig });
  }

  render() {
    const { classes } = this.props;

    return (
      <div style={{ marginBottom: "10%"}}
      >
        <GridContainer>
          <Card
            style={{
              width: "75%",
              marginRight: "auto",
              marginLeft: "auto"
            }}
          >
            <CardBody
              style={{
                width: "90%",
                marginRight: "auto",
                marginLeft: "auto",
                paddingTop: "4%",
                paddingBottom: "5%"
              }}
            >
              <h3 className={classes.cardIconTitle}>Global Config</h3>
              <br />
              <br />

              {this.state.globalConfig
                ? this.state.globalConfig.map((setting, idx) => (
                  <GridContainer
                    key={idx}
                    style={{
                      width: "100%",
                      marginRight: "auto",
                      marginLeft: "auto"
                    }}
                  >
                    <GridItem md={10} xs={12}>
                      <TextField
                        className={classes.textField}
                        label=""
                        fullWidth
                        placeholder={setting.default}
                        value={setting.local}
                        onChange={e =>
                          this.handleGlobalConfigLineChange(idx, e)
                        }
                      />
                    </GridItem>
                    {setting.local ?
                      <GridItem xs={2}>
                        <Button
                          className={classes.removeButton}
                          size="sm"
                          justIcon
                          round
                          color="danger"
                          onClick={() =>
                            this.handleGlobalConfigRemoveLine(idx)
                          }
                        >
                          <Clear />
                        </Button>
                      </GridItem>
                      : undefined}
                  </GridContainer>
                ))
                : undefined}
              <GridContainer
                style={{
                  width: "100%",
                  marginRight: "auto",
                  marginLeft: "auto",
                  paddingBottom: "1%",
                  paddingTop: "1%"
                }}
              >
                <GridItem xs={12}>
                  <Button
                    className={classes.addButton}
                    size="sm"
                    justIcon
                    round
                    color="info"
                    onClick={this.handleGlobalConfigAddLine}
                  >
                    <Add />
                  </Button>
                </GridItem>
              </GridContainer>
              <GridContainer
                style={{
                  width: "100%",
                  paddingTop: "2%",
                  marginBottom: "2%"
                }}
              >
                <Button
                  style={{
                    background: "#FFFFFF",
                    color: "#222222",
                    width: "75%",
                    marginRight: "auto",
                    marginLeft: "auto",
                    paddingBottom: "3%",
                    paddingTop: "3%",
                    marginTop: "7%"
                  }}
                  onClick={this.saveGlobalConfig}
                  color="success"
                  className={classes.updateConfigButton}
                >
                  Save
                </Button>
              </GridContainer>
            </CardBody>
          </Card>
          <Card
            style={{
              width: "75%",
              marginRight: "auto",
              marginLeft: "auto"
            }}
          >
            <CardBody
              style={{
                width: "85%",
                marginRight: "auto",
                marginLeft: "auto",
                paddingTop: "4%",
                paddingBottom: "5%"
              }}
            >
              <h3 className={classes.cardIconTitle}>Camera Settings</h3>
              <br />
              <br />
              {this.state.cameraConfig
                ? this.state.cameraConfig.map((setting, idx) => (
                  <GridContainer
                    key={idx}
                    style={{
                      width: "100%",
                      marginRight: "auto",
                      marginLeft: "auto"
                    }}
                  >
                    <GridItem md={10} xs={12}>
                      <TextField
                        className={classes.textField}
                        label=""
                        fullWidth
                        placeholder={"setting"}
                        value={setting}
                        onChange={e =>
                          this.handleCameraConfigLineChange(idx, e)
                        }
                      />
                    </GridItem>
                    <GridItem xs={2}>
                      <Button
                        className={classes.removeButton}
                        size="sm"
                        justIcon
                        round
                        color="danger"
                        onClick={() => this.handleCameraConfigRemoveLine(idx)}
                      >
                        <Clear />
                      </Button>
                    </GridItem>
                  </GridContainer>
                ))
                : undefined}
              <GridContainer
                style={{
                  width: "100%",
                  marginRight: "auto",
                  marginLeft: "auto",
                  paddingBottom: "1%",
                  paddingTop: "1%"
                }}
              >
                <GridItem xs={12}>
                  <Button
                    className={classes.addButton}
                    size="sm"
                    justIcon
                    round
                    color="info"
                    onClick={this.handleCameraConfigAddLine}
                  >
                    <Add />
                  </Button>
                </GridItem>
              </GridContainer>
              <GridContainer
                style={{
                  width: "100%",
                  paddingTop: "2%",
                  marginBottom: "2%"
                }}
              >
                <Button
                  style={{
                    background: "#FFFFFF",
                    color: "#222222",
                    width: "75%",
                    marginRight: "auto",
                    marginLeft: "auto",
                    paddingBottom: "3%",
                    paddingTop: "3%",
                    marginTop: "7%"
                  }}
                  onClick={this.saveCameraConfig}
                  color="success"
                  className={classes.updateConfigButton}
                >
                  Save
                </Button>
              </GridContainer>
            </CardBody>
          </Card>
        </GridContainer>
      </div>
    );
  }
}

Config.propTypes = {
  classes: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
};


function withSessionContext(Component) {
  return function WrapperComponent(props) {
    return (
      <SessionContext.Consumer>
        {(state) => <Component {...props} context={state} />}
      </SessionContext.Consumer>
    );
  };
}



export default withSessionContext(withI18n(withStyles(userProfileStyles)(Config)));
