import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import Add from "@material-ui/icons/Add";
import Check from "@material-ui/icons/Check";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import {monospaceFont} from "assets/jss/material-dashboard-pro-react.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Api from "modules/api.js";
import PropTypes from "prop-types";
import React from "react";
import {isSafePath, saveJSON} from "modules/utils.jsx";
import {NotificationManager} from "react-notifications";
import {createValidator} from "variables/schemas.jsx";
import {
  alarmProfileGeneralInfoSchema,
  timeConstrainedAlarmsSchema,
} from "variables/schemas/alarmProfile.jsx";
import withI18n from "modules/withI18n";

import {JSONSchemaBridge} from "uniforms-bridge-json-schema";
import {AutoForm} from "uniforms-material";
import CustomInput from "../components/CustomInput/CustomInput";
import Save from "@material-ui/icons/Save";
import {EvidenceStrategiesBlock, EvidenceGeneralConfigBlock} from "components/Config/EvidenceStrategy";


class AlarmProfile extends React.Component {
  constructor(props) {
    super(props);
    // const route = `${props.instance_id}/${props.definitions_file_id}/apps/${props.app_id}`;
    const routeCreation = `${props.instance_id}/${props.definitions_file_id}/${props.app_id}`;
    if (!props.profile_path) {
      this.state = {
        profile_path: null,
        profile_raw_json: "{}",
        new_profile_path: "",
        availableProfilesFiles: props.availableProfilesFiles || [],
      };
      this.file_type = "profile";
      this.jsonEndpoint = `/api/json/${this.file_type}/${routeCreation}`;
      this.changeProfileRawJson = this.changeProfileRawJson.bind(this);
      this.changeNewProfilePath = this.changeNewProfilePath.bind(this);
      return;
    }
    this.handleAddPhone = this.handleAddPhone.bind(this);
    this.handleAddEmail = this.handleAddEmail.bind(this);
    this.handleAddHTTP = this.handleAddHTTP.bind(this);
    this.handleAddHTTPHeader = this.handleAddHTTPHeader.bind(this);

    this.handleAddTelegram = this.handleAddTelegram.bind(this);
    this.handleAddThreema = this.handleAddThreema.bind(this);
    this.handleAddGPIO = this.handleAddGPIO.bind(this);
    this.handleAddRelay = this.handleAddRelay.bind(this);
    this.save = this.save.bind(this);
    this.saveGeneralInfo = this.saveGeneralInfo.bind(this);
    this.save_evidence_profile = this.save_evidence_profile.bind(this);
    this.addStrategy = this.addStrategy.bind(this);
    this.deleteStrategy = this.deleteStrategy.bind(this);
    this.updateStrategy = this.updateStrategy.bind(this);
    this.updateEvidenceGeneralConfig = this.updateEvidenceGeneralConfig.bind(this);
    this.saveTimeConstrainedAlarms = this.saveTimeConstrainedAlarms.bind(this);

    this.generalInfoSchema = alarmProfileGeneralInfoSchema;
    this.timeConstrainedAlarmsSchema = timeConstrainedAlarmsSchema;
    this.profileMessage = "";

    const profile = this.cleanUpProfile({});

    const generalInfoSchemaValidator = createValidator(
      this.generalInfoSchema,
      NotificationManager
    );
    const generalInfoSchemaBridge = new JSONSchemaBridge(
      this.generalInfoSchema,
      generalInfoSchemaValidator
    );

    const timeConstrainedAlarmsSchemaValidator = createValidator(
      this.timeConstrainedAlarmsSchema,
      NotificationManager
    );
    const timeConstrainedAlarmsSchemaBridge = new JSONSchemaBridge(
      this.timeConstrainedAlarmsSchema,
      timeConstrainedAlarmsSchemaValidator
    );

    // let profile_path = "/etc/opt/cartwatch/profile.json";

    let small_version = false;
    if (props.app_id) {
      small_version = true;
    }
    this.state = {
      instance_id: props.instance_id,
      definitions_file_id: props.definitions_file_id,
      app_id: props.app_id,
      profile_path: props.profile_path,
      affectedApps: [],
      profile: profile,
      generalInfoSchemaBridge: generalInfoSchemaBridge,
      timeConstrainedAlarmsSchemaBridge: timeConstrainedAlarmsSchemaBridge,
      small_version: small_version,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    if (
      !this.state.profile_path ||
            !this.state.instance_id ||
            !this.state.definitions_file_id ||
            !this.state.app_id
    ) {
      return;
    }
    let endpoint = `/api/instances/${this.state.instance_id}/${this.state.definitions_file_id}/apps/${this.state.app_id}/alarm_profile`;
    Api.get(endpoint)
      .then((res) => {
        const profile = this.cleanUpProfile(res.data.alarm_profile);
        this.setState({
          ...this.state,
          ...{
            profile: profile,
            profile_path: res.data.abs_path || this.state.profile_path,
            affectedApps: res.data.affected_apps,
          },
        });
      })
      .catch((e) => {
        NotificationManager.error(
          e.message,
          `Could not fetch alarm profile of ${this.state.app_id}.`
        );
      });
  }

  cleanUpProfile(obj) {
    this.delNullKeys(obj);

    obj.alarm = obj.alarm || {};

    obj.alarm.alarm_start = obj.alarm.alarm_start || "00:00";
    obj.alarm.alarm_end = obj.alarm.alarm_end || "23:00";
    obj.time_constraints = obj.time_constraints || {};
    obj.time_constraints.constrained_by_alarm_period =
            obj.time_constraints.constrained_by_alarm_period || [];

    obj.time_constraints.alarm_times = obj.time_constraints.alarm_times || {
      monday: {
        active: true,
        alarm_start: obj.alarm.alarm_start,
        alarm_end: obj.alarm.alarm_end,
      },
      tuesday: {
        active: true,
        alarm_start: obj.alarm.alarm_start,
        alarm_end: obj.alarm.alarm_end,
      },
      wednesday: {
        active: true,
        alarm_start: obj.alarm.alarm_start,
        alarm_end: obj.alarm.alarm_end,
      },
      thursday: {
        active: true,
        alarm_start: obj.alarm.alarm_start,
        alarm_end: obj.alarm.alarm_end,
      },
      friday: {
        active: true,
        alarm_start: obj.alarm.alarm_start,
        alarm_end: obj.alarm.alarm_end,
      },
      saturday: {
        active: true,
        alarm_start: obj.alarm.alarm_start,
        alarm_end: obj.alarm.alarm_end,
      },
      sunday: {
        active: false,
        alarm_start: obj.alarm.alarm_start,
        alarm_end: obj.alarm.alarm_end,
      },
    };
    obj.alarm.constrained_by_alarm_period =
            obj.alarm.constrained_by_alarm_period || [];
    obj.alarm.phone = obj.alarm.phone || [];
    obj.alarm.email = obj.alarm.email || [];
    obj.alarm.telegram = obj.alarm.telegram || [];
    obj.alarm.threema = obj.alarm.threema || [];
    obj.alarm.indyme = obj.alarm.indyme || {};
    if (obj.alarm.indyme instanceof Array) {
      if (obj.alarm.indyme.length > 0) {
        obj.alarm.indyme = obj.alarm.indyme[0];
      } else {
        obj.alarm.indyme = {};
      }
    }
    obj.alarm.gpio = obj.alarm.gpio || [];
    obj.alarm.relay = obj.alarm.relay || [];
    obj.alarm.axis = obj.alarm.axis || {vms_alarm_request_url: ""};
    obj.alarm.mqtt = obj.alarm.mqtt || [];
    obj.alarm.play_sound = obj.alarm.play_sound || {};
    obj.alarm.milestone = obj.alarm.milestone || {
      event_name: "",
      analytics_event_endpoint: "",
      camera_ip: "",
    };
    obj.alarm.http = obj.alarm.http || [];

    obj.evidence = obj.evidence || {};
    obj.evidence.strategies = obj.evidence.strategies || [];

    return obj;
  }

  nullify_empty_blocks(profile) {
    // please excuse this atrocity..
    // code needs to refactored to list based alarm profiles to avoid this
    let obj = Object.assign({}, profile);
    obj.alarm = Object.assign({}, profile.alarm);

    if (this.isObjEmpty(obj.alarm.indyme)) {
      delete obj.alarm.indyme;
    }

    if (this.isObjEmpty(obj.alarm.axis) || obj.alarm.axis.vms_alarm_request_url === "" ) {
      delete obj.alarm.axis;
    }
    if (this.isObjEmpty(obj.alarm.milestone) || obj.alarm.milestone.analytics_event_endpoint === "" ) {
      delete obj.alarm.milestone;
    }
    if (this.isObjEmpty(obj.alarm.play_sound) || obj.alarm.play_sound.path === "" ) {
      delete obj.alarm.play_sound;
    }

    return obj;
  }

  isObjEmpty(obj) {
    for (let key in obj)
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        return false;
      }
    return true;
  }

  delNullKeys(obj) {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        let val = obj[key];

        if (val === null) {
          delete obj[key];
          continue;
        }

        if ((Array.isArray && Array.isArray(val)) || val instanceof Array) {
          obj[key] = val
            .filter((e) => !!e && !this.isObjEmpty(e))
            .map(this.delNullKeys, this);
        } else {
          if (val instanceof Object) {
            this.delNullKeys(val);
            if (this.isObjEmpty(val)) {
              delete obj[key];
            }
          }
        }
      }
    }
    return obj;
  }

  save() {
    // let endpoint = `/api/instances/${this.state.instance_id}/${this.state.definitions_file_id}/apps/${this.state.app_id}/alarm_profile`;
    if (
      !this.state.instance_id ||
            !this.state.definitions_file_id ||
            !this.state.app_id
    ) {
      NotificationManager.error(
        "",
        "Can not update Alarm Profile without specified app"
      );
      return;
    }
    let endpoint = `/api/instances/${this.state.instance_id}/${this.state.definitions_file_id}/apps/${this.state.app_id}/alarm_profile`;
    let alarm_profile = this.cleanUpProfile(this.state.profile);
    Api.post(endpoint, this.nullify_empty_blocks(alarm_profile))
      .then(() => {
        NotificationManager.success(
          `Saved in ${this.state.profile_path}.`,
          `Updated Alarm Profile for ${this.state.app_id}!`
        );
      })
      .catch((e) => {
        NotificationManager.error(e.message, "Could not update Alarm Profile!");
        // console.error(e);
      });
    this.setState({alarm_profile: alarm_profile});
  }

  saveGeneralInfo(general_profile) {
    const profile = {
      ...this.state.profile,
      ...general_profile,
    };
    this.setState({profile}, this.save);
  }

  updateEvidenceGeneralConfig(props) {
    const profile = this.state.profile;
    profile.evidence = {...profile.evidence, ...props};
    this.setState({profile}, this.save);
  }

  updateStrategy(oldStrategy, newStrategy) {
    const profile = this.state.profile;
    const m = profile.evidence.strategies.map(strategy => {
      if (strategy.selection_strategy === oldStrategy.selection_strategy && strategy.filter_by === oldStrategy.filter_by && strategy.filter_value === oldStrategy.filter_value) {
        return {...strategy, ...newStrategy};
      }
      return strategy;
    });
    profile.evidence.strategies = m;
    this.setState({profile}, this.save);
  }


  deleteStrategy(strategy) {
    const profile = this.state.profile;
    profile.evidence.strategies.splice(profile.evidence.strategies.indexOf(strategy), 1);
    this.setState({profile}, this.save);
  }

  addStrategy(strategy) {
    const profile = this.state.profile;
    profile.evidence.strategies.push(strategy);
    this.setState({profile}, this.save);
  }

  save_evidence_profile(evidence) {
    const profile = this.state.profile;
    profile.evidence = evidence;
    this.setState({profile}, this.save);
  }

  saveTimeConstrainedAlarms(obj) {
    const profile = this.state.profile;
    profile.time_constraints.constrained_by_alarm_period =
            obj.constrained_by_alarm_period;
    profile.alarm.constrained_by_alarm_period = obj.constrained_by_alarm_period;
    this.setState({profile}, this.save);
  }

  handleAddPhone() {
    const {profile} = this.state;
    profile.alarm.phone.push({});
    this.setState({profile});
  }

  handlePhoneNameChange(idx, event) {
    const {profile} = this.state;
    profile.alarm.phone[idx].name = event.target.value;
    this.setState({profile});
  }

  handlePhoneNumberChange(idx, event) {
    const {profile} = this.state;
    profile.alarm.phone[idx].number = event.target.value;
    this.setState({profile});
  }

  handleRemovePhone(idx) {
    const {profile} = this.state;
    profile.alarm.phone.splice(idx, 1);
    this.setState({profile});
  }

  handleEmailNameChange(idx, event) {
    const {profile} = this.state;
    profile.alarm.email[idx].name = event.target.value;
    this.setState({profile});
  }

  handleEmailNumberChange(idx, event) {
    const {profile} = this.state;
    profile.alarm.email[idx].address = event.target.value;
    this.setState({profile});
  }

  handleAddEmail() {
    const {profile} = this.state;
    (profile.alarm.email = profile.alarm.email || []).push({});
    this.setState({profile});
  }

  handleRemoveEmail(idx) {
    const {profile} = this.state;
    profile.alarm.email.splice(idx, 1);
    this.setState({profile});
  }

  handleHTTPChanged(idx, e) {
    const {profile} = this.state;
    profile.alarm.http[idx][
      e.target.parentElement.parentElement.dataset.field
    ] = e.target.value;
    this.setState({profile});
  }

  handleHTTPDelayChanged(idx, e) {
    const {profile} = this.state;
    profile.alarm.http[idx][
      e.target.parentElement.parentElement.dataset.field
    ] = parseInt(e.target.value);
    this.setState({profile});
  }

  handleHTTPSChanged(idx) {
    const {profile} = this.state;
    let currentVal = profile.alarm.http[idx].https_verify;
    if (typeof currentVal !== "boolean") {
      currentVal = false;
    }
    profile.alarm.http[idx].https_verify = !currentVal;

    this.setState({profile});
  }

  handleAddHTTP() {
    const {profile} = this.state;
    (profile.alarm.http = profile.alarm.http || []).push({headers: {}});
    this.setState({profile});
  }

  handleRemoveHTTP(idx) {
    const {profile} = this.state;
    profile.alarm.http.splice(idx, 1);
    this.setState({profile});
  }

  handleHTTPHeaderChange(idx, httpHeaderFieldName, keyOrValue, e) {
    const {profile} = this.state;
    if (keyOrValue === "key") {
      profile.alarm.http[idx].headers[e.target.value] =
                profile.alarm.http[idx].headers[httpHeaderFieldName];
      delete profile.alarm.http[idx].headers[httpHeaderFieldName];
    } else {
      profile.alarm.http[idx].headers[httpHeaderFieldName] = e.target.value;
    }

    this.setState({profile});
  }

  handleAddHTTPHeader(idx) {
    const {profile} = this.state;
    profile.alarm.http[idx].headers = profile.alarm.http[idx].headers || {};
    let newHeaderName = "x-dpapi-key";
    if (profile.alarm.http[idx].headers[newHeaderName] !== undefined) {
      newHeaderName = `${Object.keys(profile.alarm.http[idx].headers).length}`;
    }
    profile.alarm.http[idx].headers[newHeaderName] = "";
    this.setState({profile});
  }

  handleRemoveHTTPHeader(idx, httpHeaderFieldName) {
    const {profile} = this.state;
    delete profile.alarm.http[idx].headers[httpHeaderFieldName];
    this.setState({profile});
  }

  handleAddTelegram() {
    const {profile} = this.state;
    (profile.alarm.telegram = profile.alarm.telegram || []).push({});
    this.setState({profile});
  }

  handleTelegramChatIDChange(idx, event) {
    const {profile} = this.state;
    profile.alarm.telegram = profile.alarm.telegram || [];
    profile.alarm.telegram[idx].chat_id = event.target.value;
    this.setState({profile});
  }

  handleTelegramNameChange(idx, event) {
    const {profile} = this.state;
    profile.alarm.telegram[idx].name = event.target.value;
    this.setState({profile});
  }

  handleTelegramMessageChange(idx, event) {
    const {profile} = this.state;
    profile.alarm.telegram = profile.alarm.telegram || [];
    profile.alarm.telegram[idx].message = event.target.value;
    this.setState({profile});
  }

  handleRemoveTelegram(idx) {
    const {profile} = this.state;
    profile.alarm.telegram.splice(idx, 1);
    this.setState({profile});
  }

  handleAddThreema() {
    const {profile} = this.state;
    (profile.alarm.threema = profile.alarm.threema || []).push({});
    this.setState({profile});
  }

  handleThreemaChange(idx, event) {
    const {profile} = this.state;
    profile.alarm.threema = profile.alarm.threema || [];
    profile.alarm.threema[idx].threema_id = event.target.value;
    this.setState({profile});
  }

  handleThreemaMessageChange(idx, event) {
    const {profile} = this.state;
    profile.alarm.threema = profile.alarm.threema || [];
    profile.alarm.threema[idx].message = event.target.value;
    this.setState({profile});
  }

  handleRemoveThreema(idx) {
    const {profile} = this.state;
    profile.alarm.threema.splice(idx, 1);
    this.setState({profile});
  }

  handleIndymeChange(event) {
    const {profile} = this.state;
    profile.alarm.indyme = profile.alarm.indyme || {};
    if (event.target.id === "indyme_client_id")
      profile.alarm.indyme.indyme_client_id = event.target.value;
    else if (event.target.id === "indyme_client_secret")
      profile.alarm.indyme.indyme_client_secret = event.target.value;
    else if (event.target.id === "indyme_site_id")
      profile.alarm.indyme.indyme_site_id = event.target.value;
    else if (event.target.id === "indyme_organization_id")
      profile.alarm.indyme.indyme_organization_id = event.target.value;
    else if (event.target.id === "indyme_event_name")
      profile.alarm.indyme.indyme_event_name = event.target.value;
    else if (event.target.id === "indyme_api_url")
      profile.alarm.indyme.indyme_api_url = event.target.value;
    else if (event.target.id === "indyme_token_url")
      profile.alarm.indyme.indyme_token_url = event.target.value;
    else if (event.target.id === "indyme_qlink_host")
      profile.alarm.indyme.indyme_qlink_host = event.target.value;
    else if (event.target.id === "indyme_qlink_alarm")
      profile.alarm.indyme.indyme_qlink_alarm = event.target.value;
    this.setState({profile});
  }

  handlePlaysoundChange(event) {
    const {profile} = this.state;
    profile.alarm.play_sound = event.target.value;
    this.setState({profile});
  }

  handleAxisURLChange(event) {
    const {profile} = this.state;
    profile.alarm.axis.vms_alarm_request_url = event.target.value;
    this.setState({profile});
  }

  handleMilestoneDataChange(event) {
    const {profile} = this.state;
    if (event.target.id === "milestone_event_name")
      profile.alarm.milestone.event_name = event.target.value;
    else if (event.target.id === "milestone_analytics_event_endpoint")
      profile.alarm.milestone.analytics_event_endpoint = event.target.value;
    else if (event.target.id === "milestone_camera_ip")
      profile.alarm.milestone.camera_ip = event.target.value;
    this.setState({profile});
  }

  handleAddGPIO() {
    const {profile} = this.state;
    profile.alarm.gpio.push({pin: 7, duration: 3});
    this.setState({profile});
  }

  handleGPIOPChange(idx, event) {
    const {profile} = this.state;
    const val = parseInt(event.target.value) || 0;

    profile.alarm.gpio[idx].pin = val;
    this.setState({profile});
  }

  handleGPIODurationChange(idx, event) {
    const {profile} = this.state;
    const val = parseInt(event.target.value) || 0;

    profile.alarm.gpio[idx].duration = val;
    this.setState({profile});
  }

  handleRemoveGPIO(idx) {
    const {profile} = this.state;
    profile.alarm.gpio.splice(idx, 1);
    this.setState({profile});
  }

  handleRelayDeviceChange(idx, event) {
    const {profile} = this.state;
    profile.alarm.relay[idx].device_path = event.target.value;
    this.setState({profile});
  }

  handleRelayDurationChange(idx, event) {
    const {profile} = this.state;
    let val = parseInt(event.target.value) || 0;

    profile.alarm.relay[idx].duration = val;
    this.setState({profile});
  }

  handleAddRelay() {
    const {profile} = this.state;
    profile.alarm.relay.push({device_path: "/dev/ttyUSB0", duration: 3});
    this.setState({profile});
  }

  handleRemoveRelay(idx) {
    const {profile} = this.state;
    profile.alarm.relay.splice(idx, 1);
    this.setState({profile});
  }

  handleWeekdayAlarmToggleActive(weekday) {
    const {profile} = this.state;
    let currentVal = profile.time_constraints.alarm_times[weekday].active;
    if (typeof currentVal !== "boolean") {
      currentVal = false;
    }
    profile.time_constraints.alarm_times[weekday].active = !currentVal;

    this.setState({profile});
  }

  handleWeekdayAlarmTimeChange(weekday, key, event) {
    const {profile} = this.state;
    profile.time_constraints.alarm_times[weekday][key] = event.target.value;
    this.setState({profile});
  }

  handleAlarmSoundChange(event) {
    const {profile} = this.state;
    if (event.target.id.endsWith("path")) {
      profile.alarm.play_sound.path = event.target.value;
    }
    if (event.target.id.endsWith("repeat")) {
      profile.alarm.play_sound.repeat = parseInt(event.target.value);
    }
    if (event.target.id.endsWith("cancel_on_keypress")) {
      profile.alarm.play_sound.cancel_on_keypress = !profile.alarm.play_sound.cancel_on_keypress;
    }
    this.setState({profile});
  }

  showAffectedApps() {
    if (this.state.affectedApps.length > 0) {
      return (
        <p style={{color: "#ef5350"}}>
          {" "}
                    This profile also affects {this.state.affectedApps.join(", ")}.
        </p>
      );
    }
  }

  changeProfileRawJson(event) {
    this.setState({
      profile_raw_json: event.target.value,
    });
    try {
      JSON.parse(event.target.value);
      event.target.style.borderColor = "green";
    } catch (e) {
      event.target.style.borderColor = "red";
    }
  }

  changeNewProfilePath(event) {
    event.target.style.border = "3px solid green";
    this.profileMessage = "";
    this.setState({
      new_profile_path: "",
    });
    if (!isSafePath(event.target.value, this.file_type)) {
      this.profileMessage = "File name should contain '" + this.file_type + "' and end with '.json'.";
      event.target.style.border = "3px solid red";
      return false;
    }
    if (this.state.availableProfilesFiles.includes(event.target.value)) {
      this.profileMessage = "File already exists, choose another name.";
      event.target.style.border = "3px solid red";
      return false;
    }
    this.setState({
      new_profile_path: event.target.value,
    });
    return true;
  }

  profileFileTargetSpecified() {
    return this.state.new_profile_path !== "";
  }

  saveProfileJson() {
    saveJSON(
      this.state.profile_raw_json,
      this.state.new_profile_path,
      this.file_type,
      this.jsonEndpoint
    );
  }

  render() {
    const {classes, t} = this.props;
    if (this.state.profile_path) {
      const {
        profile,
        generalInfoSchemaBridge,
        timeConstrainedAlarmsSchemaBridge,
      } = this.state;
      return (
        <div>
          <GridContainer>
            <GridItem xs={12}>
              <Card
                style={{
                  width: "100%",
                  marginRight: "auto",
                  marginLeft: "auto",
                  paddingTop: "3px",
                }}
              >
                <CardBody
                  style={{
                    width: "100%",
                    marginRight: "auto",
                    marginLeft: "auto",
                    paddingRight: this.state.small_version ? "0%" : "3%",
                    paddingLeft: this.state.small_version ? "3%" : "3%",
                    paddingTop: this.state.small_version ? "1%" : "2%",
                    paddingBottom: "5%",
                  }}
                >
                  <h3 className={classes.cardIconTitle}>Alarm Profile</h3>
                  <p style={{...monospaceFont}}>{this.state.profile_path}</p>
                  {this.showAffectedApps()}
                  <br/>

                  <h5 className={classes.cardIconTitle}>Telegram</h5>
                  {profile.alarm.telegram
                    ? profile.alarm.telegram.map((recipient, idx) => (
                      <GridContainer
                        key={idx}
                        style={{
                          width: "100%",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <GridItem md={5} xs={12}>
                          <TextField
                            className={classes.textField}
                            id={`telegram_name_${idx}`}
                            label={t("device_profile.name")}
                            fullWidth
                            placeholder={"Max Mustermann"}
                            value={recipient.name || ""}
                            onChange={(e) =>
                              this.handleTelegramNameChange(idx, e)
                            }
                          />
                        </GridItem>
                        <GridItem md={5} xs={12}>
                          <TextField
                            className={classes.textField}
                            id={`chat_id_${idx}`}
                            label="Chat ID"
                            fullWidth
                            placeholder={"72613827"}
                            value={recipient.chat_id || ""}
                            onChange={(e) =>
                              this.handleTelegramChatIDChange(idx, e)
                            }
                          />
                        </GridItem>
                        <GridItem md={5} xs={12}>
                          <TextField
                            className={classes.textField}
                            id={`telegram_message_id_${idx}`}
                            label="Message (optional)"
                            fullWidth
                            placeholder={""}
                            value={recipient.message || ""}
                            onChange={(e) =>
                              this.handleTelegramMessageChange(idx, e)
                            }
                          />
                        </GridItem>
                        <GridItem xs={2}>
                          <Button
                            className={classes.removeButton}
                            size="sm"
                            justIcon
                            color="danger"
                            onClick={() => this.handleRemoveTelegram(idx)}
                          >
                            <DeleteOutline/>
                          </Button>
                        </GridItem>
                      </GridContainer>
                    ))
                    : undefined}
                  <GridContainer
                    style={{
                      paddingBottom: "1%",
                      paddingTop: "1%",
                      width: "100%",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  >
                    <GridItem xs={12}>
                      <Button
                        color="info"
                        onClick={this.handleAddTelegram}
                        className="small"
                      >
                        <Add/> Alarm
                      </Button>
                    </GridItem>
                  </GridContainer>
                  <br/>
                  <h5 className={classes.cardIconTitle}>Threema</h5>
                  {profile.alarm.threema
                    ? profile.alarm.threema.map((recipient, idx) => (
                      <GridContainer
                        key={idx}
                        style={{
                          width: "100%",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <GridItem md={5} xs={12}>
                          <TextField
                            className={classes.textField}
                            id={`threema_id_${idx}`}
                            label="Threema ID"
                            fullWidth
                            placeholder={"J2YJZXA7"}
                            value={recipient.threema_id || ""}
                            onChange={(e) => this.handleThreemaChange(idx, e)}
                          />
                        </GridItem>
                        <GridItem md={5} xs={12}>
                          <TextField
                            className={classes.textField}
                            id={`threema_message_id_${idx}`}
                            label="Message (optional)"
                            fullWidth
                            placeholder={""}
                            value={recipient.message || ""}
                            onChange={(e) =>
                              this.handleThreemaMessageChange(idx, e)
                            }
                          />
                        </GridItem>
                        <GridItem xs={2}>
                          <Button
                            className={classes.removeButton}
                            size="sm"
                            justIcon
                            color="danger"
                            onClick={() => this.handleRemoveThreema(idx)}
                          >
                            <DeleteOutline/>
                          </Button>
                        </GridItem>
                      </GridContainer>
                    ))
                    : undefined}
                  <GridContainer
                    style={{
                      paddingBottom: "1%",
                      paddingTop: "1%",
                      width: "100%",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  >
                    <GridItem xs={12}>
                      <Button
                        color="info"
                        onClick={this.handleAddThreema}
                        className="small"
                      >
                        <Add/> Alarm
                      </Button>
                    </GridItem>
                  </GridContainer>
                  <br/>
                  <h5 className={classes.cardIconTitle}>
                    {t("device_profile.http_alarms")}
                  </h5>
                  {profile.alarm.http
                    ? profile.alarm.http.map((http, idx) => (
                      <div key={`httpTarget${idx}`}>
                        <GridContainer
                          key={idx + "url"}
                          style={{
                            width: "100%",
                            paddingTop: "1%",
                            marginRight: "auto",
                            marginLeft: "auto",
                          }}
                        >
                          <p>{`HTTP Alarm ${idx + 1}`}</p>
                          <GridItem xs={12}>
                            <TextField
                              className={classes.textField}
                              id={`url_${idx}`}
                              style={{
                                width: "90%",
                                marginRight: "auto",
                                marginLeft: "auto",
                              }}
                              data-field="url"
                              label="URL"
                              fullWidth
                              placeholder={"http://hostname-or-ip"}
                              value={http.url || ""}
                              onChange={(e) => this.handleHTTPChanged(idx, e)}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          key={idx}
                          style={{
                            width: "100%",
                            paddingBottom: "1%",
                            paddingTop: "1%",
                            marginRight: "auto",
                            marginLeft: "auto",
                          }}
                        >
                          <GridItem md={5} xs={12}>
                            <TextField
                              className={classes.textField}
                              id={`method_${idx}`}
                              data-field="method"
                              label={t("device_profile.http_method")}
                              fullWidth
                              placeholder={"GET|POST|PUT|DELETE|etc"}
                              value={http.method || ""}
                              onChange={(e) => this.handleHTTPChanged(idx, e)}
                            />
                          </GridItem>
                          <GridItem md={5} xs={12}>
                            <TextField
                              className={classes.textField}
                              id={`auth_type_${idx}`}
                              data-field="auth_type"
                              label={t("device_profile.http_auth_type")}
                              fullWidth
                              placeholder={"digest"}
                              value={http.auth_type || ""}
                              onChange={(e) => this.handleHTTPChanged(idx, e)}
                            />
                          </GridItem>
                          <GridItem md={5} xs={12}>
                            <TextField
                              className={classes.textField}
                              id={`auth_username_${idx}`}
                              data-field="auth_username"
                              label={t("device_profile.http_auth_username")}
                              fullWidth
                              placeholder={t(
                                "device_profile.any_kind_of_content"
                              )}
                              value={http.auth_username || ""}
                              onChange={(e) => this.handleHTTPChanged(idx, e)}
                            />
                          </GridItem>
                          <GridItem md={5} xs={12}>
                            <TextField
                              className={classes.textField}
                              id={`auth_password_${idx}`}
                              data-field="auth_password"
                              label={t("device_profile.http_auth_password")}
                              fullWidth
                              placeholder={t(
                                "device_profile.any_kind_of_content"
                              )}
                              value={http.auth_password || ""}
                              onChange={(e) => this.handleHTTPChanged(idx, e)}
                            />
                          </GridItem>
                          <GridItem md={5} xs={12}>
                            <TextField
                              className={classes.textField}
                              id={`initial_delay_${idx}`}
                              data-field="initial_delay"
                              type="integer"
                              label={t("device_profile.initial_delay")}
                              fullWidth
                              placeholder={"0"}
                              value={http.initial_delay || 0}
                              onChange={(e) =>
                                this.handleHTTPDelayChanged(idx, e)
                              }
                            />
                          </GridItem>
                          <GridContainer
                            key={`http_headers_${idx}`}
                            style={{
                              width: "100%",
                              marginRight: "auto",
                              marginLeft: "auto",
                            }}
                          >
                            <GridItem xs={12}>
                              <p>HTTP Headers</p>
                              <GridItem md={3} xs={12}>
                                <Button
                                  color="info"
                                  size="sm"
                                  onClick={() =>
                                    this.handleAddHTTPHeader(idx)
                                  }
                                  className="small"
                                >
                                  <Add/> Header
                                </Button>
                              </GridItem>
                              {Object.keys(http.headers || {}).length > 0
                                ? Object.keys(http.headers || {}).map(
                                  (httpHeaderFieldName) => (
                                    <GridContainer
                                      key={`http_headers_${httpHeaderFieldName}_${idx}`}
                                      style={{
                                        width: "90%",
                                        marginRight: "auto",
                                        marginLeft: "auto",
                                      }}
                                    >
                                      <GridItem md={5} xs={12}>
                                        <TextField
                                          className={classes.textField}
                                          id={`http_headers_${httpHeaderFieldName}_key_${idx}`}
                                          label={"HTTP Header Field Name"}
                                          placeholder={"Content-type"}
                                          value={
                                            httpHeaderFieldName ||
                                                                                        "Header Name"
                                          }
                                          onChange={(e) =>
                                            this.handleHTTPHeaderChange(
                                              idx,
                                              httpHeaderFieldName,
                                              "key",
                                              e
                                            )
                                          }
                                        />
                                      </GridItem>
                                      <GridItem md={5} xs={12}>
                                        <TextField
                                          className={classes.textField}
                                          id={`http_headers_${httpHeaderFieldName}_val_${idx}`}
                                          label={`${httpHeaderFieldName} Value`}
                                          placeholder={`${httpHeaderFieldName} Value`}
                                          value={
                                            http.headers[
                                              httpHeaderFieldName
                                            ]
                                          }
                                          onChange={(e) =>
                                            this.handleHTTPHeaderChange(
                                              idx,
                                              httpHeaderFieldName,
                                              "value",
                                              e
                                            )
                                          }
                                        />
                                      </GridItem>
                                      <GridItem md={2} xs={12}>
                                        <Button
                                          className={classes.removeButton}
                                          size="sm"
                                          color="danger"
                                          onClick={() =>
                                            this.handleRemoveHTTPHeader(
                                              idx,
                                              httpHeaderFieldName
                                            )
                                          }
                                        >
                                          <DeleteOutline/> Header
                                        </Button>
                                      </GridItem>
                                    </GridContainer>
                                  )
                                )
                                : undefined}
                            </GridItem>
                          </GridContainer>
                          <GridItem md={5} xs={12}>
                            <TextField
                              className={classes.textField}
                              id={`body_${idx}`}
                              data-field="body"
                              label={t("device_profile.http_body")}
                              fullWidth
                              placeholder={t(
                                "device_profile.any_kind_of_content"
                              )}
                              value={http.body || ""}
                              onChange={(e) => this.handleHTTPChanged(idx, e)}
                            />
                          </GridItem>
                          <br/>
                          <GridItem md={5} xs={12}>
                            {t("device_profile.https_verify")}
                            <Checkbox
                              className={classes.positionAbsolute}
                              id={`https_verify_${idx}`}
                              checked={http.https_verify === true}
                              onClick={() => this.handleHTTPSChanged(idx)}
                              checkedIcon={
                                <Check className={classes.checkedIcon}/>
                              }
                              icon={
                                <Check className={classes.uncheckedIcon}/>
                              }
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                          </GridItem>
                          <br/>
                          <GridItem xs={12}>
                            <Button
                              className={classes.removeButton}
                              color="danger"
                              style={{float: "right", marginRight: "10%"}}
                              onClick={() => this.handleRemoveHTTP(idx)}
                            >
                              <DeleteOutline/> Alarm
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </div>
                    ))
                    : undefined}
                  <GridItem xs={12} style={{marginTop: "1%"}}>
                    <Button
                      color="info"
                      onClick={this.handleAddHTTP}
                      className="small"
                    >
                      <Add/> Alarm
                    </Button>
                  </GridItem>
                  <br/>
                  <h5 className={classes.cardIconTitle}>Indyme</h5>
                  <br/>
                  <div>
                    <GridContainer
                      key={"indyme"}
                      style={{
                        width: "100%",
                        marginRight: "auto",
                        marginLeft: "auto",
                      }}
                    >
                      <GridItem md={5} xs={12}>
                        <TextField
                          className={classes.textField}
                          id={"indyme_client_id"}
                          label="Indyme Client ID"
                          fullWidth
                          placeholder={"?"}
                          value={profile.alarm.indyme.indyme_client_id || ""}
                          onChange={(e) => this.handleIndymeChange(e)}
                        />
                      </GridItem>
                      <GridItem md={5} xs={12}>
                        <TextField
                          className={classes.textField}
                          id={"indyme_client_secret"}
                          label="Indyme Client Secret"
                          fullWidth
                          placeholder={"?"}
                          value={
                            profile.alarm.indyme.indyme_client_secret || ""
                          }
                          onChange={(e) => this.handleIndymeChange(e)}
                        />
                      </GridItem>
                      <GridItem md={5} xs={12}>
                        <TextField
                          className={classes.textField}
                          id={"indyme_site_id"}
                          label="Indyme Site ID"
                          fullWidth
                          placeholder={"?"}
                          value={profile.alarm.indyme.indyme_site_id || ""}
                          onChange={(e) => this.handleIndymeChange(e)}
                        />
                      </GridItem>
                      <GridItem md={5} xs={12}>
                        <TextField
                          className={classes.textField}
                          id={"indyme_organization_id"}
                          label="Indyme Organization ID"
                          fullWidth
                          placeholder={"?"}
                          value={
                            profile.alarm.indyme.indyme_organization_id || ""
                          }
                          onChange={(e) => this.handleIndymeChange(e)}
                        />
                      </GridItem>
                      <GridItem md={5} xs={12}>
                        <TextField
                          className={classes.textField}
                          id={"indyme_event_name"}
                          label="Indyme Event Name"
                          fullWidth
                          placeholder={"?"}
                          value={profile.alarm.indyme.indyme_event_name || ""}
                          onChange={(e) => this.handleIndymeChange(e)}
                        />
                      </GridItem>
                      <GridItem md={5} xs={12}>
                        <TextField
                          className={classes.textField}
                          id={"indyme_api_url"}
                          label="Indyme API URL"
                          fullWidth
                          placeholder={"?"}
                          value={profile.alarm.indyme.indyme_api_url || ""}
                          onChange={(e) => this.handleIndymeChange(e)}
                        />
                      </GridItem>
                      <GridItem md={5} xs={12}>
                        <TextField
                          className={classes.textField}
                          id={"indyme_token_url"}
                          label="Indyme Token URL"
                          fullWidth
                          placeholder={"?"}
                          value={profile.alarm.indyme.indyme_token_url || ""}
                          onChange={(e) => this.handleIndymeChange(e)}
                        />
                      </GridItem>
                      <GridItem md={5} xs={12}>
                        <TextField
                          className={classes.textField}
                          id={"indyme_qlink_host"}
                          label="Indyme QLink Host"
                          fullWidth
                          placeholder={"?"}
                          value={profile.alarm.indyme.indyme_qlink_host || ""}
                          onChange={(e) => this.handleIndymeChange(e)}
                        />
                      </GridItem>
                      <GridItem md={5} xs={12}>
                        <TextField
                          className={classes.textField}
                          id={"indyme_qlink_alarm"}
                          label="Indyme QLink Alarm"
                          fullWidth
                          placeholder={"?"}
                          value={profile.alarm.indyme.indyme_qlink_alarm || ""}
                          onChange={(e) => this.handleIndymeChange(e)}
                        />
                      </GridItem>
                    </GridContainer>
                  </div>
                  <br/>
                  <h5 className={classes.cardIconTitle}>SMS</h5>
                  <br/>
                  {profile.alarm.phone
                    ? profile.alarm.phone.map((phone, idx) => (
                      <GridContainer
                        key={idx}
                        style={{
                          width: "100%",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <GridItem md={5} xs={12}>
                          <TextField
                            className={classes.textField}
                            label={t("device_profile.name")}
                            fullWidth
                            placeholder={"Testphone"}
                            value={phone.name || ""}
                            onChange={(e) =>
                              this.handlePhoneNameChange(idx, e)
                            }
                          />
                        </GridItem>
                        <GridItem md={5} xs={12}>
                          <TextField
                            className={classes.textField}
                            label="Phone Number"
                            fullWidth
                            placeholder={"4912345678900"}
                            value={phone.number || ""}
                            onChange={(e) =>
                              this.handlePhoneNumberChange(idx, e)
                            }
                          />
                        </GridItem>
                        <GridItem xs={2}>
                          <Button
                            className={classes.removeButton}
                            size="sm"
                            justIcon
                            color="danger"
                            onClick={() => this.handleRemovePhone(idx)}
                          >
                            <DeleteOutline/>
                          </Button>
                        </GridItem>
                      </GridContainer>
                    ))
                    : undefined}
                  <GridContainer
                    style={{
                      width: "100%",
                      marginRight: "auto",
                      marginLeft: "auto",
                      paddingBottom: "1%",
                      paddingTop: "1%",
                    }}
                  >
                    <GridItem xs={12}>
                      <Button
                        color="info"
                        onClick={this.handleAddPhone}
                        className="small"
                      >
                        <Add/> Alarm
                      </Button>
                    </GridItem>
                  </GridContainer>
                  <br/>
                  <h5 className={classes.cardIconTitle}>
                    {t("device_profile.email_recipient")}
                  </h5>
                  <br/>
                  {profile.alarm.email
                    ? profile.alarm.email.map((email, idx) => (
                      <GridContainer
                        key={idx}
                        style={{
                          width: "100%",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <GridItem md={5} xs={12}>
                          <TextField
                            className={classes.textField}
                            id={`email_name_${idx}`}
                            label={t("device_profile.name")}
                            fullWidth
                            placeholder={t("device_profile.test_email")}
                            value={email.name || ""}
                            onChange={(e) =>
                              this.handleEmailNameChange(idx, e)
                            }
                          />
                        </GridItem>
                        <GridItem md={5} xs={12}>
                          <TextField
                            className={classes.textField}
                            id={`email_address_${idx}`}
                            label={t("device_profile.email_address")}
                            fullWidth
                            placeholder={"test@example.com"}
                            value={email.address || ""}
                            onChange={(e) =>
                              this.handleEmailNumberChange(idx, e)
                            }
                          />
                        </GridItem>
                        <GridItem xs={2}>
                          <Button
                            className={classes.removeButton}
                            size="sm"
                            justIcon
                            color="danger"
                            onClick={() => this.handleRemoveEmail(idx)}
                          >
                            <DeleteOutline/>
                          </Button>
                        </GridItem>
                      </GridContainer>
                    ))
                    : undefined}
                  <GridContainer
                    style={{
                      width: "100%",
                      marginRight: "auto",
                      marginLeft: "auto",
                      paddingBottom: "1%",
                      paddingTop: "1%",
                    }}
                  >
                    <GridItem xs={12}>
                      <Button
                        color="info"
                        onClick={this.handleAddEmail}
                        className="small"
                      >
                        <Add/> Alarm
                      </Button>
                    </GridItem>
                  </GridContainer>
                  <br/>
                  <h5 className={classes.cardIconTitle}>
                    {t("device_profile.alarm_sound")}
                  </h5>
                  <GridContainer
                    style={{
                      paddingTop: "2%",
                      width: "100%",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  >
                    <GridItem md={8} xs={12}>
                      <TextField
                        className={classes.textField}
                        id={"play_sound_path"}
                        label="Soundfile path"
                        fullWidth
                        placeholder={"?"}
                        value={profile.alarm.play_sound.path || ""}
                        onChange={(e) => this.handleAlarmSoundChange(e)}
                      />
                    </GridItem>
                    <GridItem md={2} xs={12}>
                      <TextField
                        className={classes.textField}
                        id={"play_sound_repeat"}
                        label="Repeat times"
                        fullWidth
                        placeholder={"?"}
                        value={profile.alarm.play_sound.repeat || ""}
                        onChange={(e) => this.handleAlarmSoundChange(e)}
                      />
                    </GridItem>
                    <GridItem md={2} xs={12}>
                      Allow to cancel on keypress
                      <Checkbox
                        id={"cancel_on_keypress"}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                        checked={(profile.alarm.play_sound.cancel_on_keypress === true)}
                        onChange={(e) => this.handleAlarmSoundChange(e)}
                      />
                    </GridItem>
                  </GridContainer>
                  <br/>
                  <h5 className={classes.cardIconTitle}>
                    {t("device_profile.axis_request_url")}
                  </h5>
                  <GridContainer
                    style={{
                      paddingTop: "1%",
                      width: "100%",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  >
                    <GridItem xs={12}>
                      <TextField
                        className={classes.textField}
                        id="axis"
                        label="URL"
                        style={{
                          width: "95%",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                        InputLabelProps={{
                          shrink: !!profile.alarm.axis.vms_alarm_request_url,
                        }}
                        value={profile.alarm.axis.vms_alarm_request_url}
                        onChange={(e) => this.handleAxisURLChange(e)}
                      />
                    </GridItem>
                  </GridContainer>
                  <br/>
                  <h5 className={classes.cardIconTitle}>
                    {t("device_profile.milestone_alarm")}
                  </h5>
                  <GridContainer
                    style={{
                      paddingTop: "1%",
                      width: "100%",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  >
                    <GridItem xs={12}>
                      <TextField
                        className={classes.textField}
                        id="milestone_event_name"
                        label={t("device_profile.analytics_event_name")}
                        style={{
                          width: "95%",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                        InputLabelProps={{
                          shrink: !!profile.alarm.milestone.event_name,
                        }}
                        value={profile.alarm.milestone.event_name || ""}
                        onChange={(e) => this.handleMilestoneDataChange(e)}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <TextField
                        className={classes.textField}
                        id="milestone_analytics_event_endpoint"
                        label={t("device_profile.analytics_event_endpoint")}
                        style={{
                          width: "95%",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                        InputLabelProps={{
                          shrink:
                                                        !!profile.alarm.milestone.analytics_event_endpoint,
                        }}
                        value={
                          profile.alarm.milestone.analytics_event_endpoint || ""
                        }
                        onChange={(e) => this.handleMilestoneDataChange(e)}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <TextField
                        className={classes.textField}
                        id="milestone_camera_ip"
                        label={t("device_profile.camera_ip")}
                        style={{
                          width: "95%",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                        InputLabelProps={{
                          shrink: !!profile.alarm.milestone.camera_ip,
                        }}
                        value={profile.alarm.milestone.camera_ip || ""}
                        onChange={(e) => this.handleMilestoneDataChange(e)}
                      />
                    </GridItem>
                  </GridContainer>
                  <br/>
                  <h5 className={classes.cardIconTitle}>GPIO</h5>
                  {profile.alarm.gpio
                    ? profile.alarm.gpio.map((gpio_spec, idx) => (
                      <GridContainer
                        key={idx}
                        style={{
                          width: "100%",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <GridItem md={5} xs={12}>
                          <TextField
                            className={classes.textField}
                            id={`gpio_pin_${idx}`}
                            label={t("device_profile.pin")}
                            fullWidth
                            placeholder={"7"}
                            value={gpio_spec.pin}
                            onChange={(e) => this.handleGPIOPChange(idx, e)}
                          />
                        </GridItem>
                        <GridItem md={5} xs={12}>
                          <TextField
                            className={classes.textField}
                            id={`gpio_duration_${idx}`}
                            label={t("device_profile.duration")}
                            fullWidth
                            placeholder={"2"}
                            value={gpio_spec.duration}
                            onChange={(e) =>
                              this.handleGPIODurationChange(idx, e)
                            }
                          />
                        </GridItem>
                        <GridItem xs={2}>
                          <Button
                            className={classes.removeButton}
                            size="sm"
                            justIcon
                            color="danger"
                            onClick={() => this.handleRemoveGPIO(idx)}
                          >
                            <DeleteOutline/>
                          </Button>
                        </GridItem>
                      </GridContainer>
                    ))
                    : undefined}
                  <GridContainer
                    style={{
                      width: "100%",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  >
                    <GridItem xs={12}>
                      <Button
                        color="info"
                        onClick={this.handleAddGPIO}
                        className="small"
                      >
                        <Add/> Alarm
                      </Button>
                    </GridItem>
                  </GridContainer>

                  <br/>
                  <h5 className={classes.cardIconTitle}>
                    {t("device_profile.relay")}
                  </h5>
                  {profile.alarm.relay
                    ? profile.alarm.relay.map((relay_spec, idx) => (
                      <GridContainer
                        key={idx}
                        style={{
                          width: "100%",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <GridItem md={5} xs={12}>
                          <TextField
                            className={classes.textField}
                            label={t("device_profile.device")}
                            fullWidth
                            placeholder={"/dev/ttyUSB0"}
                            value={relay_spec.device_path}
                            onChange={(e) =>
                              this.handleRelayDeviceChange(idx, e)
                            }
                          />
                        </GridItem>
                        <GridItem md={5} xs={12}>
                          <TextField
                            className={classes.textField}
                            label={t("device_profile.duration")}
                            fullWidth
                            placeholder={"2"}
                            value={relay_spec.duration}
                            onChange={(e) =>
                              this.handleRelayDurationChange(idx, e)
                            }
                          />
                        </GridItem>
                        <GridItem xs={2}>
                          <Button
                            className={classes.removeButton}
                            size="sm"
                            justIcon
                            color="danger"
                            onClick={() => this.handleRemoveRelay(idx)}
                          >
                            <DeleteOutline/>
                          </Button>
                        </GridItem>
                      </GridContainer>
                    ))
                    : undefined}
                  <br/>
                  <GridContainer
                    style={{
                      width: "100%",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  >
                    <GridItem xs={12}>
                      <Button
                        color="info"
                        onClick={this.handleAddRelay}
                        className="small"
                      >
                        <Add/> Alarm
                      </Button>
                      <br/>
                      <Clearfix/>
                      <br/>
                      <br/>
                      <h3 className={classes.cardIconTitle}>
                        {t("device_profile.alarm_times")}
                      </h3>
                      <br/>
                      {[
                        "monday",
                        "tuesday",
                        "wednesday",
                        "thursday",
                        "friday",
                        "saturday",
                        "sunday",
                      ].map((weekday) => (
                        <GridContainer
                          key={weekday}
                          style={{
                            width: "100%",
                            marginRight: "auto",
                            marginLeft: "auto",
                            marginBottom: "2%",
                          }}
                        >
                          <GridItem md={4} xs={12}>
                            <h4 style={{paddingTop: "8%"}}>
                              {weekday}
                              <Checkbox
                                className={classes.positionAbsolute}
                                id={`active_${weekday}`}
                                checked={
                                  profile.time_constraints.alarm_times[weekday]
                                    .active === true
                                }
                                onClick={() =>
                                  this.handleWeekdayAlarmToggleActive(weekday)
                                }
                                checkedIcon={
                                  <Check className={classes.checkedIcon}/>
                                }
                                icon={
                                  <Check className={classes.uncheckedIcon}/>
                                }
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                              />
                            </h4>
                          </GridItem>
                          <GridItem md={3} xs={12}>
                            <TextField
                              className={classes.textField}
                              id={`alarm_start_${weekday}`}
                              label="Alarm Start"
                              fullWidth
                              placeholder={"00:00"}
                              value={
                                profile.time_constraints.alarm_times[weekday]
                                  .alarm_start || "00:00"
                              }
                              onChange={(e) =>
                                this.handleWeekdayAlarmTimeChange(
                                  weekday,
                                  "alarm_start",
                                  e
                                )
                              }
                            />
                          </GridItem>
                          <GridItem md={3} xs={12}>
                            <TextField
                              className={classes.textField}
                              id={`alarm_end_${weekday}`}
                              label="Alarm End"
                              fullWidth
                              placeholder={"23:59"}
                              value={
                                profile.time_constraints.alarm_times[weekday]
                                  .alarm_end || "23:59"
                              }
                              onChange={(e) =>
                                this.handleWeekdayAlarmTimeChange(
                                  weekday,
                                  "alarm_end",
                                  e
                                )
                              }
                            />
                          </GridItem>
                        </GridContainer>
                      ))}
                      <br/>
                      <GridContainer
                        key={"constrained"}
                        style={{
                          width: "100%",
                          marginRight: "auto",
                          marginLeft: "auto",
                          marginBottom: "2%",
                        }}
                      >
                        <GridItem xs={12}>
                          <h4 className={classes.cardIconTitle}>
                            {t("device_profile.constrained")}
                          </h4>
                          <AutoForm
                            schema={timeConstrainedAlarmsSchemaBridge}
                            model={{
                              constrained_by_alarm_period:
                                                            profile.time_constraints
                                                              .constrained_by_alarm_period,
                            }}
                            onSubmit={this.saveTimeConstrainedAlarms}
                          />
                        </GridItem>
                      </GridContainer>
                      <br/>
                      <br/>
                      <GridContainer>
                        <GridItem xs={12}>
                          <h3 className={classes.cardIconTitle}>
                            {t("device_profile.evidence_profile")}
                          </h3>
                          <EvidenceGeneralConfigBlock classes={classes}
                            general_upload_config={this.state.profile.evidence}
                            updateEvidenceGeneralConfig={this.updateEvidenceGeneralConfig}/>
                          <EvidenceStrategiesBlock
                            classes={classes}
                            className={classes.cardHover}
                            strategies={this.state.profile.evidence.strategies}
                            addFunction={this.addStrategy}
                            deleteFunction={this.deleteStrategy}
                            updateFunction={this.updateStrategy}
                          />
                        </GridItem>
                      </GridContainer>
                      <br/>
                      <br/>
                      <h3 className={classes.cardIconTitle}>
                        {t("device_profile.deployment_info")}
                      </h3>
                      <AutoForm
                        schema={generalInfoSchemaBridge}
                        model={profile}
                        onSubmit={this.saveGeneralInfo}
                      />
                    </GridItem>
                  </GridContainer>
                  <br/>
                  <GridContainer
                    style={{
                      width: "100%",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  >
                    {this.showAffectedApps()}
                    <Button
                      onClick={this.save}
                      color="success"
                      style={{
                        width: "70%",
                        margin: "auto",
                        marginTop: "5%",
                        color: "#FFFFFF",
                      }}
                      className={classes.updateProfileButton}
                    >
                      {t("device_profile.save")}
                    </Button>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
    } else {
      return (
        <div style={{width: "100%"}}>
          <p>
              This app has no alarm profile associated to it.
            <br/>
              Creating new Profile:
          </p>
          <CustomInput
            onChange={this.changeNewProfilePath}
            id="filename"
            labelText={
              "Filename must contain '" +
                    this.file_type +
                    "', ends with '.json' and valid JSON content"
            }
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "string",
            }}
          />
          <p> {this.profileMessage}</p>
          <br/>
          <textarea
            style={{width: "100%", height: "10em", border: "3px solid green", minWidth: "40em"}}
            value={this.state.profile_raw_json}
            onChange={this.changeProfileRawJson}
          ></textarea>
          <Button
            color="success"
            onClick={() => {
              this.saveProfileJson();
            }}
            style={{float: "right"}}
            disabled={!this.profileFileTargetSpecified()}
          >
            <Save/> Save
          </Button>
        </div>
      );
    }
  }
}

AlarmProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  instance_id: PropTypes.string,
  definitions_file_id: PropTypes.string,
  app_id: PropTypes.string,
  profile_path: PropTypes.string,
};

export default withI18n(withStyles(userProfileStyles)(AlarmProfile));
