const usbCameraConfigSchema = {
  title: "USBCameraConfig",
  type: "object",
  properties: {
    fps: {
      title: "FPS",
      // default: 30.0,
      type: "number"
    },
    incremental_frame_ids: {
      title: "Incremental Frame Ids",
      type: "boolean"
    },
    cam_width: {
      title: "Cam Width",
      // default: 1920,
      type: "integer"
    },
    cam_height: {
      title: "Cam Height",
      // default: 1080,
      type: "integer"
    },
    camera_additional_setup: {
      title: "Camera Additional Setup",
      type: "string"
    },
    adj_cam_brightness: {
      title: "Adj Cam Brightness",
      // default: false,
      type: "boolean"
    }
  },
  required: []
};

export {usbCameraConfigSchema};
