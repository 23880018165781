import React from "react";

import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import { SessionContext } from "layouts/Dashboard.jsx";
import Api from "modules/api.js";
import withI18n from "modules/withI18n";
import PropTypes from "prop-types";
import { NotificationManager } from "react-notifications";
//const default_definitions_file_id = "apps";


class DefinitionsFileSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      available_definitions_files: []
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.loadData(this.props.context.instance_id);

  }

  componentDidUpdate(prevProps) {
    if (this.props.context.instance_id !== prevProps.context.instance_id && typeof(this.props.context.instance_id) !== "undefined") {
      this._isMounted && this.loadData(this.props.context.instance_id);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadData(instance_id) {
    if (typeof(instance_id) === "undefined") {
      return;
    }
    Api.get(`/api/instances/${instance_id}/`).then((res) => {
      let definition_files = [];
      if (res.data.length === 0) {
        NotificationManager.error(`No definitions file(s) for ${instance_id}.`);
        // definition_files = [default_definitions_file_id];
      } else {
        definition_files = res.data;
      }
      this.props.context.toggleDefinitionsFile(definition_files[0]);
      this.setState({available_definitions_files: definition_files});
    }).catch((e) => {
      if (e.errors) {
        for (let key in e.errors) {
          NotificationManager.error(e.errors[key], key);
        }
      }
      // console.error(e);
    });
  }

  render() {
    if (this.state.available_definitions_files.length === 0) {
      return null;
    }
    const { classes, t } = this.props;
    const { definitions_file_id, toggleDefinitionsFile } = this.props.context;
    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel
          htmlFor="definitions_file_select"
          className={classes.selectLabel}
          // style={{
          //   transition: "none",
          //   color: "#222222",
          //   fontSize: "14px",
          //   // fontFamily: "Fira Mono"
          // }}
        >
          {t("definitions_file_select.header")}
        </InputLabel>
        <Select
          MenuProps={{
            className: classes.selectMenu,
          }}
          classes={{
            select: classes.select,
          }}
          value={definitions_file_id}
          onChange={(e) => {
            toggleDefinitionsFile(e.target.value);
          }}
          style={{
            // background: "rgba(255, 255, 255, 1)",
            padding: "2%",
            paddingLeft: "5%",
            paddingRight: "5%",
            // marginTop: "10%",
            // boxShadow: "0px 5px 3px 4px rgba(255, 255, 255, 0.5)",
          }}
          input={<Input id="definitions_file_select" value={definitions_file_id} />}
        >
          {this.state.available_definitions_files.map((definitions_file, idx) => (
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              key={idx}
              value={definitions_file}
            >
              {definitions_file}.yaml
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

function withSessionContext(Component) {
  return function WrapperComponent(props) {
    return (
      <SessionContext.Consumer>
        {(state) => <Component {...props} context={state} />}
      </SessionContext.Consumer>
    );
  };
}

DefinitionsFileSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withSessionContext(withI18n(withStyles(customSelectStyle)(DefinitionsFileSelect)));
