import React from "react";
import PropTypes from "prop-types";

import { SessionContext } from "layouts/Dashboard.jsx";
import Api from "modules/api.js";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Refresh from "@material-ui/icons/Refresh";
import withI18n from "modules/withI18n";
import withStyles from "@material-ui/core/styles/withStyles";
import { NotificationManager } from "react-notifications";


class ControlCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      block_rendering: true,
      status: undefined,
      is_on: false,
      logs: "...",
      // data_usage: "",
      // current_data_usage: -1,
      // network_info: { date: "", mode: "", provider: "", sim_card: "" },
      disk_usage: "",
      versions: undefined,
      task_status: {logs: undefined, is_on: false, run_result_id: undefined},
      // alarm_result: undefined,
      loading: false,
    };
  }
  resetStatus() {
    this.setState({
      target: undefined,
      status: "unavailable",
      // versions: undefined,
      // disk_usage: "",
      is_on: false,
      logs: "...",
      task_status: {logs: undefined, is_on: false, run_result_id: undefined, name: undefined},
    });
  }

  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.loadData(this.props.context.instance_id);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.context.instance_id !== this.props.context.instance_id) {
      // console.log(`updated instance in Status.jsx ${prevProps.context.instance_id} -> ${this.props.context.instance_id}`);
      this.setState({block_rendering: true}, () => {this._isMounted && this.loadData(this.props.context.instance_id);});
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  unblockRendering() {
    this._isMounted && this.setState({ block_rendering: false });
  }

  loadData(instance_id) {
    if (typeof(this.props.context.instance_id) === "undefined") {
      this.resetStatus();
      return;
    }
    let baseURL = `/api/status/${instance_id}`;
    Api.get(baseURL).then((res) => {
      this.setState(res, this.unblockRendering);
      Api.get(`${baseURL}/task_status`).then((res) => {
        this.setState({ task_status: res }, this.unblockRendering);
        if (res && res.is_on) {
          this.setState({task_status: res});
          this.taskStatusTimeout = setTimeout(() => this.getTaskStatus(res.run_result_id), 1000);
        }
      // eslint-disable-next-line no-unused-vars
      }).catch((e) => {
        // console.log(e);
        NotificationManager.error(e.message, "Could not fetch recording status.");
      });
    }).catch((e) => {
      this.resetStatus();
      this.setState({ logs: e.message });
      // console.log(e);
      // NotificationManager.error(e.message, `Could not fetch status of ${instance_id}.`);
    });
    // Api.get(`/api/status/network`).then((res) => {
    //   this.setState(res);
    // });
    // Api.get(`/api/status/data_usage`).then((res) => {
    //   this.setState(res);
    // });
    // Api.get(`/api/status/current_data_usage`).then((res) => {
    //   this.setState(res);
    // });
    if (!this.state.disk_usage) {
      Api.get("/api/status/disk_usage").then((res) => {
        this.setState(res, this.unblockRendering);
      }).catch((e) => {
        this.setState({ disk_usage: "" });
        console.log(e);
        // NotificationManager.error(e.message, "Could not fetch disk usage info.");
      });
    }
    if (!this.state.versions) {
      Api.get(`${baseURL}/version`).then((res) => {
        this.setState(res, this.unblockRendering);
      }).catch((e) => {
        this.setState({ versions: undefined });
        // console.log(e);
        // NotificationManager.error(e.message, "Could not fetch versions.");
      });
    }

  }

  // runSmokeTests() {
  //   Api.post(`${baseURL}/run_smoke_tests`).then((res) => {
  //     const { run_result_id, run_result } = res;

  //     this.setState({
  //       task_status: res,
  //       task_status.logs: run_result,
  //       logs: "",
  //       status: "",
  //     });

  //     if (this.taskStatusTimeout) {
  //       clearTimeout(this.taskStatusTimeout);
  //       this.taskStatusTimeout = undefined;
  //     }

  //     if (run_result_id) {
  //       this.taskStatusTimeout = setTimeout(
  //         () => this.getTaskStatus("0"),
  //         1000
  //       );
  //     }
  //   });
  // }

  getTaskStatus(id) {
    let baseURL = `/api/status/${this.props.context.instance_id}`;
    Api.get(`${baseURL}/task_status`, {id}).then((res) => {
      this.setState({task_status: res}, this.unblockRendering);
      if (res.is_on) {
        this.setState({task_status: res});
        this.taskStatusTimeout = setTimeout(() => this.getTaskStatus(id), 1000);
      } else {
        clearTimeout(this.taskStatusTimeout);
        this.taskStatusTimeout = undefined;
      }
    }).catch((e) => {
      NotificationManager.error(e.message, `Failed to get status of ${id}.service ${this.props.context.instance_id}.`);
    });
  }

  toggle(id, toggleAction) {
    let baseURL = `/api/status/${this.props.context.instance_id}`;
    Api.get(`${baseURL}/${toggleAction}`, {id}).then(() => {
      NotificationManager.success(`${id} will now ${toggleAction}.`);
      this._isMounted && this.loadData(this.props.context.instance_id);
    }).catch((e) => {
      NotificationManager.error(e.message, `Could not ${toggleAction} ${this.props.context.instance_id}.`);
      this._isMounted && this.loadData(this.props.context.instance_id);
    });
  }

  toggleLoading(id, toggleAction) {
    this.setState({loading: true});
    this.setState({task_status: {name: `Running ${toggleAction}...`}});
    NotificationManager.success(`Executing ${toggleAction} on instance ${id}, please wait.`);
    let baseURL = `/api/status/${this.props.context.instance_id}`;
    Api.get(`${baseURL}/${toggleAction}`, {id}).then((r) => {
      this.setState({loading: false});
      NotificationManager.success(`${toggleAction} on instance ${id} finished successfully.`);
      //this._isMounted && this.loadData(this.props.context.instance_id);
      this.setState({task_status: {name: `${toggleAction} successfull`, logs: r.message}})
    }).catch((e) => {
      this.setState({loading: false});
      NotificationManager.error(e.message, `${toggleAction} on instance ${this.props.context.instance_id} failed!`);
      this._isMounted && this.loadData(this.props.context.instance_id);
    });
  }

  restart() {
    let baseURL = `/api/status/${this.props.context.instance_id}`;
    Api.get(`${baseURL}/restart`).then(() => {
      NotificationManager.success(`${this.props.context.instance_id} is restarting.`);
      this._isMounted && this.loadData(this.props.context.instance_id);
    }).catch((e) => {
      // this.resetStatus();
      NotificationManager.error(e.message, `Could not restart ${this.props.context.instance_id}.`);
      this._isMounted && this.loadData(this.props.context.instance_id);
    });
  }

  render() {
    if (this.state.block_rendering) {return <div></div>;}
    const { classes, t } = this.props;
    return (
      <div>
        <GridContainer
          style={{
            width: "98%",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <br />
          <br />
          <GridItem
            xs={12}
            style={{
              width: "95%",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            {this.state.status ? (
              <Card style={{paddingBottom: "2%"}}>
                <span>
                  <CardHeader
                    color={this.state.is_on ? "success" : "danger"}
                    style={{ float: "left", width: "fit-content", padding: "1% 12", boxShadow: "none" }}
                    stats
                  >
                    <h3>{this.state.status}</h3>
                  </CardHeader>
                </span>
                <CardBody
                  style={{
                    width: "95%",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <br />
                  {this.state.target ? (
                    <CardHeader
                      color={"info"}
                      style={{float: "left", width: "fit-content", padding: "1% 2%", color: "black",
                        margin: "0px 0px 0px 0px",
                        boxShadow:
                        "0 10px 15px -20px rgba(0, 0, 0, 0.15), 0 3px 20px 5px rgba(0, 0, 0, 0.08), 0 8px 10px -5px rgba(0, 0, 0, 0.02)"}}
                      stats
                    >
                      <h3>{this.state.target}</h3>
                    </CardHeader>
                  ) : <h3 className={classes.cardTitle}>
                    {t("status.application_log")}
                  </h3>}
                  <Button
                    color="info"
                    style={{ fontFamily: "Fira Mono", boxShadow: "none", float: "left", marginLeft: "0%", marginRight: "2%", borderRadius: "3px",}} // padding: "15px 15px",
                    onClick={() => {this.setState({logs: "..."}, () => this.loadData(this.props.context.instance_id));}}
                  >
                    <Refresh style={{ margin: "0", float: "center"}}/>
                  </Button>
                  {!this.state.status.includes("unavailable") ? (
                    <>
                      <Button
                        style={{ float: "right", fontFamily: "Fira Mono" }}
                        color={this.state.is_on || this.state.status === "restarting" ? "danger" : "success"}
                        onClick={() => this.toggle(this.state.target, this.state.is_on ? "stop" : "start")}
                      >
                        {(this.state.is_on || this.state.status === "restarting") ? t("status.stop") : t("status.start")}
                      </Button>
                      {(this.state.is_on || this.state.status === "restarting") ? (
                        <Button
                          style={{ float: "right",  fontFamily: "Fira Mono"  }}
                          color="warning"
                          onClick={() => this.restart()}
                        >
                          <b style={{color: "#333"}}>{t("status.restart")}</b>
                        </Button>
                      ) : null}
                    </>
                  ) : null}

                  <pre className={classes.cardCategory}
                    style={{
                      fontFamily: "Fira Mono",
                      display: "flex",
                      flexDirection: "column-reverse",
                      overflow: "scroll",
                      width: "100%",
                      minHeight: "50px",
                      maxHeight: "650px",
                      padding: "1%",
                      background: "black",
                      color: "white",
                    }}
                  >{(this.state.logs) ? this.state.logs : "No Logs available."}</pre>
                  <br />
                  {/* <Button
                        color="info"
                        style={{ fontFamily: "Fira Mono", boxShadow: "none", float: "left", marginLeft: "-3%", padding: "15px 15px",  borderRadius: "3px",}}
                        onClick={() => {this.setState({logs: ""}, () => this.loadData(this.props.context.instance_id));}}
                      >
                        <Refresh style={{ margin: "0", float: "center"}}/>
                      </Button> */}
                </CardBody>
              </Card>
            ) : undefined}
            <br />
            {this.state.task_status ? (
              <Card>
                <CardBody
                  style={{
                    fontFamily: "Fira Mono",
                    width: "95%",
                    marginRight: "auto",
                    marginLeft: "auto",
                    overflow: "scroll",
                  }}
                >
                  <br />
                  {this.state.task_status.target || this.state.task_status.name ? (
                    <CardHeader
                      color={"info"}
                      style={{ float: "left", margin: "0px 0px 0px 0px", color: "black", width: "fit-content", padding: "1% 2%", boxShadow: "none" }}
                      stats
                    >
                      <h3>{this.state.task_status.name ? this.state.task_status.name : this.state.task_status.target}</h3>
                    </CardHeader>
                  ) :  <h3 className={classes.cardTitle}>
                Task Logs
                  </h3>}
                  <Button
                    color="info"
                    style={{ fontFamily: "Fira Mono", boxShadow: "none", float: "left", marginLeft: "0%", marginRight: "2%", borderRadius: "3px",}} // padding: "15px
                    onClick={() => {this.setState({tasks: [this.state.task_status.run_result_id], task_status: {is_on: false, name: this.state.task_status.run_result_id, logs: "..."}}, () => this.getTaskStatus(this.state.task_status.run_result_id));}}
                  >
                    <Refresh style={{ margin: "0", float: "center"}}/>
                  </Button>
                  <Button
                      disabled={this.state.loading}
                      style={{ float: "right", fontFamily: "Fira Mono" }}
                      color={this.state.is_on || this.state.status === "restarting" ? "danger" : "success"}
                      onClick={() => this.toggleLoading(this.state.target, "dryrun")}
                  >dry-run</Button>
                  <Button
                      disabled={this.state.loading}
                      style={{ float: "right", fontFamily: "Fira Mono" }}
                      color={this.state.is_on || this.state.status === "restarting" ? "danger" : "success"}
                      onClick={() => this.toggleLoading(this.state.target, "smoketests")}
                  >smoke-test</Button>
                  <Button
                    style={{ float: "right", fontFamily: "Fira Mono" }}
                    color={this.state.task_status.is_on ? "danger" : "primary"}
                    disabled={this.state.task_status.is_on ? false : true}
                    onClick={() => this.toggle(this.state.task_status.run_result_id, "stop")}
                  >
                    { t("status.stop")}
                  </Button>

                  <pre className={classes.cardCategory}
                    style={{
                      fontFamily: "Fira Mono",
                      display: "flex",
                      flexDirection: "column-reverse",
                      overflow: "scroll",
                      width: "100%",
                      minHeight: "50px",
                      maxHeight: "650px",
                      padding: "1%",
                      background: "black",
                      color: "white",
                      // border: "1px solid #222",
                    }}
                  >
                    {this.state.task_status.logs}
                  </pre>
                  <br />
                  {/* <Button
                      color="info"
                      style={{ fontFamily: "Fira Mono", boxShadow: "none", float: "left", marginLeft: "0%", padding: "15px 15px",  borderRadius: "3px",}}
                      onClick={() => {this.setState({task_status: {run_result_id: this.state.task_status.run_result_id, logs: "..."}}, () => this.getTaskStatus(this.state.task_status.run_result_id));}}
                    >
                      <Refresh style={{ margin: "0", float: "center"}}/>
                    </Button> */}
                </CardBody>
              </Card>
            ) : null}
            {/* {this.state.alarm_result ? (
              <Card>
                <CardHeader
                  style={{
                    width: "95%",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <br />
                  <h3 className={classes.cardTitle}>
                    {t("status.alarm_results")}
                  </h3>
                </CardHeader>

                <CardBody
                  style={{
                    width: "95%",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <pre className={classes.cardCategory}>
                    {this.state.alarm_result}
                  </pre>
                  <br />
                </CardBody>
              </Card>
            ) : null} */}
            <Card>
              <CardBody
                style={{
                  width: "95%",
                  marginRight: "auto",
                  marginLeft: "auto",
                  overflow: "scroll",
                  fontFamily: "Fira Mono"
                }}
              >
                <br />
                <h5 className={classes.cardTitle}>
                    Versions
                </h5>
                <p style={{fontFamily: "Fira Mono" }}>
                  {t("status.cartwatch_version")}: {this.state.versions ? this.state.versions.cartwatch : "unknown" }
                  <br />
                  {t("status.cartwatch_admin_version")}: {this.state.versions ? this.state.versions.cartwatch_admin : "unknown"}
                </p>
                <br />
                <h5 className={classes.cardTitle}>
                  {t("status.disk_usage")}
                </h5>
                <pre className={classes.cardCategory}>
                  {this.state.disk_usage}
                </pre>
                <br />
              </CardBody>
              <br />
            </Card>

            {/* {this.state.data_usage !== "" ? (
              <Card>
                <CardBody
                  style={{
                    width: "95%",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <br />
                  <h3 className={classes.cardTitle}>
                    {t("status.data_usage")}
                  </h3>
                  <br />
                  <pre className={classes.cardCategory}>
                    {this.state.data_usage}
                  </pre>
                  <br />
                </CardBody>
              </Card>
            ) : null} */}
          </GridItem>
          {/* <p
            style={{
              marginRight: "auto",
              marginLeft: "auto",
              fontFamily: '"Fira Mono"'
            }}
          >
            {this.state.network_info.mode}{" "}
            {this.state.network_info.provider !== ""
              ? "  ( " + this.state.network_info.provider + ") "
              : ""}
            {this.state.network_info.sim_card !== "" ? " - Sim Card: " : ""}{" "}
            {this.state.network_info.sim_card}
            {this.state.current_data_usage !== -1
              ? " - " +
                this.state.current_data_usage.toString() +
                "GB this month"
              : ""}{" "}
          </p> */}
        </GridContainer>
      </div>
    );
  }
}

ControlCenter.propTypes = {
  classes: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

function withSessionContext(Component) {
  return function WrapperComponent(props) {
    return (
      <SessionContext.Consumer>
        {(state) => <Component {...props} context={state} />}
      </SessionContext.Consumer>
    );
  };
}

export default withSessionContext(withI18n(withStyles(dashboardStyle)(ControlCenter)));
