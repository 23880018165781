import React from "react";

import { useTranslation } from "react-i18next";

export default function withI18n(Component) {
  return function I18nComponent(props) {
    const { t, i18n } = useTranslation();

    return <Component {...props} t={t} i18n={i18n} />;
  };
}
