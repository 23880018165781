import React from "react";

import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

import { SessionContext } from "layouts/Dashboard.jsx";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import Api from "modules/api.js";
import withI18n from "modules/withI18n";

class DeviceSelector extends React.Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this._isMounted = false;
    this.state = {
      devices: [],
      selectionHeader: t("device_select.stores"),
      organization: { name: t("device_select.your_organization") },
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.t !== prevProps.t) {
      this._isMounted && this.loadData();
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  loadData() {
    const { t } = this.props;

    Api.get("/api/devices/").then((res) => {
      if (this._isMounted) {
        this.setState({
          devices: res.data,
        });
        if (this.state.devices.length === 1) {
          this.setState({
            selectionHeader: t("device_select.store"),
          });
        }
      }
    });
    Api.get("/api/organizations/").then((res) => {
      if (this._isMounted) {
        this.setState({
          organization: res.data,
        });
      }
    });
  }
  createDeviceList(classes) {
    const { t } = this.props;

    let selection_list = [];
    if (this.state.devices.length > 1) {
      selection_list.push(
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          key={-1}
          value={-1}
        >
          {t("device_select.all_from")} {this.state.organization.name}
        </MenuItem>
      );
    }
    for (let i = 0; i < this.state.devices.length; i++) {
      selection_list.push(
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          key={i}
          value={this.state.devices[i].id}
        >
          {this.state.devices[i].name}
        </MenuItem>
      );
    }
    if (selection_list.length === 0) {
      selection_list.push(
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          key={-1}
          value={-1}
        >
          {t("device_select.local_device")}
        </MenuItem>
      );
    }
    return selection_list;
  }

  render() {
    const { classes, t } = this.props;
    return (
      <SessionContext.Consumer>
        {({ device, toggleDevice }) => (
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              htmlFor="device-select"
              className={classes.selectLabel}
              style={{
                color: "#222222",
                fontSize: "14px",
                fontFamily: "Fira Mono"
              }}
            >
              {device === this.state.organization.name
                ? t("device_select.organization")
                : this.state.selectionHeader}
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={device}
              onChange={(e) => {
                toggleDevice(e.target.value);
              }}
              style={{
                background: "rgba(255, 255, 255, 1)",
                padding: "2%",
                paddingLeft: "5%",
                paddingRight: "5%",
                marginTop: "10%",
                boxShadow: "0px 10px 25px 10px rgba(255, 255, 255, 0.9)",
              }}
              input={<Input id="device-select" value={-1} />}
            >
              {this.createDeviceList(classes)}
            </Select>
          </FormControl>
        )}
      </SessionContext.Consumer>
    );
  }
}
DeviceSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withI18n(withStyles(customSelectStyle)(DeviceSelector));
