import Ajv from "ajv";
const ajv = new Ajv({ allErrors: true, useDefaults: true, $data: true });
ajv.addKeyword("uniforms");


function createValidator(schema, loggingDestination) {
    const validator = ajv.compile(schema);
    return (model) => {
      validator(model);
      if (validator.errors && validator.errors.length) {
        for (var key in validator.errors) {
            console.log(validator.errors[key], key);
            loggingDestination.error(validator.errors[key].message, schema.title + " Validation Error " + key);
        }
      }
      return validator.errors && validator.errors.length
        ? { details: validator.errors }
        : null;
    };
};

export {createValidator};
