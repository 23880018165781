import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";

import footerStyle from "assets/jss/material-dashboard-pro-react/components/footerStyle";

function Footer({ ...props }) {
  const { classes, white } = props;
  // var container = cx({
  //   [classes.container]: !fluid,
  //   [classes.containerFluid]: fluid,
  //   [classes.whiteColor]: white
  // });
  var anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white
    });
  // var block = cx({
  //   [classes.block]: true,
  //   [classes.whiteColor]: white
  // });
  return (
    <footer className={classes.footer} >
      {/* <div className={container} style={{ marginBottom: "0px" }}> */}
      <p
        className={classes.right}
        style={{ color: "#222222", fontSize: "11px", fontFamily: "BrandFont"}} // fontWeight: "bold", lineHeight: "1.5em" }}
      >
          &copy; {1900 + new Date().getYear()}{"  "}
        <a
          href="https://www.signatrix.com"
          style={{ color: "#222222", fontSize: "11px", fontFamily: "BrandFont", fontWeight: "bold", lineHeight: "1.5em" }}
          className={anchor}
        >
            Signatrix GmbH
        </a>
      </p>
      {/* </div> */}
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
