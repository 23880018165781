const sourceDefinitionSchema = {
  title: "Source Definition",
  type: "object",
  properties: {
    name: {
      title: "Source ID",
      description: "Source ID/Name",
      type: "string",
      min: 1
    },
    type: {
      title: "Source Type",
      default: "rtsp",
      type: "string",
      allowedValues: ["rtsp", "file", "evidence", "usb", "shared_memory"]
    },
    location: {
      title: "Source URI/Location",
      description: "RTSP URI/Filename etc.",
      type: "string"
    },
    perspective_id: {
      title: "Perspective ID",
      description: "To be able to differentiate between perspective changes",
      type: "integer",
      default: 0
    },
    // perspective: {
    //   title: "Perspective",
    //   type: "string"
    // },
    source_params: {
      title: "Source Params",
      type: "object",
      properties: {},
      default: {},
      required: false
    },
    enabled: {
      type: "boolean",
      title: "Enabled",
      description: "Should this source be active?",
      default: false,
    },
  },
  required: ["name"]
};

export {sourceDefinitionSchema};
