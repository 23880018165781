import React from "react";
import Button from "components/CustomButtons/Button.jsx";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import PropTypes from "prop-types";
import Stop from "@material-ui/icons/Stop";
import withStyles from "@material-ui/core/styles/withStyles";
import cardStyle from "assets/jss/material-dashboard-pro-react/components/cardStyle.jsx";


function RecordButton({ ...props }) {
  function statusIcon(isRecording) {
    if (isRecording) {
      return <Stop style={{ float: "center", color: "#CCCCCC" }} />;
    } else {
      return  <FiberManualRecord style={{ float: "center", color: "#FFFFFF" }} />;
    }
  }
  function statusColor(isRecording) {
    if (isRecording) {
      return "#000000";
    } else {
      return "#ef5350";
    }
  }

  const {
    source,
    recordingJob,
    startRecordingFunction,
    stopRecordingFunction,
  } = props;
  if ((source.type === "rtsp" || source.type === "usb") && source.location){
    let isRecording = (recordingJob !== undefined);

    let toggleRecording = (myDefinition) => {
      if (isRecording) {
        stopRecordingFunction(myDefinition);
        isRecording = false;
      } else {
        startRecordingFunction(myDefinition);
        isRecording = true;
      }
    };
    return (
      <Button
        // disabled={true}
        style={{
          background: statusColor(isRecording),
          color: statusColor(isRecording),
          float: "right",
          marginLeft: "5%",
          marginRight: "-4%",
          borderRadius: "calc(.25rem - 1px) calc(.25rem - 1px) calc(.25rem - 1px) calc(.25rem - 1px)",
          boxShadow: "inset 5px 5px 8px 0 rgba(50, 50, 50, 0.05), inset -5px -5px 8px 0 rgba(255, 255, 255, 0.2)",
        }}
        onClick={() => toggleRecording(source)}
      >
        {statusIcon(isRecording)}
      </Button>
    );
  } else {
    return null;
  }
}

RecordButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  plain: PropTypes.bool,
  background: PropTypes.bool,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ]),
};

export default withStyles(cardStyle)(RecordButton);
