import React from "react";
import { NotificationManager } from "react-notifications";
import Button from "components/CustomButtons/Button.jsx";
import cardStyle from "assets/jss/material-dashboard-pro-react/components/cardStyle.jsx";
import Notifications from "@material-ui/icons/Notifications";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

function TestAlarmButton({ ...props }) {
  const {
    app,
    startTestAlarmFunction,
  } = props;

  if (app.enabled) {
    const disabled = !(app.alarm_profile || app.type.includes('checkout'));
    const statusColor = disabled ? "rgb(150, 150, 150)" : "rgb(69, 125, 227)";
    const triggerFunction = (app) => {
      if (disabled) {
        NotificationManager.warning(`No alarm profile configured for ${app.name}.`, "Cannot trigger test alarm.")
      } else {
        startTestAlarmFunction(app)
      }
    }
    return (
      <Button
        style={{
          background: statusColor,
          color: statusColor,
          float: "right",
          disabled: disabled,
          marginLeft: "5%",
          marginRight: "-4%",
          borderRadius: "calc(.25rem - 1px) calc(.25rem - 1px) calc(.25rem - 1px) calc(.25rem - 1px)",
          boxShadow: "inset 5px 5px 8px 0 rgba(50, 50, 50, 0.05), inset -5px -5px 8px 0 rgba(255, 255, 255, 0.2)",
        }}
        onClick={() => triggerFunction(app)}
      >
        <Notifications style={{ float: "center", color: "#FFFFFF" }} />
      </Button>
    );
  } else {
    return null;
  };
}

TestAlarmButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  plain: PropTypes.bool,
  background: PropTypes.bool,

  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ]),
};

export default withStyles(cardStyle)(TestAlarmButton);
