import React, {useState} from "react";
import {NotificationManager} from "react-notifications";
import {createValidator} from "variables/schemas.jsx";
import {JSONSchemaBridge} from "uniforms-bridge-json-schema";
import Overlay from "react-overlay-component";
import Button from "components/CustomButtons/Button.jsx";
import Add from "@material-ui/icons/Add";
import GridItem from "components/Grid/GridItem.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Edit from "@material-ui/icons/Edit";
import classNames from "classnames";
import {AutoForm} from "uniforms-material";
import {evidenceStrategySchema} from "../../variables/schemas/evidence_profile/evidenceStrategySchema.jsx";
import {
  evidenceStrategyNonBooleanFilterValueSchema
} from "../../variables/schemas/evidence_profile/strategy_filter_value_array_of_string.jsx";
import {
  evidenceStrategyBooleanFilterValueSchema
} from "../../variables/schemas/evidence_profile/strategy_filter_value_boolean.jsx";
import {evidenceStrategyFloatFilterValueSchema} from "../../variables/schemas/evidence_profile/strategy_filter_value_float.jsx";
import {
  evidenceUploadSchema
} from "../../variables/schemas/evidence_profile/evidence_profile";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import {DeleteConfirmOverlayButton} from "./DeleteConfirmOverlayButton";

const strategyFilterSchemaByType = {
  bool: evidenceStrategyBooleanFilterValueSchema,
  nonBool: evidenceStrategyNonBooleanFilterValueSchema,
  float: evidenceStrategyFloatFilterValueSchema,
};

function StrategyForm({strategy, schema, updateFunction}) {
  if (strategy) {
    try {
      const validator = createValidator(schema, NotificationManager);
      const bridge = new JSONSchemaBridge(schema, validator);
      const old_s = {...strategy};
      return (
        <AutoForm
          autosave={false}
          style={{float: "center", margin: "2%", overflow: "visible"}}
          schema={bridge}
          disabled={false}
          model={strategy}
          onSubmit={model => updateFunction(old_s, model)}
          showInlineError
        />
      );
    } catch (error) {
      console.error(error);
      return (
        <>
          <span>Could not load Form</span>
          <br/>
          <pre>{strategy ? JSON.stringify(strategy, 2) : ""}</pre>
          <br/>
          <pre>{error ? JSON.stringify(error, 2) : ""}</pre>
        </>
      );
    }
  } else {
    return <></>;
  }
}

class AddStrategy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: ""
    };
    this.strategy = null;
    this.schema = {...evidenceStrategySchema};
    this.addFunction = props.addFunction;
  }

  handleSelectedFilterBy(e) {
    if (this.state.selected !== e.target.value) {
      this.strategy = null;
      this.schema = {...evidenceStrategySchema};
    }
    if (e.target.value === "bool") {
      this.schema.properties = {
        ...this.schema.properties,
        ...strategyFilterSchemaByType["bool"],
      };
      this.strategy = {"selection_strategy": "always", "filter_by": "reported", "filter_value": false};
    }
    else if (e.target.value === "float"){
      this.schema.properties = {
        ...this.schema.properties,
        ...strategyFilterSchemaByType["float"],
      };
      this.strategy = {"selection_strategy": "confidence", "filter_by": "lost_products", "filter_value": 0.8};
    }
    else {
      this.schema.properties = {
        ...this.schema.properties,
        ...strategyFilterSchemaByType["nonBool"],
      };
      this.strategy = {"selection_strategy": "always", "filter_by": "kind", "filter_value": ["incident"]};
    }
    this.setState({selected: e.target.value});
  }

  render() {
    return (
      <div>
        <h5>Adding new upload strategy</h5>
        <Select value={this.state.selected} style={{width: "100%"}} onChange={(e) => {
          this.handleSelectedFilterBy(e);
        }}>
          <MenuItem key="bool" value="bool">A Boolean Filter Value</MenuItem>
          <MenuItem key="nonBool" value="nonBool">A List of String Filter Value</MenuItem>
          <MenuItem key="float" value="float">A Float Number Filter Value</MenuItem>
        </Select>
        <StrategyForm
          schema={this.schema}
          strategy={this.strategy}
          updateFunction={this.addFunction}
        />
      </div>
    );
  }
}

function EvidenceGeneralConfigBlock({...props}) {
  const {
    classes,
    general_upload_config,
    updateEvidenceGeneralConfig
  } = props;
  const schema = {...evidenceUploadSchema};
  const validator = createValidator(schema, NotificationManager);
  const bridge = new JSONSchemaBridge(schema, validator);
  const model = Object.keys(general_upload_config).filter((key) => !key.includes("strategies")).reduce((cur, key) => {
    return Object.assign(cur, {[key]: general_upload_config[key]});
  }, {});

  return <div><h4 className={classes.cardIconTitle}>General Config</h4>
    <AutoForm
      style={{float: "center", margin: "2%", overflow: "visible"}}
      schema={bridge}
      disabled={false}
      model={model}
      onSubmit={updateEvidenceGeneralConfig}
      showInlineError
    /></div>;
}

function EvidenceStrategiesBlock({...props}) {
  const {
    classes,
    className,
    strategies,
    addFunction,
    updateFunction,
    deleteFunction
  } = props;
  let [isOpen, setOverlay] = useState(false);
  let closeOverlay = () => {
    setOverlay(false);
  };

  function addAndCloseOverlayFunction(skip, strategy) {
    addFunction(strategy);
    closeOverlay();
  }

  return (
    <div style={{clear: "both", paddingTop: "2em"}}>
      <h4 className={classes.cardIconTitle}>Strategies</h4>
      {strategies.map((strategy, index) => (
        <EvidenceStrategyCard strategy={strategy} key={index} classes={classes}
          className={className} deleteFunction={deleteFunction}
          updateFunction={updateFunction}/>
      ))}
      <Button
        style={{
          float: "right",
          marginRight: "2%",
          marginTop: "2%",
          marginBottom: "1%",
          width: "10%",
          background: "#457DE3",
        }}
        onClick={() => {
          setOverlay(true);
        }}
      >
        <Add style={{float: "center", color: "#FFFFFF"}}/>
      </Button>
      <Overlay
        style={{backgroundColor: "rgba(50, 0, 50, 0.68"}}
        isOpen={isOpen}
        closeOverlay={closeOverlay}
      >
        {isOpen && <AddStrategy addFunction={addAndCloseOverlayFunction}/>}
      </Overlay>
    </div>
  );
}

function EvidenceStrategyCard({...props}) {
  const {
    classes,
    className,
    strategy,
    deleteFunction,
    updateFunction,
    ...rest
  } = props;
  const configs = {
    animate: true,
    clickDismiss: true,
    escapeDismiss: true,
    focusOutline: true,
    contentClass: "overlay-container",
  };

  let [isOpen, setOverlay] = useState(false);
  let closeOverlay = () => setOverlay(false);

  let schema = {...evidenceStrategySchema};
  if (strategy.selection_strategy === "confidence"){
    schema.properties = {
      ...schema.properties,
      ...strategyFilterSchemaByType["float"],
    };
  }
  else if (strategy.filter_by === "reported") {
    schema.properties = {
      ...schema.properties,
      ...strategyFilterSchemaByType["bool"],
    };
  }
  else {
    schema.properties = {
      ...schema.properties,
      ...strategyFilterSchemaByType["nonBool"],
    };
  }

  function updateAndCloseOverlayFunction(old_strategy, new_strategy) {
    updateFunction(old_strategy, new_strategy);
    closeOverlay();
  }

  let backgroundColor = "#fafafa";

  const cardClasses = classNames({
    [classes.card]: true,
    [className]: className !== undefined
  });

  return (
    <GridItem xs={12} sm={12} md={12}>
      <div className={cardClasses} {...rest} style={{background: backgroundColor, width: "100%"}}>
        <h5
          style={{
            fontWeight: 600,
            color: "#000",
            display: "flex",
            overflow: "hidden",
          }}
        >
                    Selection Strategy is {strategy.selection_strategy} for{" "}
          {strategy.filter_by} by {strategy.filter_value}
        </h5>
        <CardBody
          style={{
            width: "92%",
            marginLeft: "4%",
            marginRight: "10%",
            marginBottom: "2%",
          }}
        >
          <DeleteConfirmOverlayButton
            item={strategy}
            deleteFunction={() => deleteFunction(strategy)}
          />
          <Button
            style={{
              float: "right",
              marginRight: "-2%",
              width: "23%",
              background: "#333333",
              boxShadow:
                                "inset 5px 5px 8px 0 rgba(0, 0, 0, 0.25), inset -5px -5px 8px 0 rgba(255, 255, 255, 0.15)",
            }}
            onClick={() => setOverlay(true)}
          >
            <Edit style={{float: "center", color: "#FFFFFF"}}/>
          </Button>
        </CardBody>
        <Overlay
          style={{backgroundColor: "rgba(50, 0, 50, 0.68"}}
          configs={configs}
          isOpen={isOpen}
          closeOverlay={closeOverlay}
        >
          {isOpen && (
            <>
              <StrategyForm
                key={strategy.filter_by}
                strategy={strategy}
                schema={schema}
                updateFunction={updateAndCloseOverlayFunction}
              />
            </>
          )}
        </Overlay>
      </div>
    </GridItem>
  );
}

EvidenceStrategyCard.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};
export {EvidenceStrategiesBlock, EvidenceGeneralConfigBlock};
