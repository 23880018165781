const pipelineModelDefinitionsSchema = {
  title: "Pipeline Model",
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "Pipeline Model Name",
      description: "Name equivalent to its directory name",
      min: 1,
    },
    type: {
      type: "string",
      title: "Model Type",
      description: "Type of the pipeline model",
      allowedValues: ["detector", "classifier", "feature_extractor", "keypoint_detector"],
      default: "detector"
    },
    model: {
      type: "string",
      title: "Pipeline Model Folder Name",
      description: "If empty, assumed to be equivalent to its directory name",
    },
    sources: {
      type: "array",
      title: "Sources",
      description: "List of the Sources the Pipeline Model uses",
      items: { type: "string", allowedValues: [] },
      default: [],
    },
    model_params: {
      type: "object",
      title: "Pipeline Model Parameters",
      properties: {},
      default: {},
      required: false
    },
    enabled: {
      type: "boolean",
      title: "Enabled",
      description: "Should this model run?",
      default: true,
    },
  },
  required: ["name"],
};

const appModelDefinitionsSchema = {
  title: "App Model",
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "App Model Name",
      // description: "Name equivalent to its directory name",
      min: 1,
    },
    type: {
      type: "string",
      title: "Model Type",
      description: "Type of the App model",
      allowedValues: ["detector", "classifier", "feature_extractor"],
      default:  "classifier"
    },
    model: {
      type: "string",
      title: "App Model Folder Name",
      description: "If empty, assumed to be equivalent to its directory name",
    },
    model_params: {
      type: "object",
      title: "App Model Parameters",
      properties: {},
      default: {},
      required: false
    },
    enabled: {
      type: "boolean",
      title: "Enabled",
      description: "Should this model run?",
      default: true,
    },
  },
  required: [
    "name"
  ],
};

const faceModelDefinitionsSchema = {
  title: "Face Model",
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "Face Model Name",
      // description: "Name equivalent to its directory name",
      min: 1,
    },
    type: {
      type: "string",
      title: "Model Type",
      description: "Type of the Face model",
      allowedValues: ["detector"],
      default:  "detector"
    },
    model: {
      type: "string",
      title: "Face Model Folder Name",
      description: "If empty, assumed to be equivalent to its directory name",
    },
    model_params: {
      type: "object",
      title: "Face Model Parameters",
      properties: {},
      default: {},
      required: false
    },
  },
  required: [
    "name"
  ],
};

export {pipelineModelDefinitionsSchema, appModelDefinitionsSchema, faceModelDefinitionsSchema};
