const itabIntegrationSchema = {
  properties: {
    ip: {
      title: "IP",
      type: "string"
    },
    port: {
      title: "Port",
      type: "integer"
    },
  },
  required: []
};

export {itabIntegrationSchema};
