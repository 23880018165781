import React from "react";
import Button from "components/CustomButtons/Button.jsx";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";


class PerspectiveChangeButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      perspectiveID: this.props.perspectiveID,
      sourceID: this.props.sourceID,
      updatePerspectiveIDFunction: this.props.updatePerspectiveIDFunction,
    };
    this.updatePerspectiveID = this.updatePerspectiveID.bind(this);
  }

  updatePerspectiveID() {
    const newPerspectiveID = String(parseInt(this.state.perspectiveID) + 1);
    this.state.updatePerspectiveIDFunction(this.state.sourceID, newPerspectiveID);
    this.setState({ perspectiveID: newPerspectiveID });
  }


  render() {
    if (!this.state.updatePerspectiveIDFunction || !this.state.sourceID) {
      return null;
    }
    return (
      <>
        <p style={{marginBottom: "0px", marginTop: "0px", marginLeft: "5%", marginRight: "-4%"}}>Perspective: {this.state.perspectiveID}</p>
        <Button
          color="danger"
          style={{borderRadius: "calc(.25rem - 1px) calc(.25rem - 1px) calc(.25rem - 1px) calc(.25rem - 1px)",}}
          onClick={this.updatePerspectiveID}
        >
        Update Perspective
        </Button>
      </>
    );
  }
}

PerspectiveChangeButton.propTypes = {
  sourceID: PropTypes.string,
  perspectiveID: PropTypes.string,
  updatePerspectiveIDFunction: PropTypes.func,
};

export default withStyles(buttonStyle)(PerspectiveChangeButton);
