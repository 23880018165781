const shmBufferConfigSchema = {
  title: "ShmBufferConfig",
  type: "object",
  properties: {
    fps: {
      title: "FPS",
      // default: 30.0,
      type: "number"
    },
    incremental_frame_ids: {
      title: "Incremental Frame Ids",
      type: "boolean"
    },
    gamma_correct: {
      title: "Gamma Correct",
      // default: false,
      type: "boolean"
    },
    shm_buffer_size: {
      title: "SHM Buffer Size",
      // default: 4096 * 2048 * 3,
      type: "number"
    },
    cam_width: {
      title: "Cam Width",
      // default: 1920,
      type: "integer"
    },
    cam_height: {
      title: "Cam Height",
      // default: 1080,
      type: "integer"
    },
  },
  required: []
};

export {shmBufferConfigSchema};
