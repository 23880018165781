/* eslint-disable no-console */
/* eslint-disable react/jsx-key */
import React from "react";
import Pagination from "components/Pagination/Pagination.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import "video-react/dist/video-react.css"; // import css
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Api from "modules/api.js";
import { SessionContext } from "layouts/Dashboard.jsx";

import withI18n from "modules/withI18n";
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";
import {
  Player,
  ControlBar,
  CurrentTimeDisplay,
  BigPlayButton,
  TimeDivider,
  PlaybackRateMenuButton,
} from "video-react";

class IncidentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10,
      activePage: 1,
      totalPages: 1,
      total: 0,
      incidents: [],
      pages: [],
      activeColor: "warning",
      video_renderer: (incident) => {
        return <div />;
      },
    };
  }

  render_video(incident) {
    return incident.video_url !== "" ? (
      <Player playsInline poster={incident.image_url} preload="none">
        <source src={incident.video_url} loop />
        <BigPlayButton position="center" />
        <ControlBar>
          <CurrentTimeDisplay order={4.1} />
          <TimeDivider order={4.2} />
          <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
        </ControlBar>
      </Player>
    ) : incident.image_url !== "" ? (
      <img
        src={incident.image_url}
        style={{
          width: "82%",
          marginRight: "auto",
          marginLeft: "auto",
        }}
        alt={""}
      />
    ) : (
      <br />
    );
  }

  handlePageClick(data) {
    if (data !== this.state.activePage) {
      var pages = this.state.pages;
      pages[this.state.activePage - 1].active = false;
      pages[data - 1].active = true;
      var activeColor = "warning";
      if (data % 2 === 0) {
        activeColor = "success";
      }
      this.setState(
        { activePage: data, pages: pages, activeColor: activeColor },
        () => {
          this.loadData(this.props.context.device);
        }
      );
      this.setState({ video_renderer: this.render_video });
      // this.refs.mainPanel.scrollTop = 0;
      window.scrollTo(0, 1);
      // this.refs.mainPanel.scrollTop = 0;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.context.device !== prevProps.context.device) {
      this.loadData(this.props.context.device);
    }
  }

  componentDidMount() {
    this.loadData(this.props.context.device);
  }

  pages() {
    if (this.state.pages.length !== this.state.totalPages) {
      var pages = [];
      for (var i = 1; i <= this.state.totalPages; i++) {
        pages.push({
          active: i === this.state.activePage ? true : false,
          text: i,
          onClick: this.handlePageClick.bind(this, i), // after reading javascript - the good parts I figured that this should not work because of scoping in js but it works so I dont know
        });
      }
      this.setState({
        pages: pages,
      });
    }
  }

  loadData(device) {
    this.setState({
      video_renderer: (incident) => {
        return <div />;
      },
    });
    Api.get("/api/incidents/", {
      device_id: device,
      page: this.state.activePage,
      page_size: this.state.pageSize,
    }).then((res) => {
      this.setState({
        incidents: res.data.incidents,
        total: res.data.total,
        totalPages: res.data.total_pages,
        video_renderer: this.render_video,
      });
      this.pages();
      window.scrollTo(0, 0);
    });
  }

  render() {
    const { t } = this.props;
    return (
      <div
        style={{
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        {" "}
        <h5
          style={{
            marginRight: "10%",
            marginLeft: "10%",
          }}
        >
          {t("incident_list.disclaimer")}
          <a href="https://dashboard.cartwatch.de/admin/evidence/" title="Link">
            {"Link"}
          </a>
        </h5>
        {this.state.incidents.map((incident, key) => {
          return (
            <div
              key={key}
              style={{
                paddingTop: "4%",
                paddingBottom: "4%",
              }}
            >
              <Card
                // key={key}
                style={{
                  width: "77%",
                  height: "auto",
                  marginBottom: "2%",
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
              >
                <CardHeader
                  style={{
                    background: "#FFFFFF",
                    width: "88%",
                    paddingTop: "3%",
                    marginTop: "-2%",
                    paddingBottom: "3%",
                    paddingRight: "5%",
                    paddingLeft: "5%",
                    marginRight: "auto",
                    marginLeft: "auto",
                    boxShadow:
                      "0 12px 15px 5px rgba(0,0,0,.05), 0 4px 80px 0 rgba(0,0,0,.04), 0 7px 8px 5px rgba(0,0,0,.02)",
                  }}
                >
                  {" "}
                  <span
                    style={{
                      fontFamily:
                        "\"BrandFont\", \"Montserrat\", \"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
                      color: "#222222",
                      fontSize: "18px",
                    }}
                  >
                    <span style={{ fontWeight: 200 }}>
                      {incident.device_location} ({incident.device_name})
                    </span>
                    {"   -   "} <i>{incident.created}</i>
                  </span>
                </CardHeader>
                <CardBody>
                  <GridContainer
                    // justifyContent="center"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <GridItem
                      style={{
                        width: "82%",
                        marginBottom: "7%",
                        marginTop: "5%",
                        marginRight: "auto",
                        marginLeft: "auto",
                      }}
                    >
                      {this.state.video_renderer(incident)}
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </div>
          );
        })}
        <br />
        <br />
        <div
          style={{
            flex: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            {"showing " +
            (this.state.pageSize < this.state.total
              ? this.state.pageSize.toString()
              : this.state.total.toString()) +
            " of " +
            this.state.total.toString() +
            " incidents"
              ? this.state.total > 0
              : "loading"}{" "}
          </p>
        </div>
        <div
          style={{
            flex: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Pagination pages={this.state.pages} color={this.state.activeColor} />
        </div>
        <br />
      </div>
    );
  }
}

function withSessionContext(Component) {
  return function WrapperComponent(props) {
    return (
      <SessionContext.Consumer>
        {(state) => <Component {...props} context={state} />}
      </SessionContext.Consumer>
    );
  };
}

export default withI18n(withSessionContext(withStyles(sidebarStyle)(IncidentList)));
