import React from "react";
import PropTypes from "prop-types";

import { SessionContext } from "layouts/Dashboard.jsx";
import { NotificationManager } from "react-notifications";
import Api from "modules/api.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import withI18n from "modules/withI18n";
import withStyles from "@material-ui/core/styles/withStyles";

import Form from '@rjsf/material-ui';
import validator from '@rjsf/validator-ajv8';
import applyNavs from "react-jsonschema-form-pagination";

import "./cartwatch_config.css";

class CartwatchConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      block_rendering: true
    };
    this.reloadConfig = this.reloadConfig.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.loadData(this.props.context.instance_id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.context.instance_id !== prevProps.context.instance_id) {
      this.setState({block_rendering: true});
      if (typeof (this.props.context.instance_id) != "undefined") {
        this._isMounted && this.loadData(this.props.context.instance_id);
      }
    }
  }

  unblockRendering() {
    this.setState({ block_rendering: false });
  }

  reloadConfig() {
    this._isMounted && this.loadData(this.props.context.instance_id);
  }

  loadData(instance_id) {
    if (instance_id === undefined) {
      return;
    }
    Api.get(`/api/instances/${instance_id}/config`).then((res) => {
      this.setState({
        cartwatchConfig: res.cartwatch_config,
        cartwatchPath: res.cartwatch_config_path,
        cartwatchSchema: res.cartwatch_config_schema,
      }, this.unblockRendering);
    }).catch((e) => {
      NotificationManager.error(e.message, "Error loading Cartwatch config!");
    });
  }

  saveData(instance_id, data) {
    if (instance_id === undefined) {
      return;
    }
    Api.put(`/api/instances/${instance_id}/config`, data).then((res) => {
      NotificationManager.success("Cartwatch config saved!");
    }).catch((e) => {
      NotificationManager.error(e.message, "Error saving Cartwatch config!");
    });

  }

  render() {
    if (this.state.block_rendering) {
      return (<div>Loading...</div>);
    }
    else {
      const uiSchema = {
        "ui:title": "Config path: " + this.state.cartwatchPath,
        base:{"nav": "Base"},
        pipeline:{"nav": "Pipeline"},
        tracking:{"nav": "Tracking"},
        broker:{"nav": "Broker"},
        sources:{"nav": "Sources"},
        models:{"nav": "Models"},
        apps:{"nav": "Apps"},
        stats:{"nav": "Stats"}
      }
      let FormWithPagination = applyNavs(Form);
      return (<FormWithPagination title={this.state.cartwatchPath}
                                  schema={this.state.cartwatchSchema}
                                  uiSchema={uiSchema}
                                  formData={this.state.cartwatchConfig}
                                  validator={validator}
                                  onSubmit={(e) => this.saveData(this.props.context.instance_id, e.formData)}/>);
    }
  }
}

CartwatchConfig.propTypes = {
  classes: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

function withSessionContext(Component) {
  return function WrapperComponent(props) {
    return (
        <SessionContext.Consumer>
          {(state) => <Component {...props} context={state}/>}
        </SessionContext.Consumer>
    );
  };
}

export default withSessionContext(withI18n(withStyles(dashboardStyle)(CartwatchConfig)));
