import React, { useState } from "react";

import {classifierConfigSchema} from "variables/schemas/model_configs/model_params/classifier_schema.jsx";
import {definitionForm} from "components/Config/DefinitionForm.jsx";
import {DeleteConfirmOverlayButton} from "components/Config/DeleteConfirmOverlayButton.jsx";
import {detectorParamsSchema} from "variables/schemas/model_configs/model_params/detector_schema.jsx";
import {keypointDetectorParamsSchema} from "variables/schemas/model_configs/model_params/keypoint_model_schema.jsx";
import {featureExtractorConfigSchema} from "variables/schemas/model_configs/model_params/feature_extractor_schema.jsx";
import {
  pipelineModelDefinitionsSchema,
  appModelDefinitionsSchema,
  faceModelDefinitionsSchema
} from "variables/schemas/model_configs/models_schema.jsx";

import Button from "components/CustomButtons/Button.jsx";
import CardBody from "components/Card/CardBody.jsx";
import cardStyle from "assets/jss/material-dashboard-pro-react/components/cardStyle.jsx";
import classNames from "classnames";
import Edit from "@material-ui/icons/Edit";
import GridItem from "components/Grid/GridItem.jsx";
import Overlay from "react-overlay-component";
import PropTypes from "prop-types";
import StateButton from "components/Config/StateButton.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

function ModelCard({ ...props }) {
  const [isOpen, setOverlay] = useState(false);
  const {
    classes,
    className,
    plain,
    background,
    color,
    modelCategory,
    model,
    updateFunction,
    deleteFunction,
    statusToggleFunction,
    availableSources,
    ...rest
  } = props;
  const closeOverlay = () => setOverlay(false);
  function updateModel(modelID, modelDefinition) {
    updateFunction(modelCategory, modelID, modelDefinition);
    closeOverlay();
  }

  function updateStatus() {
    statusToggleFunction(modelCategory, model);
  }

  function showModel() {
    if (model.model) {
      return (<p><b>model: </b> {model.model}</p>);
    }
    //  else {
    //   return (<p><b>model: </b> <i> undefined </i> </p>);
    // }
  }
  function showDevice() {
    let deviceID = model.model_params.device || model.model_params.device_id;
    if (!deviceID && model.type === "detector") deviceID = model.model_params.detector_device_id;
    if (!deviceID && model.type === "keypoint_detector") deviceID = model.model_params.keypoint_model_device_id;
    if (!deviceID && model.type === "classifier") deviceID = model.model_params.classifier_device_id;
    if (!deviceID && model.type === "feature_extractor") deviceID = model.model_params.feature_extractor_device_id;
    if (deviceID) {
      if (deviceID.length === 1) {
        deviceID = `gpu:${deviceID}`;
      }
      model.model_params.device = deviceID;
      return (<b>({deviceID})</b>);
    } else {
      return null;
    }
  }

  function showSource(sourceName) {
    if (sourceName) {
      return (<span key={sourceName}> - {sourceName}</span>);
    } else {
      return null;
    }
  }

  function showSources() {
    let elements = [];
    if (modelCategory === "pipeline") {
      if (typeof(model.sources) == "undefined" || !model.sources.length ) {
        model.sources = [];
        elements.push(<p key={"sources-header" + model.name}> <b>sources: </b>  <i> none </i> </p>);
      } else {
        elements.push(<b key={"sources-header" + model.name}>sources: </b>);
        elements.push(<br />);
        for (let source of model.sources) {
          elements.push(showSource(source));
          elements.push(<br />);
        }
      }
    }
    return elements;
  }

  if (model) {
    const cardClasses = classNames({
      [classes.card]: true,
      [classes.cardPlain]: plain,
      [classes.cardBackground]: background,
      [classes[color]]: color,
      [className]: className !== undefined
    });

    let backgroundColor = "#fafafa";
    let fontColor = "#AAAAAA";
    if (model.enabled !== false) {
      backgroundColor = "#FFFFFF";
      fontColor = "#333333";
    }


    const modelID = model.name;

    const modelParamsSchemaByType = {
      classifier: classifierConfigSchema,
      detector: detectorParamsSchema,
      feature_extractor: featureExtractorConfigSchema,
      keypoint_detector: keypointDetectorParamsSchema,
    };

    let schema = appModelDefinitionsSchema;

    if (modelCategory === "pipeline") {
      schema = pipelineModelDefinitionsSchema;
      schema.properties.sources.items.allowedValues = availableSources;
    }

    if (modelCategory === "face"){
      schema = faceModelDefinitionsSchema;
    }

    if (!model.type) {
      model.type = (modelCategory === "app") ? "classifier" : "detector";
    }
    if (!model.model && !model.name.includes("new_" + modelCategory + "_model_")) {
      model.model = model.name;
    }
    if (!model.model_params) {
      model.model_params = {device: undefined};
    }
    schema.properties.model_params = modelParamsSchemaByType[model.type];

    // console.log("model schema");
    // console.log(schema);
    const configs = {
      animate: true,
      clickDismiss: true,
      escapeDismiss: true,
      focusOutline: true,
      contentClass: "overlay-container"
    };
    return (
      <GridItem xs={12} sm={12} md={6}>
        <div className={cardClasses}  {...rest} style={{ background: backgroundColor}}>
          <GridItem
            xs={12}
            style={{
              width: "100%",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <StateButton
              key={model.name + "State"}
              status={model.enabled !== false}
              updateFunction={updateStatus}
            />
            <h5 className={classes.cardCategory} style={{fontWeight: 600, color: fontColor, display:"flex", overflow: "hidden", boxShadow: "inset 6px 6px 10px 0 rgba(50,50, 50, 0.05), inset -6px -6px 10px 0 rgba(255, 255, 255, 0.48)", background: "linear-gradient(-45deg, rgba(80, 80, 80, 0.03), rgba(255,255, 255, 0.4))", borderRadius: "5px", width: "82%", marginLeft: "auto", marginRight: "5%", marginTop: "-3%", marginBottom: "2%", paddingLeft: "3%", paddingTop:"2%", paddingBottom: "2%"}}>
              {model.name}
            </h5>
          </GridItem>
          <CardBody
            style={{
              width: "92%",
              marginLeft: "4%",
              marginRight: "10%",
              marginBottom: "2%",
            }}>
            <span style={{fontFamily: "\"Fira Mono\", \"Helvetica\"", color: fontColor, fontSize: "12px"}}>
              <p> {model.type} {showDevice()}</p>
              {showModel()}
              {showSources()}
              <br />
            </span>
            <DeleteConfirmOverlayButton
              item={model}
              deleteFunction={() => deleteFunction(modelCategory, model)}
            />
            <Button
              style={{ float: "right", marginTop: "2%", marginRight: "-2%", width: "23%", background: "#333333", boxShadow: "inset 5px 5px 8px 0 rgba(0, 0, 0, 0.25), inset -5px -5px 8px 0 rgba(255, 255, 255, 0.15)" }}
              onClick={() => setOverlay(true)}
            >
              <Edit style={{ float: "center", color: "#FFFFFF" }} />
            </Button>
          </CardBody>
        </div>
        <Overlay style={{backgroundColor: "rgba(50, 0, 50, 0.68"}} configs={configs} isOpen={isOpen} closeOverlay={closeOverlay}>
          <div style={{width: "100%", overflow: "visible"}}>
            <h4>{model.name}</h4>
            {definitionForm(schema, modelID, model, updateModel)}
          </div>
        </Overlay>
      </GridItem>
    );
  } else {
    return null;
  }
}

ModelCard.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  plain: PropTypes.bool,
  background: PropTypes.bool,

  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ]),
};

export default withStyles(cardStyle)(ModelCard);
