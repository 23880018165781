const drsIntegrationSchema = {
  properties: {
    sco_id: {
      title: "SCO id",
      type: "integer"
    },
  },
  required: []
};

export {drsIntegrationSchema};
