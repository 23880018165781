const integrationDefinitionSchema = {
    title: "Integration Definition",
    type: "object",
    properties: {
        source_id: {
            title: "Source",
            description: "Integration name",
            type: "string",
            disabled: true
        },
        name: {
            title: "Name",
            description: "Integration name",
            type: "string",
            min: 1
        },
        type: {
            title: "Type",
            type: "string",
            allowedValues: ["drs", "gk", "itab", "itab_v2", "rewe"]
        },
    },
    required: ["source_id", "type", "name"]
};

export {integrationDefinitionSchema};
