import React from "react";
import PropTypes from "prop-types";

import { SessionContext } from "layouts/Dashboard.jsx";
import { NotificationManager } from "react-notifications";
import Add from "@material-ui/icons/Add";
import Adjust from "@material-ui/icons/Adjust";
import Api from "modules/api.js";
import AppCard from "components/Config/AppCard.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import GridContainer from "components/Grid/GridContainer.jsx";
import ModelCard from "components/Config/ModelCard.jsx";
import SourceCard from "components/Config/SourceCard.jsx";
import TripOrigin from "@material-ui/icons/TripOrigin";
import Videocam from "@material-ui/icons/Videocam";
import withI18n from "modules/withI18n";
import withStyles from "@material-ui/core/styles/withStyles";


class Definitions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      block_rendering: true
    };
    this.addApp = this.addApp.bind(this);
    this.updateAppDefinition = this.updateAppDefinition.bind(this);
    this.deleteAppDefinition = this.deleteAppDefinition.bind(this);
    this.toggleAppStatus = this.toggleAppStatus.bind(this);
    this.startTestAlarm = this.startTestAlarm.bind(this);
    this.addSource = this.addSource.bind(this);
    this.updateSourceDefinition = this.updateSourceDefinition.bind(this);
    this.reloadDefinitions = this.reloadDefinitions.bind(this);
    this.deleteSourceDefinition = this.deleteSourceDefinition.bind(this);
    this.startRecording = this.startRecording.bind(this);
    this.stopRecording = this.stopRecording.bind(this);
    this.addModel = this.addModel.bind(this);
    this.updateModelDefinition = this.updateModelDefinition.bind(this);
    this.deleteModelDefinition = this.deleteModelDefinition.bind(this);
    this.toggleModelStatus = this.toggleModelStatus.bind(this);
    this.addIntegration = this.addIntegration.bind(this);
    this.updateIntegrationDefinition = this.updateIntegrationDefinition.bind(this);
    this.deleteIntegrationDefinition = this.deleteIntegrationDefinition.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.loadData(this.props.context.instance_id, this.props.context.definitions_file_id);
  }

  reloadDefinitions() {
    this._isMounted && this.loadData(this.props.context.instance_id, this.props.context.definitions_file_id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.context.instance_id !== prevProps.context.instance_id ||
      this.props.context.definitions_file_id !== prevProps.context.definitions_file_id) {
      this.setState({block_rendering: true});
      if (typeof(this.props.context.instance_id) != "undefined" &&
        typeof(this.props.context.definitions_file_id) != "undefined")  {
        this._isMounted && this.loadData(this.props.context.instance_id, this.props.context.definitions_file_id);
      }
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  unblockRendering() {
    this.setState({ block_rendering: false });
  }

  loadRecordingJobs(instance_id, definitions_file_id) {
    Api.get(`/api/instances/${instance_id}/${definitions_file_id}/recording_jobs`).then((res) => {
      this.setState({recordingJobs: res.data.recording_jobs});
    }).catch((e) => {
      NotificationManager.error(e.message, "Error fetching recording jobs!");
    });
  }
  loadData(instance_id, definitions_file_id) {
    if (typeof(instance_id) == "undefined" || typeof(definitions_file_id) == "undefined") {
      return;
    }
    Api.get(`/api/instances/${instance_id}/${definitions_file_id}`).then((res) => {
      this.setState({
        appDefinitions: res.data.app_definitions,
        modelDefinitions: res.data.model_definitions,
        sourceDefinitions: res.data.source_definitions,
        availableSources: res.data.available_sources,
        availableAppModels: res.data.available_app_models,
        availableFaceModels: res.data.available_face_models,
        availableAlarmProfiles: res.data.available_alarm_profiles,
        availableZonesFiles: res.data.available_zones_files,
      }, this.unblockRendering);
      this.loadRecordingJobs(instance_id, definitions_file_id);
    }).catch((e) => {
      NotificationManager.error(e.message, "Error loading definitions!");
    });
  }

  perspectiveForApp(app) {
    //TODO: I added this line since It was failing because of an empty app.
    //If you know the reason let me know @blgnksy
    if (!app) {return {};}
    let perspectiveInfo = {sourceID: app.source};
    if (!app.source) {return perspectiveInfo;}
    let found = false;
    this.state.sourceDefinitions.forEach((source) => {
      if (!found && source.name === app.source) {
        perspectiveInfo.sourceID = source.name;
        perspectiveInfo.perspectiveID = source.perspective_id;
        found = true;
      }
      return found;
    });
    return perspectiveInfo;
  }


  createAppCards(){
    let elements = [];
    let enabled = [];
    let disabled = [];
    this.state.appDefinitions.forEach((app) => {
      if (app.enabled !== false) {
        enabled.push(app);
      } else {
        disabled.push(app);
      }
    });
    for (let i = 1; i - 1 < enabled.length; i = i + 2) {
      elements.push(this.appCardPair(i, enabled[i-1], enabled[i]));
    }
    elements.push(<br key={"appCardPairBr1"} />);
    elements.push(<br key={"appCardPairBr2"} />);
    for (let i = 1; i - 1 < disabled.length; i = i + 2) {
      elements.push(this.appCardPair(i, disabled[i-1], disabled[i]));
    }
    return elements;
  }

  createSourceCards(){
    let elements = [];
    let enabled = [];
    let disabled = [];
    this.state.sourceDefinitions.forEach((source) => {
      if (source.enabled !== false) {
        enabled.push(source);
      } else {
        disabled.push(source);
      }
    });
    for (let i = 1; i - 1 < enabled.length; i = i + 2) {
      elements.push(this.sourceCardPair(i, enabled[i-1], enabled[i]));
    }
    elements.push(<br key={"sourceCardPairBr1"} />);
    elements.push(<br key={"sourceCardPairBr2"} />);
    for (let i = 1; i - 1 < disabled.length; i = i + 2) {
      elements.push(this.sourceCardPair(i, disabled[i-1], disabled[i]));
    }
    return elements;
  }

  createPipelineModelCards(){
    if (!("pipeline" in this.state.modelDefinitions)) {
      return [];
    }
    let elements = [];
    let enabled = [];
    let disabled = [];
    this.state.modelDefinitions.pipeline.forEach((pipelineModel) => {
      if (pipelineModel.enabled !== false) {
        enabled.push(pipelineModel);
      } else {
        disabled.push(pipelineModel);
      }
    });
    for (let i = 1; i - 1 < enabled.length; i = i + 2) {
      elements.push(this.modelCardPair(i, enabled[i-1], enabled[i], "pipeline"));
    }
    elements.push(<br key={"pModelCardPairBr1"} />);
    elements.push(<br key={"pModelCardPairBr2"} />);
    for (let i = 1; i - 1 < disabled.length; i = i + 2) {
      elements.push(this.modelCardPair(i, disabled[i-1], disabled[i], "pipeline"));
    }
    return elements;
  }

  createAppModelCards(){
    if (!("app" in this.state.modelDefinitions)) {
      return [];
    }
    let elements = [];
    let enabled = [];
    let disabled = [];
    this.state.modelDefinitions.app.forEach((appModel) => {
      if (appModel.enabled !== false) {
        enabled.push(appModel);
      } else {
        disabled.push(appModel);
      }
    });
    for (let i = 1; i - 1 < enabled.length; i = i + 2) {
      elements.push(this.modelCardPair(i, enabled[i-1], enabled[i], "app"));
    }
    elements.push(<br key={"aModelCardPairBr1"} />);
    elements.push(<br key={"aModelCardPairBr2"} />);
    for (let i = 1; i - 1 < disabled.length; i = i + 2) {
      elements.push(this.modelCardPair(i, disabled[i-1], disabled[i], "app"));
    }
    return elements;
  }

  createFaceModelCards(){
    if (!("face" in this.state.modelDefinitions)) {
      return [];
    }
    let elements = [];
    let enabled = [];
    this.state.modelDefinitions.face.forEach((faceModel) => {
      enabled.push(faceModel);
    });
    for (let i = 1; i - 1 < enabled.length; i = i + 2) {
      elements.push(this.modelCardPair(i, enabled[i-1], enabled[i], "face"));
    }
    elements.push(<br key={"pModelCardPairBr1"} />);
    elements.push(<br key={"pModelCardPairBr2"} />);
    return elements;
  }
  appCardPair(i, app1, app2) {
    const { classes } = this.props;
    if (app1) {
      const key = "appCardPair" + app1.name  + i;
      return (
        <GridContainer
          key={key}
          style={{
            width: "100%",
            marginRight: "auto",
            marginLeft: "auto",
          }}>
          <AppCard
            key={key + "1"}
            className={classes.cardHover}
            instance_id={this.props.context.instance_id}
            definitions_file_id={this.props.context.definitions_file_id}
            app={app1}
            perspectiveInfo={this.perspectiveForApp(app1)}
            updateFunction={this.updateAppDefinition}
            deleteFunction={this.deleteAppDefinition}
            statusToggleFunction={this.toggleAppStatus}
            startTestAlarmFunction={this.startTestAlarm}
            availableAppModels={this.state.availableAppModels}
            availableFaceModels={this.state.availableFaceModels}
            availableSources={this.state.availableSources}
            availableAlarmProfiles={this.state.availableAlarmProfiles}
            availableZonesFiles={this.state.availableZonesFiles} />
          <AppCard
            key={key + "2"}
            className={classes.cardHover}
            instance_id={this.props.context.instance_id}
            definitions_file_id={this.props.context.definitions_file_id}
            app={app2}
            perspectiveInfo={this.perspectiveForApp(app2)}
            updateFunction={this.updateAppDefinition}
            deleteFunction={this.deleteAppDefinition}
            statusToggleFunction={this.toggleAppStatus}
            startTestAlarmFunction={this.startTestAlarm}
            availableAppModels={this.state.availableAppModels}
            availableFaceModels={this.state.availableFaceModels}
            availableSources={this.state.availableSources}
            availableAlarmProfiles={this.state.availableAlarmProfiles}
            availableZonesFiles={this.state.availableZonesFiles} />
        </GridContainer>
      );
    } else {
      return null;
    }
  }

  sourceCardPair(i, source1, source2) {
    const { classes } = this.props;
    if (source1) {
      const key = "sourceCardPair" + source1.name + i;
      return (
        <GridContainer
          key={key}
          style={{
            width: "100%",
            marginRight: "auto",
            marginLeft: "auto",
          }}>
          <SourceCard
            key={key + "1"}
            className={classes.cardHover}
            instance_id={this.props.context.instance_id}
            definitions_file_id={this.props.context.definitions_file_id}
            source={source1}
            definitionsObj={this}
            updateFunction={this.updateSourceDefinition}
            deleteFunction={this.deleteSourceDefinition}
            recordingJobs={this.state.recordingJobs}
            startRecordingFunction={this.startRecording}
            stopRecordingFunction={this.stopRecording}
          />
          <SourceCard
            key={key + "2"}
            className={classes.cardHover}
            instance_id={this.props.context.instance_id}
            definitions_file_id={this.props.context.definitions_file_id}
            source={source2}
            definitionsObj={this}
            updateFunction={this.updateSourceDefinition}
            deleteFunction={this.deleteSourceDefinition}
            recordingJobs={this.state.recordingJobs}
            startRecordingFunction={this.startRecording}
            stopRecordingFunction={this.stopRecording}
          />
        </GridContainer>
      );
    } else {
      return null;
    }
  }

  modelCardPair(i, model1, model2, modelCategory) {
    const { classes } = this.props;
    if (model1) {
      const key = modelCategory + "ModelCardPair" + model1.name + i;
      return (
        <GridContainer
          key={key}
          style={{
            width: "100%",
            marginRight: "auto",
            marginLeft: "auto",
          }}>
          <ModelCard
            key={key + "1"}
            className={classes.cardHover}
            modelCategory={modelCategory}
            model={model1}
            statusToggleFunction={this.toggleModelStatus}
            updateFunction={this.updateModelDefinition}
            deleteFunction={this.deleteModelDefinition}
            availableSources={this.state.availableSources} />
          <ModelCard
            key={key + "2"}
            className={classes.cardHover}
            modelCategory={modelCategory}
            model={model2}
            statusToggleFunction={this.toggleModelStatus}
            updateFunction={this.updateModelDefinition}
            deleteFunction={this.deleteModelDefinition}
            availableSources={this.state.availableSources}
          />
        </GridContainer>
      );
    } else {
      return null;
    }
  }

  addAppButton() {
    return (
      <Button
        style={{ float: "right", marginRight: "2%", marginTop: "2%", marginBottom: "1%", width: "10%", background: "#457DE3" }}
        onClick={() => this.addApp()}
      >
        <Add style={{ float: "center", color: "#FFFFFF" }} />
      </Button>
    );
  }
  addSourceButton() {
    return (
      <Button
        style={{ float: "right", marginRight: "2%", marginTop: "2%", marginBottom: "1%", width: "10%", background: "#457DE3" }}
        onClick={() => this.addSource()}
      >
        <Add style={{ float: "center", color: "#FFFFFF" }} />
      </Button>
    );
  }
  addPipelineModelButton() {
    return (
      <Button
        style={{ float: "right", marginTop: "2%", marginBottom: "1%", width: "12%", background: "#457DE3" }}
        onClick={() => this.addModel("pipeline")}
      >
        <Add style={{ float: "center", color: "#FFFFFF" }} />
      </Button>

    );
  }
  addAppModelButton() {
    return (
      <Button
        style={{ float: "right", marginTop: "2%", marginBottom: "1%", width: "12%", background: "#457DE3" }}
        onClick={() => this.addModel("app")}
      >
        <Add style={{ float: "center", color: "#FFFFFF" }} />
      </Button>
    );
  }

  addFaceModelButton() {
    return (
      <Button
        style={{ float: "right", marginTop: "2%", marginBottom: "1%", width: "12%", background: "#457DE3" }}
        onClick={() => this.addModel("face")}
      >
        <Add style={{ float: "center", color: "#FFFFFF" }} />
      </Button>
    );
  }
  addApp() {
    let newApp = {name: "new_app_", type: "checkout", enabled: false};
    Api.post(`/api/instances/${this.props.context.instance_id}/${this.props.context.definitions_file_id}/apps/new`, {
      app: newApp,
    }).then(() => {
      this.reloadDefinitions();
      NotificationManager.success("Successfully created new app entry", "App Created");
    }).catch((e) => {
      NotificationManager.error(e.message, "Could not add new app.");
    });
  }
  updateAppDefinition(appID, updatedAppDefinition) {
    Api.post(`/api/instances/${this.props.context.instance_id}/${this.props.context.definitions_file_id}/apps/${appID}`, {
      app: updatedAppDefinition
    }).then(() => {
      this.reloadDefinitions();
      NotificationManager.success("Successfully updated " + appID + ".", "App Updated");
    }).catch((e) => {
      NotificationManager.error(e.message, `Could not update  ${appID}.`);
    });
  }
  deleteAppDefinition(appDefinition) {
    Api.post(`/api/instances/${this.props.context.instance_id}/${this.props.context.definitions_file_id}/apps/${appDefinition.name}/delete`, {
      app: appDefinition
    }).then(() => {
      this.reloadDefinitions();
      NotificationManager.info("Successfully deleted " + appDefinition.name + ".", "App Deleted");
    }).catch((e) => {
      NotificationManager.error(e.message, `Could not delete ${appDefinition.name}.`);
    });
  }
  toggleAppStatus(updatedAppDefinition) {
    const newState = !(updatedAppDefinition.enabled !== false);
    Api.post(`/api/instances/${this.props.context.instance_id}/${this.props.context.definitions_file_id}/apps/${updatedAppDefinition.name}/status`, {
      enabled: newState
    }).then(() => {
      this.reloadDefinitions();
      const verb = (newState) ? "Enabled" : "Disabled";
      NotificationManager.info(`${verb} the app ${updatedAppDefinition.name}.`, verb);
    }).catch((e) => {
      NotificationManager.error(e.message, `Could not enable/disable ${updatedAppDefinition.name}.`);
    });
  }
  startTestAlarm(appDefinition) {
    Api.post(`/api/instances/${this.props.context.instance_id}/${this.props.context.definitions_file_id}/apps/${appDefinition.name}/trigger_alarm`).then(() => {
      // this.loadRecordingJobs(this.props.context.definitions_file_id);
      NotificationManager.success("Triggered Test Alarm for " + appDefinition.name + ".");
    }).catch((e) => {
      NotificationManager.error(e.message, `Could not trigger test alarm for ${appDefinition.name}.`);
    });
  }

  addSource() {
    let newSource = {name: "new_source_", type: "rtsp", enabled: false};
    Api.post(`/api/instances/${this.props.context.instance_id}/${this.props.context.definitions_file_id}/sources/new`, {
      source: newSource,
    }).then(() => {
      this.reloadDefinitions();
      NotificationManager.success("Successfully created new source entry.", "Source Created");
    }).catch((e) => {
      NotificationManager.error(e.message, "Could not add new source.");
    });
  }
  updateSourceDefinition(sourceID, updatedSourceDefinition) {
    Api.post(`/api/instances/${this.props.context.instance_id}/${this.props.context.definitions_file_id}/sources/${sourceID}`, {
      source: updatedSourceDefinition
    }).then(() => {
      this.reloadDefinitions();
      NotificationManager.success("Successfully updated " + sourceID + ".", "Source Updated");
    }).catch((e) => {
      NotificationManager.error(e.message, `Could not update ${sourceID}.`);
    });
  }

  deleteSourceDefinition(sourceDefinition) {
    Api.post(`/api/instances/${this.props.context.instance_id}/${this.props.context.definitions_file_id}/sources/${sourceDefinition.name}/delete`, {
      source: sourceDefinition
    }).then(() => {
      this.reloadDefinitions();
      NotificationManager.info("Successfully deleted " + sourceDefinition.name + ".", "Source Deleted");
    }).catch((e) => {
      NotificationManager.error(e.message, `Could not delete ${sourceDefinition.name}.`);
    });
  }
  startRecording(sourceDefinition) {
    Api.get(`/api/instances/${this.props.context.instance_id}/${this.props.context.definitions_file_id}/sources/${sourceDefinition.name}/start_recording`).then(() => {
      this.loadRecordingJobs(this.props.context.instance_id, this.props.context.definitions_file_id);
      NotificationManager.success("Started recording from " + sourceDefinition.name + ".");
    }).catch((e) => {
      NotificationManager.error(e.message, `Could not record from ${sourceDefinition.location}.`);
    });
  }
  stopRecording(sourceDefinition) {
    Api.get(`/api/instances/${this.props.context.instance_id}/${this.props.context.definitions_file_id}/sources/${sourceDefinition.name}/stop_recording`).then(() => {
      this.loadRecordingJobs(this.props.context.instance_id, this.props.context.definitions_file_id);
      NotificationManager.info("Stopped Recording from " + sourceDefinition.name + ".");
    }).catch((e) => {
      NotificationManager.error(e.message, `Could not stop recording from ${sourceDefinition.name}.`);
    });
  }

  addModel(modelCategory) {
    const defaultType = (modelCategory === "app") ? "classifier" : "detector";
    let newModel = {name: "new_" + modelCategory + "_model_", type: defaultType};
    if (modelCategory === "pipeline") {
      newModel.sources = [];
    }
    Api.post(`/api/instances/${this.props.context.instance_id}/${this.props.context.definitions_file_id}/models/${modelCategory}/new`, {
      model: newModel,
    }).then(() => {
      this.reloadDefinitions();
      NotificationManager.success("Successfully created new " + modelCategory + " model entry.", "New Model Entry Created");
    }).catch((e) => {
      NotificationManager.error(e.message, `Could not add new ${modelCategory} model.`);
    });
  }
  updateModelDefinition(modelCategory, modelID, updatedModelDefinition) {
    console.log(modelCategory);
    console.log(modelID);
    console.log(updatedModelDefinition);
    Api.post(`/api/instances/${this.props.context.instance_id}/${this.props.context.definitions_file_id}/models/${modelCategory}/${modelID}`, {model: updatedModelDefinition}).then(() => {
      this.reloadDefinitions();
      NotificationManager.success("Successfully updated " + modelID, "Model Updated");
    }).catch((e) => {
      NotificationManager.error(e.message, `Could not update ${modelCategory} model ${modelID}.`);
    });
  }
  deleteModelDefinition(modelCategory, modelDefinition) {
    Api.post(`/api/instances/${this.props.context.instance_id}/${this.props.context.definitions_file_id}/models/${modelCategory}/${modelDefinition.name}/delete`, {
      model: modelDefinition
    }).then(() => {
      this.reloadDefinitions();
      NotificationManager.info("Successfully deleted " + modelDefinition.name + ".", "Model Deleted");
    }).catch((e) => {
      NotificationManager.error(e.message, `Could not delete ${modelCategory} model ${modelDefinition.name}.`);
    });
  }
  toggleModelStatus(modelCategory, updatedModelDefinition) {
    if (modelCategory === "app") {
      NotificationManager.info(`${modelCategory} models are enabled & disabled automatically.`, "Not allowed.");
      return;
    }
    const newState = !(updatedModelDefinition.enabled !== false);
    Api.post(`/api/instances/${this.props.context.instance_id}/${this.props.context.definitions_file_id}/models/${modelCategory}/${updatedModelDefinition.name}/status`, {
      enabled: newState
    }).then(() => {
      this.reloadDefinitions();
      const verb = (newState) ? "Enabled" : "Disabled";
      NotificationManager.info(verb + " the " + modelCategory + " model " + updatedModelDefinition.name + ".", verb);
    }).catch((e) => {
      NotificationManager.error(e.message, `Could enable/disable ${updatedModelDefinition.name}.`);
    });
  }

  addIntegration(props) {
    Api.post(`/api/instances/${this.props.context.instance_id}/${this.props.context.definitions_file_id}/integrations/${props.sourceID}/new`, {
      integration: props.updatedIntegrationDefinition,
    }).then(() => {
      this.reloadDefinitions();
      NotificationManager.success(`Successfully added integration ${props.updatedIntegrationDefinition.name} to ${props.sourceID}.`, "Integration Created");
    }).catch((e) => {
      NotificationManager.error(e.message, `Could not add new integration to ${props.sourceID}.`);
    });
  }
  updateIntegrationDefinition(props) {
    Api.post(`/api/instances/${this.props.context.instance_id}/${this.props.context.definitions_file_id}/integrations/${props.sourceID}/update/${props.integrationDefinition.name}`, {
      integration: props.updatedIntegrationDefinition
    }).then(() => {
      this.reloadDefinitions();
      NotificationManager.success(`Successfully updated integration ${props.integrationDefinition.name} in ${props.sourceID}.`, "Integration Updated");
    }).catch((e) => {
      NotificationManager.error(e.message, `Could not update ${props.integrationDefinition.name} in ${props.sourceID}.`);
    });
  }
  deleteIntegrationDefinition(props) {
    Api.post(`/api/instances/${this.props.context.instance_id}/${this.props.context.definitions_file_id}/integrations/${props.sourceID}/delete/${props.integrationDefinition.name}`, {
      integration: props.integrationDefinition
    }).then(() => {
      this.reloadDefinitions();
      NotificationManager.info(`Successfully deleted integration ${props.integrationDefinition.name} from ${props.sourceID}.`, "Integration Deleted");
    }).catch((e) => {
      NotificationManager.error(e.message, `Could not delete ${props.integrationDefinition.name} from ${props.sourceID}.`);
    });
  }

  render() {
    if (this.state.block_rendering) {
      return null;
    }
    const { classes, t } = this.props;
    const appDefinitionsHeader = t("definitions.apps");
    const sourceDefinitionsHeader = t("definitions.sources");
    return (
      <div
        style={{marginTop: "2%", marginBottom: "6%", paddingTop: "1%", paddingBottom: "3%", fontFamily: "Fira Mono"}}
      >
        <CustomTabs
          headerColor="info"
          style={{width: "fit-content"}}
          tabs={[
            {
              tabName: ` ${appDefinitionsHeader} (${this.state.appDefinitions.length}) `,
              tabIcon: TripOrigin,
              tabContent: (
                <div style={{paddingBottom: "3%"}}>
                  {this.addAppButton()}
                  {this.createAppCards()}
                </div>
              )
            },
            {
              tabName: ` ${sourceDefinitionsHeader} (${this.state.sourceDefinitions.length}) `,
              tabIcon: Videocam,
              tabContent: (
                <div style={{paddingBottom: "3%"}}>
                  {this.addSourceButton()}
                  {this.createSourceCards()}
                </div>
              )
            },
            {
              tabName: " Models (" + parseFloat("app" in this.state.modelDefinitions ? this.state.modelDefinitions["app"].length : 0 +
                  "pipeline" in this.state.modelDefinitions ? this.state.modelDefinitions["pipeline"].length : 0 +
                  "face" in this.state.modelDefinitions ? this.state.modelDefinitions["face"].length : 0
              ) + ") ",
              tabIcon: Adjust,
              tabContent: (
                <div style={{marginBottom: "3%", paddingBottom: "3%"}}>
                  <Card className={classes.cardHover} style={{marginBottom: "4%", paddingBottom: "2%"}}>
                    <h4 style={{fontWeight: 600, width: "95%", margin: "auto", marginTop: "3%", marginBottom: "0%", paddingLeft: "1%", paddingTop: "0%", paddingBottom: "0%"}}>
                    Pipeline Models ({"pipeline" in this.state.modelDefinitions ? this.state.modelDefinitions["pipeline"].length : 0})
                      {this.addPipelineModelButton()}
                    </h4>
                    {this.createPipelineModelCards()}
                  </Card>
                  <Card className={classes.cardHover} style={{paddingBottom: "2%"}}>
                    <h4 style={{fontWeight: 600, width: "95%", margin: "auto", marginTop: "3%", marginBottom: "0%", paddingLeft: "1%", paddingTop: "0%", paddingBottom: "0%"}}>
                    App Models ({"app" in this.state.modelDefinitions ? this.state.modelDefinitions["app"].length : 0})
                      {this.addAppModelButton()}
                    </h4>
                    {this.createAppModelCards()}
                  </Card>
                  <Card className={classes.cardHover} style={{paddingBottom: "2%"}}>
                    <h4 style={{fontWeight: 600, width: "95%", margin: "auto", marginTop: "3%", marginBottom: "0%", paddingLeft: "1%", paddingTop: "0%", paddingBottom: "0%"}}>
                    Face Anonymizer ({"face" in this.state.modelDefinitions ? this.state.modelDefinitions["face"].length : 0})
                      {this.addFaceModelButton()}
                    </h4>
                    {this.createFaceModelCards()}
                  </Card>
                </div>
              )
            }
          ]}
        />
      </div>
    );
  }
}

Definitions.propTypes = {
  classes: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

function withSessionContext(Component) {
  return function WrapperComponent(props) {
    return (
      <SessionContext.Consumer>
        {(state) => <Component {...props} context={state} />}
      </SessionContext.Consumer>
    );
  };
}

export default withSessionContext(withI18n(withStyles(dashboardStyle)(Definitions)));
