import React from "react";
import PropTypes from "prop-types";
// react plugin for creating vector maps

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Entering from "components/Charts/Entrance/Entering.jsx";
import EnteringPerHour from "components/Charts/Entrance/EnteringPerHour.jsx";
import EnteringPerWeekday from "components/Charts/Entrance/EnteringPerWeekday.jsx";
import Exiting from "components/Charts/Entrance/Exiting.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import IncidentsPerDay from "components/Charts/Entrance/IncidentsPerDay.jsx";
import IncidentsPerHour from "components/Charts/Entrance/IncidentsPerHour.jsx";

import { SessionContext } from "layouts/Dashboard.jsx";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

class Dashboard extends React.Component {
  render() {
    return (
      <div style={{ marginTop: "1%", marginBottom: "6%" }}>
        <GridContainer>
          <IncidentsPerDay />
          <IncidentsPerHour />
        </GridContainer>

        <GridContainer>
          <Entering />
          <Exiting />
        </GridContainer>

        <GridContainer>
          <EnteringPerWeekday />
          <EnteringPerHour />
        </GridContainer>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

function withSessionContext(Component) {
  return function WrapperComponent(props) {
    return (
      <SessionContext.Consumer>
        {(state) => <Component {...props} context={state} />}
      </SessionContext.Consumer>
    );
  };
}

export default withSessionContext(withStyles(dashboardStyle)(Dashboard));
