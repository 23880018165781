import React, { useState } from "react";

import { definitionForm } from "components/Config/DefinitionForm.jsx";
import { IntegrationsBlock } from "components/Config/Integrations.jsx";
import { DeleteConfirmOverlayButton } from "components/Config/DeleteConfirmOverlayButton.jsx";
import { evidenceVideoConfigSchema } from "variables/schemas/source_configs/evidence_video.jsx";
import { fileStreamConfigSchema } from "variables/schemas/source_configs/file_stream.jsx";
import { rtspStreamConfigSchema } from "variables/schemas/source_configs/rtsp_stream.jsx";
import { shmBufferConfigSchema } from "variables/schemas/source_configs/shared_memory.jsx";
import { usbCameraConfigSchema } from "variables/schemas/source_configs/usb_camera.jsx";
import { sourceDefinitionSchema } from "variables/schemas/source_definition.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import Edit from "@material-ui/icons/Edit";
import cardStyle from "assets/jss/material-dashboard-pro-react/components/cardStyle.jsx";
import classNames from "classnames";
import CardBody from "components/Card/CardBody.jsx";
import RecordButton from "components/Config/RecordButton.jsx";
import StateButton from "components/Config/StateButton.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import Overlay from "react-overlay-component";

function SourceCard({ ...props }) {
  const [isOpen, setOverlay] = useState(false);
  const {
    classes,
    className,
    plain,
    background,
    color,
    source,
    definitionsObj,
    updateFunction,
    deleteFunction,
    recordingJobs,
    startRecordingFunction,
    stopRecordingFunction,
    ...rest
  } = props;
  const closeOverlay = () => setOverlay(false);

  function updateSource(sourceID, sourceDefinition) {
    updateFunction(sourceID, sourceDefinition);
    closeOverlay();
  }

  function recordingJob() {
    return recordingJobs[source.name];
  }

  if (source) {
    const cardClasses = classNames({
      [classes.card]: true,
      [classes.cardPlain]: plain,
      [classes.cardBackground]: background,
      [classes[color]]: color,
      [className]: className !== undefined
    });
    let backgroundColor = "#fafafa";
    let fontColor = "#AAAAAA";
    if (source.enabled !== false) {
      backgroundColor = "#FFFFFF";
      fontColor = "#333333";
    }

    const sourceID = source.name;

    const sourceParamsSchemaByType = {
      evidence: evidenceVideoConfigSchema,
      file: fileStreamConfigSchema,
      rtsp: rtspStreamConfigSchema,
      shared_memory: shmBufferConfigSchema,
      usb: usbCameraConfigSchema,
    };
    if (!source.type) {
      source.type = "rtsp";
    }

    let schema = sourceDefinitionSchema;
    schema.properties.source_params = sourceParamsSchemaByType[source.type];
    const configs = {
      animate: true,
      clickDismiss: true,
      escapeDismiss: true,
      focusOutline: true,
      contentClass: "overlay-container"
    };
    // TODO: activate this code to fetch and display preview image in source overview
    // var image = null;
    // Api.get(`/api/instances/${instance_id}/${definitions_file_id}/sources/${source.name}/preview`).then((res) => {
    //     res.blob().then((res) => {
    //       image = URL.createObjectURL(res)
    //     });
    //   }).catch((e) => {return});
    // function showImage(previewImage) {
    //   if (previewImage) {
    //     return (
    //     <img
    //       style={{width: "150px"}}
    //       ref={`${source.name}Preview`}
    //       src={previewImage}
    //       alt={`${source.name}Preview`} />)
    //   }
    // }

    return (
      <GridItem xs={12} sm={12} md={6}>
        <div className={cardClasses} {...rest} style={{ background: backgroundColor}}>
          <GridItem
            xs={12}
            style={{
              width: "100%",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <StateButton
              key={source.name + "State"}
              status={source.enabled !== false}
            />
            <h5 className={classes.cardCategory} style={{fontWeight: 600, color: fontColor,  display:"flex", overflow: "hidden", boxShadow: "inset 6px 6px 10px 0 rgba(50,50, 50, 0.05), inset -6px -6px 10px 0 rgba(255, 255, 255, 0.48)", background: "linear-gradient(-45deg, rgba(80, 80, 80, 0.03), rgba(255,255, 255, 0.4))", borderRadius: "5px", width: "82%", marginLeft: "auto", marginRight: "5%", marginTop: "-3%", marginBottom: "2%", paddingLeft: "4%", paddingTop:"2%", paddingBottom: "2%"}}>
              {source.name}
            </h5>
          </GridItem>
          <CardBody style={{
            width: "92%",
            marginLeft: "4%",
            marginRight: "10%",
            marginBottom: "2%",
          }}>
            <span style={{fontFamily: "\"Fira Mono\", \"Helvetica\"", color: fontColor, fontSize: "12px"}}>
              <p> {source.type} </p>
              <p> <b> source: </b>{source.location} </p>
              <br />
            </span>
            <DeleteConfirmOverlayButton
              item={source}
              deleteFunction={deleteFunction}
            />
            {/* {showImage(image)} */}
            <RecordButton
              source={source}
              recordingJob={recordingJob()}
              startRecordingFunction={startRecordingFunction}
              stopRecordingFunction={stopRecordingFunction}
            />
            <Button
              style={{ float: "right", marginRight: "-2%", width: "23%", background: "#333333", boxShadow: "inset 5px 5px 8px 0 rgba(0, 0, 0, 0.25), inset -5px -5px 8px 0 rgba(255, 255, 255, 0.15)" }}
              onClick={() => setOverlay(true)}
            >
              <Edit style={{ float: "center", color: "#FFFFFF" }} />
            </Button>
            <IntegrationsBlock
              plain={plain}
              classes={classes}
              background={background}
              color={color}
              className={classes.cardHover}
              integrations={source.integrations}
              sourceID={source.name}
              addFunction={definitionsObj.addIntegration}
              updateFunction={definitionsObj.updateIntegrationDefinition}
              deleteFunction={definitionsObj.deleteIntegrationDefinition}
            />
          </CardBody>
        </div>
        <Overlay style={{backgroundColor: "rgba(50, 0, 50, 0.68"}} configs={configs} isOpen={isOpen} closeOverlay={closeOverlay}>
          <div style={{width: "100%", overflow: "visible"}}>
            <h4>{source.name}</h4>
            {definitionForm(schema, sourceID, source, updateSource)}
          </div>
        </Overlay>
      </GridItem>
    );
  } else {
    return null;
  }
}

SourceCard.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  plain: PropTypes.bool,
  background: PropTypes.bool,

  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ]),
};

export default withStyles(cardStyle)(SourceCard);
