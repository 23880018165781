const retoureCheckConfigSchema = {
  "title": "RetoureCheck Params",
  "description": "RetoureCheck Config Parameters",
  "type": "object",
  "properties": {
    "log_level": {
      "title": "Log Level",
      "default": "INFO",
      "type": "string"
    },
    "fps": {
      "title": "FPS",
      // "default": 30.0,
      "type": "number"
    },
    "alarm_on_zone_enter": {
      "title": "Alarm On Zone Enter",
      // "default": false,
      "type": "boolean"
    },
    "wait_for_next_cls": {
      "title": "Wait For Next Classification",
      // "default": 0.4,
      "type": "number"
    },
    "classification_limit": {
      "title": "Classification Limit",
      // "default": 4,
      "type": "integer"
    },
    "match_tracklet_trajectories": {
      "title": "Match Tracklet Trajectories",
      // "default": false,
      "type": "boolean"
    },
    // "evidence": {
    //   "title": "Evidence",
    //   // "default": "./evidence",
    //   "type": "string"
    // },
    "save_video": {
      "title": "Save Video",
      "type": "string"
    },
    "save_non_alarms": {
      "title": "Save Non Alarms",
      // "default": false,
      "type": "boolean"
    },

    "save_test_images": {
      "title": "Save Test Images",
      // "default": false,
      "type": "boolean"
    },
    "save_extra_seconds": {
      "title": "Save Extra Seconds",
      // "default": 0,
      "type": "integer"
    },
    "display": {
      "title": "Display",
      // "default": false,
      "type": "boolean"
    },
    "visualiser_rtsp_stream": {
      "title": "Visualiser Rtsp Stream",
      // "default": false,
      "type": "boolean"
    },
    "fullscreen": {
      "title": "Fullscreen",
      // "default": false,
      "type": "boolean"
    },
    "display_width": {
      "title": "Display Width",
      // "default": 1920,
      "type": "integer"
    },
    "display_height": {
      "title": "Display Height",
      // "default": 1080,
      "type": "integer"
    },
    "visualize_detections": {
      "title": "Visualize Detections",
      // "default": false,
      "type": "boolean"
    },
    "http_proxy": {
      "title": "Http Proxy",
      "type": "string"
    },
    "client_id": {
      "title": "Client ID",
      // "default": "client1",
      "type": "integer"
      // "type": ["integer", "string"]
    },
    "person_confidence_threshold": {
      "title": "Person Confidence Threshold",
      // "default": 0.5,
      "type": "number"
    },
  },
  "required": []
};

export {retoureCheckConfigSchema};
