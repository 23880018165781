import React from "react";

import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import { SessionContext } from "layouts/Dashboard.jsx";
import withI18n from "modules/withI18n";
import PropTypes from "prop-types";

class InstanceSelect extends React.Component {
  render() {
    const { classes, t } = this.props;
    const { available_instances, instance_id, toggleInstance } = this.props.context;

    if (!available_instances || available_instances.length === 0) {
      return <div />;
    }

    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel
          htmlFor="instance_select"
          className={classes.selectLabel}
        >
          {t("instance_select.header")}
        </InputLabel>
        <Select
          MenuProps={{className: classes.selectMenu}}
          classes={{select: classes.select}}
          value={instance_id}
          onChange={(e) => {toggleInstance(e.target.value);}}
          style={{
            // background: "rgba(255, 255, 255, 1)",
            padding: "2%",
            paddingLeft: "5%",
            paddingRight: "5%",
          }}
          input={<Input id="instance_select" value={instance_id} />}
        >
          {available_instances.map((instance, idx) => (
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              key={idx}
              value={instance}
            >
              {instance}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}
function withSessionContext(Component) {
  return function WrapperComponent(props) {
    return (
      <SessionContext.Consumer>
        {(state) => <Component {...props} context={state} />}
      </SessionContext.Consumer>
    );
  };
}

InstanceSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withSessionContext(withI18n(withStyles(customSelectStyle)(InstanceSelect)));
