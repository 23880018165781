import React from "react";

import cx from "classnames";
// import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import PropTypes from "prop-types";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "components/PrivateRoute.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Footer from "components/Footer/Footer.jsx";
import Header from "components/Header/Header.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import withI18n from "modules/withI18n";

import {
  dashboardRoutesCheckout,
  dashboardRoutesCloud,
  dashboardRoutesEntrance,
  dashboardRoutesPlatform,
} from "routes/dashboard.jsx";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx";

import Api from "modules/api.js";

export const SessionContext = React.createContext();

const switchRoutes = (product) => {
  if (product === "cloud") {
    return (
      <Switch>
        {dashboardRoutesCloud.map((prop, key) => {
          if (prop.redirect)
            return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
          if (prop.collapse)
            return prop.views.map((prop, key) => {
              return <Route path={prop.path} component={prop.component} key={key} />;
            });
          return <Route path={prop.path} component={prop.component} key={key} />;
        })}
      </Switch>
    );
  } else if (product === "checkout") {
    return (
      <Switch>
        {dashboardRoutesCheckout.map((prop, key) => {
          if (prop.redirect)
            return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
          if (prop.collapse)
            return prop.views.map((prop, key) => {
              return (
                <Route
                  path={prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            });
          return <Route path={prop.path} component={prop.component} key={key} />;
        })};
      </Switch>
    );
  } else if (product === "entrance") {
    return (
      <Switch>
        {dashboardRoutesEntrance.map((prop, key) => {
          if (prop.redirect)
            return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
          if (prop.collapse)
            return prop.views.map((prop, key) => {
              return (
                <Route
                  path={prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            });
          return (
            <Route path={prop.path} component={prop.component} key={key} />
          );
        })}
      </Switch>
    );
  } else if (product === "platform") {
    return (
      <Switch>
        {dashboardRoutesPlatform.map((prop, key) => {
          if (prop.redirect)
            return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
          if (prop.collapse)
            return prop.views.map((prop, key) => {
              return (
                <Route
                  path={prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            });
          return (
            <Route path={prop.path} component={prop.component} key={key} />
          );
        })}
      </Switch>
    );
  } else {
    return (
      <Switch>
        <PrivateRoute
          exact
          path="/logout"
          component={() => {
            Api.post("/api/auth/logout", {}).then(() => {Api.deauthenticateUser();
            });
            return <Redirect to="/" replace />;
          }}
          authenticated={Api.isUserAuthenticated()}
        />
      </Switch>
    );
  }
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      available_instances: undefined,
      instance_id: undefined,
      definitions_file_id: undefined,
      device: -1,
      timespan: 7,
      object_classes: ["cart", "person", "basket"],
      dashboardRoutes: undefined,
      product: null,
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps = new PerfectScrollbar(this.refs.mainPanel, {
    //     suppressScrollX: true,
    //     suppressScrollY: false,
    //   });
    //   document.body.style.overflow = "hidden";
    // }
    window.addEventListener("resize", this.resizeFunction);
    this._isMounted && this.loadData();
  }

  loadData() {
    Api.get("/api/status/product").then((res) => {
      this.setState({product: res.product, hostname: res.hostname});
      if (res.product === "cloud") {
        this.setState({dashboardRoutes: dashboardRoutesCloud});
      } else {
        let dashboardRoutes = undefined;
        if (res.product === "platform") {
          dashboardRoutes = dashboardRoutesPlatform;
        } else if (res.product === "checkout") {
          dashboardRoutes = dashboardRoutesCheckout;
        } else if (res.product === "entrance") {
          dashboardRoutes = dashboardRoutesEntrance;
        } else {
          throw new Error("Unknown product: " + res.product);
        }
        this.setState({dashboardRoutes: dashboardRoutes}, this.loadInstances);
      }
    });
    Api.get("/api/devices/").then((res) => {
      if (res.data.length > 0) {
        this.setState({
          devices: res.data,
          device: res.data[0].id,
        });
      }
    }).catch((e) => {
      // console.error(e);
      this.setState({devices: [], device: -1});
    });
  }
  loadInstances() {
    Api.get("/api/instances/").then((res) => {
      if (res.data && res.data.length > 0) {
        this.setState({available_instances: res.data, instance_id: res.data[0]});
      } else {
        // this.setState({available_instances})
        console.error("No cartwatch instances were found!");
        // throw new Error("No cartwatch instances were found!");
      }
    }).catch((e) => {
      console.error(e);
      // this.setState({available_instances: [], instance_id: undefined});
    });
  }
  componentWillUnmount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps.destroy();
    // }
    window.removeEventListener("resize", this.resizeFunction);
    this._isMounted = false;
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  toggleInstance(instance_id) {
    this.setState({ instance_id: instance_id, definitions_file_id: undefined });
  }
  toggleDefinitionsFile(definitions_file_id) {
    this.setState({ definitions_file_id: definitions_file_id });
  }
  toggleDevice(device_id) {
    this.setState({ device: device_id });
  }
  toggleTimespan(timespan) {
    this.setState({ timespan: timespan });
  }
  toggleObjectClasses(object_classes) {
    this.setState({ object_classes: object_classes });
  }
  handleDrawerToggle () {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  render() {
    if (!this.state.dashboardRoutes) {
      return null;
    }
    const { classes, ...rest } = this.props;
    const {
      hostname,
      available_instances,
      instance_id,
      definitions_file_id,
      device,
      timespan,
      object_classes,
    } = this.state;

    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        // [classes.mainPanelWithPerfectScrollbar]:
        //   navigator.platform.indexOf("Win") > -1,
      });

    return (
      <div className={classes.wrapper}>
        <SessionContext.Provider
          value={{
            hostname,
            available_instances,
            instance_id,
            definitions_file_id,
            device,
            timespan,
            object_classes,
            toggleInstance: this.toggleInstance.bind(this),
            toggleDefinitionsFile: this.toggleDefinitionsFile.bind(this),
            toggleDevice: this.toggleDevice.bind(this),
            toggleTimespan: this.toggleTimespan.bind(this),
            toggleObjectClasses: this.toggleObjectClasses.bind(this)
          }}
        >
          <Sidebar
            routes={this.state.dashboardRoutes}
            logoText={"Signatrix"}
            handleDrawerToggle={this.handleDrawerToggle}
            open={this.state.mobileOpen}
            color="rose"
            bgColor="white"
            miniActive={this.state.miniActive}
            hostname={hostname}
            {...rest}
          />
          <div className={mainPanel} ref="mainPanel">
            <Header
              sidebarMinimize={this.sidebarMinimize.bind(this)}
              miniActive={this.state.miniActive}
              routes={this.state.dashboardRoutes}
              handleDrawerToggle={this.handleDrawerToggle}
              {...rest}
            />
            <div className={classes.content}>
              <div className={classes.container}>
                {switchRoutes(this.state.product)}
              </div>
            </div>
            <Footer fluid />
          </div>
        </SessionContext.Provider>
        <NotificationContainer />
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.elementType
  ]),
};

export default withI18n(withStyles(appStyle)(Dashboard));
// export const mainPanel = Dashboard.refs.mainPanel;
