import React from "react";
import Button from "components/CustomButtons/Button.jsx";

import TripOrigin from "@material-ui/icons/TripOrigin";
import Pause from "@material-ui/icons/Pause";

import { successColor } from "assets/jss/material-dashboard-pro-react.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import cardStyle from "assets/jss/material-dashboard-pro-react/components/cardStyle.jsx";


function StateButton({ ...props }) {
  const {
    status,
    updateFunction,
  } = props;
  if (status !== undefined) {
    function backgroundColor() {
      if (status !== false) {
        return successColor;
      } else {
        return "#fafafa";
      }
    }
    function statusColor() {
      if (status  !== false) {
        return successColor;
      } else {
        return "#fafafa";
      }
    }

    function statusIcon() {
      if (status !== false) {
        return  <TripOrigin style={{ float: "center", color: "#FFFFFF" }} />;
      } else {
        return <Pause style={{ float: "center", color: "#CCCCCC" }} />;
      }
    }
    function toggleState() {
      if (updateFunction !== undefined) {
        updateFunction();
      }
    }

    return (
      <Button
        style={{
          background: backgroundColor(),
          color: statusColor(),
          paddingTop: "2%",
          paddingBottom: "2%",
          paddingLeft: "2%",
          paddingRight: "1%",
          // width: "fit-content",
          borderRadius: "calc(.25rem - 1px) calc(.25rem - 1px) calc(.25rem - 1px) calc(.25rem - 1px)",
          marginTop: "2%",
          marginBottom: "0%",
          marginLeft: "0%",
          marginRight: "0%",
          // overflow: "visible",
          boxShadow: "inset 5px 5px 8px 0 rgba(50, 50, 50, 0.05), inset -5px -5px 8px 0 rgba(255, 255, 255, 0.2)",
        }}
        onClick={() => toggleState()}
      >
        {statusIcon()}
      </Button>
    );
  } else {
    return null;
  }
}

StateButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  plain: PropTypes.bool,
  background: PropTypes.bool,

  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ]),
};

export default withStyles(cardStyle)(StateButton);
