import React from "react";

import Checkbox from "@material-ui/core/Checkbox";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Person from "@material-ui/icons/Person";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";

import { SessionContext } from "layouts/Dashboard.jsx";

import withI18n from "modules/withI18n";

class ObjectClassSelect extends React.Component {
  handleToggle(key, object_classes, updateFunction) {
    if (object_classes.includes(key)) {
      updateFunction(object_classes.filter((o) => o !== key));
    } else {
      updateFunction(object_classes.concat([key]));
    }
  }

  render() {
    return (
      <SessionContext.Consumer>
        {({ object_classes, toggleObjectClasses }) => (
          <div>
            <Checkbox
              onClick={() =>
                this.handleToggle("cart", object_classes, toggleObjectClasses)
              }
              checkedIcon={<ShoppingCart style={{ color: "#111111" }} />}
              icon={<ShoppingCart />}
              checked={object_classes.includes("cart")}
            />
            <Checkbox
              onClick={() =>
                this.handleToggle("person", object_classes, toggleObjectClasses)
              }
              checkedIcon={<Person style={{ color: "#111111" }} />}
              icon={<Person />}
              checked={object_classes.includes("person")}
            />
            <Checkbox
              onClick={() =>
                this.handleToggle("basket", object_classes, toggleObjectClasses)
              }
              checkedIcon={<ShoppingBasket style={{ color: "#111111" }} />}
              icon={<ShoppingBasket />}
              checked={object_classes.includes("basket")}
            />
          </div>
        )}
      </SessionContext.Consumer>
    );
  }
}

export default withI18n(ObjectClassSelect);
