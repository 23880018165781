import React, { useState } from "react";
import { AutoForm } from "uniforms-material";
import { JSONSchemaBridge } from "uniforms-bridge-json-schema";
import { NotificationManager } from "react-notifications";
import { createValidator } from "variables/schemas.jsx";

import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/Add";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import classNames from "classnames";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import Overlay from "react-overlay-component";

import { DeleteConfirmOverlayButton } from "components/Config/DeleteConfirmOverlayButton.jsx";
import { integrationDefinitionSchema } from "variables/schemas/integration_definition.jsx"
import { drsIntegrationSchema } from "variables/schemas/integration_configs/drs.jsx";
import { gkIntegrationSchema } from "variables/schemas/integration_configs/gk.jsx";
import { itabIntegrationSchema } from "variables/schemas/integration_configs/itab.jsx";
import {itabv2IntegrationSchema} from "variables/schemas/integration_configs/itab_v2";
import { reweIntegrationSchema } from "variables/schemas/integration_configs/rewe.jsx";

const integrationsSchemaByType = {
    drs: drsIntegrationSchema,
    gk: gkIntegrationSchema,
    itab: itabIntegrationSchema,
    itab_v2: itabv2IntegrationSchema,
    rewe: reweIntegrationSchema,
};

function IntegrationForm(props) {
    if (props.integrationDefinition) {
        if (!props.schema){
            return(<></>);
//            throw new Error("No Schema provided");
        }
        try {
            const validator = createValidator(props.schema, NotificationManager);
            const bridge = new JSONSchemaBridge(props.schema, validator);
            return (
                    <AutoForm
                        style={{float: "center", margin: "2%", overflow: "visible"}}
                        schema={bridge}
                        disabled={false}
                        model={props.integrationDefinition}
                        showInlineError
                        onSubmit={(model) => props.updateFunction({sourceID: props.sourceID,
                            integrationDefinition: props.integrationDefinition,
                            updatedIntegrationDefinition: model})}
                    />
                );
        } catch (error) {
            console.error(error);
            return (<>
            <span>Could not load Form</span>
            <br />
            <pre>{props.integrationDefinition ? JSON.stringify(props.integrationDefinition, 2) : ""}</pre>
            <br />
            <pre>{error ? JSON.stringify(error, 2) : ""}</pre>
            </>);
        }
    } else {
        return <></>;
    }
}

class AddIntegration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: ''
        };
        this.sourceID = props.sourceID;
        this.addFunction = props.addFunction;
        this.schema = null;
    }
    handleChange = (e) => {
        if(e.target.value){
            this.schema = {...integrationDefinitionSchema};
            this.schema.properties = {...this.schema.properties, ...integrationsSchemaByType[e.target.value].properties};
        }
        this.setState({selected:e.target.value})
    }
    render() {
        return (
            <div>
                <h5>Adding new integration to source <b>{this.sourceID}</b></h5>
                <Select value={this.state.selected} onChange={(e) => {this.handleChange(e)}} style={{width: "100%"}}>
                    {Object.keys(integrationsSchemaByType).map((key, value) => (<MenuItem key={key} value={key}>{key}</MenuItem>))}
                </Select>
                <IntegrationForm
                    schema={this.schema}
                    sourceID={this.sourceID}
                    integrationDefinition={{type: this.state.selected, source_id: this.sourceID}}
                    updateFunction={this.addFunction}
                />
            </div>
            );
    }
}

function IntegrationsBlock({ ...props }) {
    const {
        plain,
        classes,
        background,
        color,
        className,
        integrations,
        sourceID,
        addFunction,
        updateFunction,
        deleteFunction
    } = props;

    let [isOpen, setOverlay] = useState(false);
    let closeOverlay = () => {setOverlay(false);}

    function addAndCloseOverlayFunction(props) {
        addFunction(props);
        closeOverlay();
    }

    return (<div style={{clear: "both", paddingTop: "2em"}}>Integrations:
        {integrations?.map((integration) => (
            <IntegrationCard
                key={integration.name}
                plain={plain}
                classes={classes}
                background={background}
                color={color}
                className={className}
                integration={integration}
                sourceID={sourceID}
                updateFunction={updateFunction}
                deleteFunction={deleteFunction}
            />))}
        <Button
            style={{ float: "right", marginRight: "2%", marginTop: "2%", marginBottom: "1%", width: "10%", background: "#457DE3" }}
            onClick={() => {setOverlay(true); }}>
            <Add style={{ float: "center", color: "#FFFFFF" }} />
        </Button>
        <Overlay style={{backgroundColor: "rgba(50, 0, 50, 0.68"}} isOpen={isOpen} closeOverlay={closeOverlay}>
            { isOpen &&
            <AddIntegration sourceID={sourceID} addFunction={addAndCloseOverlayFunction} />}
        </Overlay>
    </div>);
}

function IntegrationCard({ ...props }){
    const {
        plain,
        classes,
        background,
        color,
        className,
        integration,
        sourceID,
        addFunction,
        updateFunction,
        deleteFunction,
        ...rest
    } = props;

    const configs = {
        animate: true,
        clickDismiss: true,
        escapeDismiss: true,
        focusOutline: true,
        contentClass: "overlay-container"
    };
    let [isOpen, setOverlay] = useState(false);
    let closeOverlay = () => setOverlay(false);

    let schema = {...integrationDefinitionSchema};
    schema.properties = {...schema.properties, ...integrationsSchemaByType[integration.type].properties};

    // disable type field
    schema.properties.type.disabled = true;

    const cardClasses = classNames({
        [classes.card]: true,
        [classes.cardPlain]: plain,
        [classes.cardBackground]: background,
        [classes[color]]: color,
        [className]: className !== undefined
    });
    let backgroundColor = "#fafafa";

    function updateAndCloseOverlayFunction(props) {
        updateFunction(props);
        closeOverlay();
    }

    return (
        <GridItem xs={12} sm={12} md={12}>
            <div className={cardClasses} {...rest} style={{ background: backgroundColor, width: "100%"}}>
                <h5 className={classes.cardCategory} style={{fontWeight: 600, color: "#000",  display:"flex", overflow: "hidden"}}>
                        {integration.name} (type: {integration.type})
                    </h5>
                <CardBody style={{
                    width: "92%",
                    marginLeft: "4%",
                    marginRight: "10%",
                    marginBottom: "2%",
                }}>
                    <DeleteConfirmOverlayButton
                        item={integration}
                        deleteFunction={() => deleteFunction({sourceID: sourceID, integrationDefinition: integration})}
                    />
                    <Button
                        style={{ float: "right", marginRight: "-2%", width: "23%", background: "#333333", boxShadow: "inset 5px 5px 8px 0 rgba(0, 0, 0, 0.25), inset -5px -5px 8px 0 rgba(255, 255, 255, 0.15)" }}
                        onClick={() => setOverlay(true)}
                        >
                        <Edit style={{ float: "center", color: "#FFFFFF" }} />
                    </Button>
                </CardBody>
                <Overlay style={{backgroundColor: "rgba(50, 0, 50, 0.68"}} configs={configs} isOpen={isOpen} closeOverlay={closeOverlay}>
                    { isOpen && <>
                        <h4>Editing <b>{integration.name}</b> integration in source <b>{sourceID}</b></h4>
                        <IntegrationForm
                            schema={schema}
                            sourceID={sourceID}
                            integrationDefinition={integration}
                            updateFunction={updateAndCloseOverlayFunction}
                            />
                    </>}
                </Overlay>
            </div>
        </GridItem>
        )
}

IntegrationCard.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    plain: PropTypes.bool,
    background: PropTypes.bool,

    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "rose"
    ]),
};

export {IntegrationsBlock};
