const fullnessEntranceConfigSchema = {
  "title": "FullnessEntrance Params",
  "description": "fullness entrance config",
  "type": "object",
  "properties": {
    "log_level": {
      "title": "Log Level",
      "default": "INFO",
      "type": "string"
    },
    "fps": {
      "title": "FPS",
      // "default": 30.0,
      "type": "number"
    },
    "alarm_on_zone_enter": {
      "title": "Alarm On Zone Enter",
      // "default": false,
      "type": "boolean"
    },
    "fullness_trigger_threshold": {
      "title": "Fullness Trigger Threshold",
      // "default": 70.0,
      "type": "number"
    },
    "wait_for_next_cls": {
      "title": "Wait For Next Classification",
      // "default": 0.4,
      "type": "number"
    },
    "classification_limit": {
      "title": "Classification Limit",
      // "default": 4,
      "type": "integer"
    },
    "match_tracklet_trajectories": {
      "title": "Match Tracklet Trajectories",
      // "default": false,
      "type": "boolean"
    },
    // "evidence": {
    //   "title": "Evidence Path",
    //   // "default": "./evidence",
    //   "type": "string"
    // },
    "save_non_alarms": {
      "title": "Save Non Alarms",
      // "default": false,
      "type": "boolean"
    },
    "save_test_images": {
      "title": "Save Test Images",
      // "default": false,
      "type": "boolean"
    },
    "save_video": {
      "title": "Save Video",
      "type": "string"
    },
    "save_extra_seconds": {
      "title": "Save Extra Seconds",
      // "default": 0,
      "type": "integer"
    },
    "display": {
      "title": "Display",
      // "default": false,
      "type": "boolean"
    },
    "visualiser_rtsp_stream": {
      "title": "Visualiser Rtsp Stream",
      // "default": false,
      "type": "boolean"
    },
    "fullscreen": {
      "title": "Fullscreen",
      // "default": false,
      "type": "boolean"
    },
    "display_width": {
      "title": "Display Width",
      // "default": 1920,
      "type": "integer"
    },
    "display_height": {
      "title": "Display Height",
      // "default": 1080,
      "type": "integer"
    },
    "visualize_detections": {
      "title": "Visualize Detections",
      // "default": false,
      "type": "boolean"
    },
    "http_proxy": {
      "title": "Http Proxy",
      "type": "string"
    },
    "evaluate_tracker": {
      "title": "Evaluate Tracker",
      "type": "string"
    },
    "redemption_secs": {
      "title": "Redemption Secs",
      // "default": 120,
      "type": "integer"
    },
    "full_cls_threshold": {
      "title": "Full Cls Threshold",
      // "default": 0.6,
      "type": "number"
    },
    "extra_cls_threshold": {
      "title": "Extra Cls Threshold",
      // "default": 0.5,
      "type": "number"
    },
    "min_hits": {
      "title": "Min Hits",
      // "default": 3,
      "type": "integer"
    },
    "max_age": {
      "title": "Max Age",
      // "default": 1.0,
      "type": "number"
    },
    "cls_avg_on_exit": {
      "title": "Cls Avg On Exit",
      // "default": false,
      "type": "boolean"
    },
    "alarm_triggering_classes": {
      "title": "Alarm Triggering Classes",
      // "default": [
      //   "basket",
      //   "cart"
      // ],
      "type": "array",
      "items": {
        "type": "string"
      },
    },
  },
  "required": []
};

export {fullnessEntranceConfigSchema};
