import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";

import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { SessionContext } from "layouts/Dashboard.jsx";
import PropTypes from "prop-types";

import withI18n from "modules/withI18n";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";

const default_selection = 7;

class TimespanSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timespan: default_selection,
    };
  }

  createTimespanList(classes) {
    const { t } = this.props;

    return [
      <MenuItem
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected,
        }}
        key={1}
        value={30}
      >
        {t("timespan.last_30_days")}
      </MenuItem>,
      <MenuItem
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected,
        }}
        key={2}
        value={7}
      >
        {t("timespan.last_7_days")}
      </MenuItem>,
      <MenuItem
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected,
        }}
        key={3}
        value={2}
      >
        {t("timespan.yesterday")}
      </MenuItem>,
      <MenuItem
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected,
        }}
        key={4}
        value={1}
      >
        {t("timespan.today")}
      </MenuItem>,
    ];
  }

  render() {
    const { classes, t } = this.props;
    return (
      <SessionContext.Consumer>
        {({ timespan, toggleTimespan }) => (
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              htmlFor="timespan-select"
              className={classes.selectLabel}
            >
              {t("timespan.timespan")}
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={timespan}
              onChange={(e) => {
                toggleTimespan(e.target.value);
              }}
              style={{
                // background: "rgba(255, 255, 255, 1)",
                padding: "2%",
                paddingLeft: "5%",
                paddingRight: "5%",
                // marginTop: "10%",
                // boxShadow: "0px 10px 25px 10px rgba(255, 255, 255, 0.4)",
              }}
              input={<Input id="timespan-select" value={default_selection} />}
            >
              {this.createTimespanList(classes)}
            </Select>
            {/*<div>Global State: {device}</div>*/}
          </FormControl>
        )}
      </SessionContext.Consumer>
    );
  }
}
TimespanSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  context: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withI18n(withStyles(customSelectStyle)(TimespanSelect));
