import "./i18n";
import "assets/css/custom-fonts.css";
import "assets/scss/material-dashboard-pro-react.css";
import "core-js/es/map";
import "core-js/es/set";
import "react-app-polyfill/ie9";
import "typeface-montserrat";
import "typeface-roboto";
import "@fontsource/fira-mono"; // Defaults to weight 400.
import App from "./app";
import React from "react";
import ReactDOM from "react-dom";

ReactDOM.render(<App />, document.getElementById("root"));
