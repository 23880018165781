const bakeryConfigSchema = {
  "title": "Bakery App Parameters",
  "description": "app_params for bakery app_type",
  "type": "object",
  "properties": {
    "log_level": {
      "title": "Log Level",
      "default": "INFO",
      "type": "string"
    },
    "fps": {
      "title": "Fps",
      //"default": 30.0,
      "type": "number"
    },
    "empty_cls_threshold": {
      "title": "Empty cls Threshold",
      //"default": 70.0,
      "type": "number"
    },
    "fullness_support": {
      "title": "Fullness Support",
      "type": "boolean",
      //"default": true,
    },
    "fullness_trigger_threshold": {
      "title": "Fullness Trigger Threshold",
      //"default": 70.0,
      "type": "number"
    },
    "alarm_on_zone_enter": {
      "title": "Alarm On Zone Enter",
      //"default": false,
      "type": "boolean"
    },
    "wait_for_next_cls": {
      "title": "Wait For Next Classification",
      //"default": 5,
      "type": "number"
    },
    "wait_for_next_alarm": {
      "title": "Wait For Next Alarm",
      //"default": 15,
      "type": "number"
    },
    "min_number_of_empty_cells": {
      "title": "Min Number of Empty Cells",
      //"default": 2,
      "type": "integer"
    },
    "mvmt_detection_threshold": {
      "title": "Movement Detection Threshold",
      //"default": 0.01,
      "type": "number"
    },
    "cell_groups": {
      "title": "Cell Groups",
      //"default": [],
      "type": "array",
      "items": {
        "type": "array",
        //"default": [],
        "items": {
          "type": "integer",
        }
      }
    },
    "classification_limit": {
      "title": "Classification Limit",
      //"default": 4,
      "type": "integer"
    },
    "max_age": {
      "title": "Max Age",
      //"default": 1.0,
      "type": "number"
    },
    "min_hits": {
      "title": "Min Hits",
      //"default": 3,
      "type": "integer"
    },
    "match_tracklet_trajectories": {
      "title": "Match Tracklet Trajectories",
      //"default": false,
      "type": "boolean"
    },
    // "evidence": {
    //   "title": "Evidence",
    //   //"default": "./evidence",
    //   "type": "string"
    // },
    "save_video": {
      "title": "Save Video",
      "type": "string"
    },
    "save_non_alarms": {
      "title": "Save Non Alarms",
      //"default": false,
      "type": "boolean"
    },
    "save_test_images": {
      "title": "Save Test Images",
      //"default": false,
      "type": "boolean"
    },
    "write_cvat": {
      "title": "Write Cvat",
      //"default": false,
      "type": "boolean"
    },
    "write_all_tracklets_in_cvat": {
      "title": "Write all tracklets Cvat",
      // "default": false,
      "type": "boolean"
    },
    "save_extra_seconds": {
      "title": "Save Extra Seconds",
      //"default": 0,
      "type": "integer"
    },
    "display": {
      "title": "Display",
      //"default": false,
      "type": "boolean"
    },
    "visualiser_rtsp_stream": {
      "title": "Visualiser Rtsp Stream",
      //"default": false,
      "type": "boolean"
    },
    "fullscreen": {
      "title": "Fullscreen",
      //"default": false,
      "type": "boolean"
    },
    "display_width": {
      "title": "Display Width",
      //"default": 1920,
      "type": "integer"
    },
    "display_height": {
      "title": "Display Height",
      //"default": 1080,
      "type": "integer"
    },
    "visualize_detections": {
      "title": "Visualize Detections",
      //"default": false,
      "type": "boolean"
    },
    "http_proxy": {
      "title": "Http Proxy",
      "type": "string"
    },
    "full_cls_threshold": {
      "title": "Full Cls Threshold",
      //"default": 0.6,
      "type": "number"
    },
    // "required_object_classes": {
    //   "title": "Required Object Classes",
    //   "type": "array",
    //   "items": {
    //     "type": "string"
    //   },
    // },
  },
  "required": []
};

export {bakeryConfigSchema};
