const timeConstrainedAlarmsSchema = {
  title: "Time Constrained Alarms",
  type: "object",
  properties: {
    constrained_by_alarm_period: {
      type: "array",
      title: "Time Constrained Alarms",
      items: {
        type: "string",
        allowedValues: [
          "axis",
          "email",
          "gpio",
          "http",
          "indyme",
          "milestone",
          "mqtt",
          "phone",
          "relay",
          "telegram",
          "threema",
        ],
      },
      default: [],
    },
  },
  required: ["constrained_by_alarm_period"],
};

const alarmProfileSchema = {
  title: "Alarm Profile",
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "Name",
      description: "Name of the Alarm Profile",
    },
    address: {
      type: "string",
      title: "Address",
      description: "Address of Deployment",
    },
    data_plan: {
      type: "string",
      title: "Data Plan",
      description: "Mobile Connection Data Plan",
    },
    language: {
      type: "string",
      title: "Language",
      description: "Language setting to be used for an Alert",
      allowedValues: ["de", "en"],
      default: "de",
    },
    constrained_by_alarm_period: {
      type: "array",
      title: "Alert Destinations",
      items: {
        type: "string",
        allowedValues: [],
      },
      default: [],
    },
    alarms: {
      type: "array",
      title: "Alert Destinations",
      anyOf: [
        {
          type: "object",
          title: "Telegram Alert",
          properties: {
            name: {
              type: "string",
              title: "Name",
              description: "Name of Recipient",
            },
            chat_id: {
              type: "string",
              title: "Chat ID",
              description: "Unique Telegram Chat ID",
            },
            message: {
              type: "string",
              title: "Message to send",
              description: "Message to send (optional)",
            },
            type: {
              type: "string",
              title: "Type",
              description: "Type of the Alarm",
              default: "telegram",
            },
          },
          required: ["chat_id", "type"],
        },
        {
          type: "object",
          title: "Indyme QLink Alert",
          properties: {
            indyme_qlink_host: {
              type: "string",
              title: "Indyme QLink Host",
              description: "Indyme QLink Host",
            },
            type: {
              type: "string",
              title: "Type",
              description: "Type of the Alarm",
              default: "indyme",
            },
          },
          required: ["indyme_qlink_host", "type"],
        },
        {
          type: "object",
          title: "Threema Alert",
          properties: {
            name: {
              type: "string",
              title: "Name",
              description: "Name of Recipient",
            },
            threema_id: {
              type: "string",
              title: "Threema ID",
              description: "Unique Threema Chat ID",
            },
            message: {
              type: "string",
              title: "Message to send",
              description: "Message to send (optional)",
            },
            type: {
              type: "string",
              title: "Type",
              description: "Type of the Alarm",
              default: "threema",
            },
          },
          required: ["threema_id", "type"],
        },
        {
          type: "object",
          title: "Email Recipient",
          properties: {
            name: {
              type: "string",
              title: "Name",
              description: "Name of Recipient",
            },
            email_address: {
              type: "string",
              title: "Email Address",
              description: "Unique Email Address",
            },
            type: {
              type: "string",
              title: "Type",
              description: "Type of the Alarm",
              default: "email",
            },
          },
          required: ["name", "email_address"],
        },
        {
          type: "object",
          title: "HTTP Endpoint",
          properties: {
            name: {
              type: "string",
              title: "Name",
              description: "Name of Recipient",
            },
            address: {
              type: "string",
              title: "Address",
              description: "HTTP Address",
            },
            type: {
              type: "string",
              title: "Type",
              description: "Type of the Alarm",
              default: "telegram",
            },
          },
          required: ["name", "type"],
        },
        {
          type: "object",
          title: "MQTT Message Recipient",
          properties: {
            broker: {
              type: "string",
              title: "Broker",
              description: "Address of MQTT Broker",
            },
            port: {
              type: "integer",
              title: "MQTT Broker Port",
              description: "Maximum age of evidence to consider uploading",
              default: 1883,
            },
            topic: {
              type: "string",
              title: "Topic",
              description: "MQTT Topic to publish ",
            },
            message: {
              type: "string",
              title: "Message",
              description: "Custom MQTT Message to publish to Topic",
            },
            qos_level: {
              type: "integer",
              title: "MQTT Quality of Service Level",
              description: "MQTT Quality of Service Level",
              default: 0,
            },
            type: {
              type: "string",
              title: "Type",
              description: "Type of the Alarm",
              default: "mqtt",
            },
          },
          required: ["broker", "port", "topic", "qos_level", "type"],
        },
      ],
    },
  },
  required: [],
};
const alarmTimesSchema = {
  title: "Alarm Activity",
  type: "object",
  properties: {
    monday: {
      type: "object",
      title: "Monday",
      description: "Alarms on Monday",
      properties: {
        active: {
          title: "Active",
          default: true,
          type: "boolean",
        },
        alarm_start: {
          type: "string",
          title: "Alarm Start",
          description: "When the alarms start on that day",
          default: "09:00",
        },
        alarm_end: {
          type: "string",
          title: "Alarm Stop",
          description: "When the alarms end on that day",
          default: "18:00",
        },
      },
    },
    tuesday: {
      type: "object",
      title: "Tuesday",
      description: "Alarms on Tuesday",
      properties: {
        active: {
          title: "Active",
          default: true,
          type: "boolean",
        },
        alarm_start: {
          type: "string",
          title: "Alarm Start",
          description: "When the alarms start on that day",
          default: "09:00",
        },
        alarm_end: {
          type: "string",
          title: "Alarm Stop",
          description: "When the alarms end on that day",
          default: "18:00",
        },
      },
    },
    wednesday: {
      type: "object",
      title: "Wednesday",
      description: "Alarms on Wednesday",
      properties: {
        active: {
          title: "Active",
          default: true,
          type: "boolean",
        },
        alarm_start: {
          type: "string",
          title: "Alarm Start",
          description: "When the alarms start on that day",
          default: "09:00",
        },
        alarm_end: {
          type: "string",
          title: "Alarm Stop",
          description: "When the alarms end on that day",
          default: "18:00",
        },
      },
    },
    thursday: {
      type: "object",
      title: "Thursday",
      description: "Alarms on Thursday",
      properties: {
        active: {
          title: "Active",
          default: true,
          type: "boolean",
        },
        alarm_start: {
          type: "string",
          title: "Alarm Start",
          description: "When the alarms start on that day",
          default: "09:00",
        },
        alarm_end: {
          type: "string",
          title: "Alarm Stop",
          description: "When the alarms end on that day",
          default: "18:00",
        },
      },
    },
    friday: {
      type: "object",
      title: "Friday",
      description: "Alarms on Friday",
      properties: {
        active: {
          title: "Active",
          default: true,
          type: "boolean",
        },
        alarm_start: {
          type: "string",
          title: "Alarm Start",
          description: "When the alarms start on that day",
          default: "09:00",
        },
        alarm_end: {
          type: "string",
          title: "Alarm Stop",
          description: "When the alarms end on that day",
          default: "18:00",
        },
      },
    },
    saturday: {
      type: "object",
      title: "Saturday",
      description: "Alarms on Saturday",
      properties: {
        active: {
          title: "Active",
          default: true,
          type: "boolean",
        },
        alarm_start: {
          type: "string",
          title: "Alarm Start",
          description: "When the alarms start on that day",
          default: "09:00",
        },
        alarm_end: {
          type: "string",
          title: "Alarm Stop",
          description: "When the alarms end on that day",
          default: "18:00",
        },
      },
    },
    sunday: {
      type: "object",
      title: "Sunday",
      description: "Alarms on Sunday",
      properties: {
        active: {
          title: "Active",
          default: false,
          type: "boolean",
        },
        alarm_start: {
          type: "string",
          title: "Alarm Start",
          description: "When the alarms start on that day",
          default: "09:00",
        },
        alarm_end: {
          type: "string",
          title: "Alarm Stop",
          description: "When the alarms end on that day",
          default: "18:00",
        },
      },
    },
  },
  required: [],
};

const alarmProfileGeneralInfoSchema = {
  title: "General Info",
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "Device Name",
      description: "Name of the Device",
      default: "",
    },
    address: {
      type: "string",
      title: "Address",
      description: "Physical Address of the device",
      default: "",
    },
    language: {
      type: "string",
      title: "Language",
      description: "Language setting For Notifications",
      allowedValues: ["de", "en"],
      default: "de",
    },
    data_plan: {
      type: "integer",
      title: "Data Plan (GB/month)",
      description: "What is the data plan for this device (0 means infinite)",
      default: 0,
    },
  },
  required: ["language"],
};

export {
  alarmProfileSchema,
  alarmProfileGeneralInfoSchema,
  alarmTimesSchema,
  timeConstrainedAlarmsSchema,
};
