
const scoConfigSchema = {
  "title": "SelfCheckout Params",
  "description": "app_params for sco app_type",
  "type": "object",
  "properties": {
    "log_level": {
      "title": "Log Level",
      "default": "INFO",
      "type": "string"
    },
    "debug_mode": {
      "title": "Debug Mode",
      // "default": true,
      "type": "boolean"
    },
    "fps": {
      "title": "FPS",
      // "default": 30.0,
      "type": "number"
    },
    "stream_delay_seconds": {
      "title": "Stream Delay Seconds",
      // "default": 0.0,
      "type": "number"
    },
    "alarm_on_zone_enter": {
      "title": "Alarm On Zone Enter",
      // "default": false,
      "type": "boolean"
    },
    "wait_for_next_cls": {
      "title": "Wait For Next Classification",
      // "default": 0.4,
      "type": "number"
    },
    "classification_limit": {
      "title": "Classification Limit",
      // "default": 4,
      "type": "integer"
    },
    "match_tracklet_trajectories": {
      "title": "Match Tracklet Trajectories",
      // "default": false,
      "type": "boolean"
    },
    // "evidence": {
    //   "title": "Evidence",
    //   // "default": "./evidence",
    //   "type": "string"
    // },
    "save_evidence": {
      "title": "Save Evidence",
      // "default": true,
      "type": "boolean"
    },
    "save_video": {
      "title": "Save Video",
      "type": "string"
    },
    "save_non_alarms": {
      "title": "Save Non Alarms",
      // "default": false,
      "type": "boolean"
    },
    "save_test_images": {
      "title": "Save Test Images",
      // "default": false,
      "type": "boolean"
    },
    "save_payment_session": {
      "title": "Save Payment Session",
      // "default": false,
      "type": "boolean"
    },
    "write_all_tracklets_in_cvat": {
      "title": "Write all tracklets Cvat",
      // "default": false,
      "type": "boolean"
    },
    "display": {
      "title": "Display",
      // "default": false,
      "type": "boolean"
    },
    "visualiser_rtsp_stream": {
      "title": "Stream RTSP",
      // "default": false,
      "type": "boolean"
    },
    "fullscreen": {
      "title": "Fullscreen",
      // "default": false,
      "type": "boolean"
    },
    "display_width": {
      "title": "Display Width",
      // "default": 1920,
      "type": "integer"
    },
    "display_height": {
      "title": "Display Height",
      // "default": 1080,
      "type": "integer"
    },
    "visualize_detections": {
      "title": "Visualize Detections",
      // "default": false,
      "type": "boolean"
    },
    "visualize_anomalies": {
      "title": "Visualize Anomalies",
      // "default": false,
      "type": "boolean"
    },
    "blur_faces_with_kp": {
      "title": "Blur faces with key points",
      // "default": false,
      "type": "boolean"
    },
    "save_display": {
      "title": "Save Display",
      // "default": false,
      "type": "boolean"
    },
    "http_proxy": {
      "title": "Http Proxy",
      "type": "string"
    },
    "app_type": {
      "title": "App Type",
      // "default": "sco",
      "type": "string"
    },
    "multi_item_support": {
      "title": "Multi Item Support",
      // "default": true,
      "type": "boolean"
    },
    "discard_nonscans_in_trigger_zone": {
      "title": "Discard Nonscans in Trigger Zone",
      // "default": true,
      "type": "boolean"
    },
    "disable_trigger_zone": {
      "title": "Disable Trigger Zone",
      // "default": true,
      "type": "boolean"
    },
    "scale_active": {
      "title": "Scale Active",
      // "default": false,
      "type": "boolean"
    },
    "hand_scanner": {
      "title": "Hand Scanner",
      // "default": false,
      "type": "boolean"
    },
    "use_thor": {
      "title": "Use Thor",
      "type": "boolean"
    },
    "nonscan_reverification": {
      "title": "NonScan Reverification",
      // "default": false,
      "type": "boolean"
    },
    "histogram_similarity_threshold": {
      "title": "Histogram Similarity Threshold",
      // "default": 0.6,
      "type": "number"
    },
    "nonscan_crop_buffer": {
      "title": "NonScan Crop Buffer",
      "type": "integer"
    },
    "classify_basket_fullness": {
      "title": "Classify Basket Fullness",
      // "default": false,
      "type": "boolean"
    },
    "maximum_hand_distance": {
      "title": "Maximum Hand Distance",
      // "default": 0.1,
      "type": "number"
    },
    "classify_hot_products": {
      "title": "Classify Hot Products",
      // "default": false,
      "type": "boolean"
    },
    "hot_product_list_path": {
      "title": "Hot Product List Path",
      "type": "string"
    },
    "hot_product_thresh": {
      "title": "Maximum Hand Distance",
      // "default": 0.8,
      "type": "number"
    },
    "hot_product_crops_num": {
      "title": "Number of hot product crops",
      // "default": 5,
      "type": "integer"
    },
    "store_hot_product_crops": {
      "title": "Store hot product crops",
      // "default": false,
      "type": "boolean"
    },
    "hot_prod_bbox_movement_thresh": {
      "title": "Hot Product Bbox Movement Threshold",
      // "default": 0.01,
      "type": "number"
    },
    "maximum_tracker_overlap": {
      "title": "Maximum Tracker Overlap",
      // "default": 0.03,
      "type": "number"
    },
    "number_of_tracker_instances": {
      "title": "Number of Tracker Instances",
      // "default": 2,
      "type": "integer"
    },
    "tracklet_jump": {
      "title": "Tracklet Jump",
      // "default": true,
      "type": "boolean"
    },
  },
  "required": []
};

export {scoConfigSchema};
