const evidenceVideoConfigSchema = {
  title: "EvidenceVideoConfig",
  type: "object",
  properties: {
    fps: {
      title: "FPS",
      // default: 30.0,
      type: "number"
    },
    incremental_frame_ids: {
      title: "Incremental Frame Ids",
      type: "boolean"
    },
    loop_input_file: {
      title: "Loop Input File",
      // default: false,
      type: "boolean"
    },
    fps_input_file: {
      title: "FPS Input File",
      // default: 30.0,
      type: "number"
    },
    cam_width: {
      title: "Cam Width",
      // default: 1920,
      type: "integer"
    },
    cam_height: {
      title: "Cam Height",
      // default: 1080,
      type: "integer"
    },
  },
  required: []
};

export {evidenceVideoConfigSchema};
