const classifierConfigSchema = {
  title: "Classifier Params",
  type: "object",
  properties: {
    device: {
      title: "Device (CPU/GPU ID)",
      type: "string",
      allowedValues: [
        "cpu",
        "gpu:0",
        "gpu:1",
        "gpu:2",
        "gpu:3",
        "gpu:4"
      ]
    }
  },
  required: ["device"]
};

export {classifierConfigSchema};
