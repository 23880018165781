import React from "react";
import PropTypes from "prop-types";
// react plugin for creating vector maps

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import CheckoutAllCarts from "components/Charts/Checkout/CheckoutAllCarts.jsx";
import CheckoutCartsPerHour from "components/Charts/Checkout/CheckoutCartsPerHour.jsx";

import { SessionContext } from "layouts/Dashboard.jsx";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

class CheckoutDashboard extends React.Component {
  render() {
    return (
      <div style={{ marginTop: "1%", marginBottom: "7%" }}>
        <GridContainer>
          <CheckoutCartsPerHour />
          <CheckoutAllCarts />
        </GridContainer>
      </div>
    );
  }
}

CheckoutDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

function withSessionContext(Component) {
  // can this be moved out?
  return function WrapperComponent(props) {
    return (
      <SessionContext.Consumer>
        {(state) => <Component {...props} context={state} />}
      </SessionContext.Consumer>
    );
  };
}

export default withSessionContext(withStyles(dashboardStyle)(CheckoutDashboard));
