const boxShadow = {
  boxShadow:
    "0 10px 30px -12px rgba(0, 0, 0, 0.05), 0 4px 25px 0px rgba(0, 0, 0, 0.1), 0 8px 10px -5px rgba(0, 0, 0, 0.05)"
};

const customSelectStyle = {
  select: {
    // padding: "8px 0 7px",
    // margin: "8px 0 7px",
    // paddingTop: "2%",
    fontFamily: "'BrandFont', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: "14px",
    // fontWeight: "bold",
    lineHeight: "1.42857",
    textDecoration: "none",
    // textTransform: "uppercase",
    color: "#222222",
    letterSpacing: "0",
    "&:focus": {
      backgroundColor: "transparent"
    },
    "&[aria-owns] + input + svg": {
      transform: "rotate(180deg)"
    },
    "& + input + svg": {
      transition: "all 100ms linear"
    }
  },
  selectFormControl: {
    // margin: "10px 1px 10px 0px !important",
    "& > div": {
      "&:before": {
        borderBottomWidth: "1px !important",
        borderBottomColor: "#444444 !important"
      },
      "&:after": {
        borderBottomColor: "#333333 !important"
      }
    }
  },
  selectLabel: {
    fontSize: "14px",
    // textTransform: "uppercase",
    fontFamily: "'Fira Mono', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    color: "#222222 !important",
    fontWeight: "bold",
    top: "4px",
    bottom: "auto",
  },
  selectMenu: {
    "& > div > ul": {
      border: "0",
      padding: "5px 0",
      margin: "0",
      boxShadow: "none",
      minWidth: "100%",
      borderRadius: "2px",
      boxSizing: "border-box",
      display: "block",
      fontSize: "14px",
      textAlign: "left",
      listStyle: "none",
      backgroundColor: "transparent",
      overflowY: "auto",
      overflowX: "hidden",
      // backgroundColor: "#fff",
      backgroundClip: "padding-box"
    },
    "& $selectPaper $selectMenuItemSelectedMultiple": {
      backgroundColor: "inherit"
    },
    "& > div + div": {
      maxHeight: "266px !important"
      // maxHeight: "40% !important"
    }
  },
  selectMenuItem: {
    fontSize: "14px",
    fontFamily: "'BrandFont', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    padding: "10px 15px",
    margin: "0 5px",
    borderRadius: "2px",
    transition: "all 50ms linear",
    display: "block",
    clear: "both",
    // fontWeight: "300",
    // lineHeight: "2",
    // whiteSpace: "nowrap",
    // backgroundColor: "transparent",
    color: "#222222",
    paddingRight: "30px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: "#111111",
      ...boxShadow
    }
  },
  selectMenuItemSelected: {
    backgroundColor: "#FFFFFF !important",
    ...boxShadow,
    color: "#111111",
    fontWeight: "400"
  },
  selectMenuItemSelectedMultiple: {
    backgroundColor: "transparent !important",
    "&:hover": {
      backgroundColor: "#FFFFFF !important",
      color: "#FFFFFF",
      ...boxShadow,
      "&:after": {
        color: "#FFFFFF"
      }
    },
    "&:after": {
      top: "16px",
      right: "12px",
      width: "12px",
      height: "5px",
      borderLeft: "2px solid currentColor",
      // transform: "rotate(-45deg)",
      opacity: "0.9",
      color: "rgb(69, 124, 227)",
      position: "absolute",
      content: "''",
      borderBottom: "1px solid currentColor",
      transition: "opacity 90ms cubic-bezier(0,0,.2,.1)"
    }
  },
  selectPaper: {
    boxSizing: "borderBox",
    borderRadius: "2px",
    padding: "0",
    minWidth: "100%",
    display: "block",
    border: "0",
    boxShadow: boxShadow,
    backgroundClip: "padding-box",
    margin: "2px 0 0",
    fontSize: "14px",
    textAlign: "left",
    listStyle: "none",
    backgroundColor: "transparent",
    maxHeight: "266px"
  }
};

export default customSelectStyle;
