const evidenceParamsSchema = {
    "title": "Evidence Params",
    "description": "Schema for evidence params",
    "type": "object",
    "properties": {
        "save_extra_seconds": {
            "title": "Save Extra Seconds",
            "default": 0,
            "type": "integer"
        },
        "save_video": {
            "title": "Save video",
            "default": false,
            "type": "boolean"
        },
    }
};
export {evidenceParamsSchema};
