import React from "react";
// import Icon from "@material-ui/core/Icon";
import withStyles from "@material-ui/core/styles/withStyles";
import CardHeader from "components/Card/CardHeader.jsx";
import { SessionContext } from "layouts/Dashboard.jsx";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import PropTypes from "prop-types";
import Api from "modules/api.js";
import withI18n from "modules/withI18n";

class IncidentCount extends React.PureComponent {
  constructor(props) {
    super(props);
    const { t } = props;

    this._isMounted = false;
    this.state = {
      incidentCount: {
        count: 0,
        description: t("incident_count.incidents"),
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.context.device !== prevProps.context.device ||
      this.props.context.timespan !== prevProps.context.timespan ||
      this.props.t !== prevProps.t
    ) {
      this._isMounted &&
        this.loadData(this.props.context.device, this.props.context.timespan);
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this._isMounted &&
      this.loadData(this.props.context.device, this.props.context.timespan);
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  loadData(device, timespan) {
    const { t } = this.props;

    Api.get("/api/statistics/incident_count", {
      device_id: device,
      timespan: timespan,
    }).then((res) => {
      if (this._isMounted) {
        if (
          this.props.context.device === device &&
          this.props.context.timespan === timespan
        ) {
          this.setState({
            incidentCount: {
              count: res.data.count,
              description: t("incident_count.incidents"),
            },
          });
        }
      }
    });
  }

  render() {
    return (
      <CardHeader
        style={{ background: "#ffffff", width: "auto", marginBottom: "-10.8%" }}
      >
        <p
          style={{
            fontFamily:
              "\"BrandFont\", \"Montserrat\", \"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
            color: "#222222",
            fontWeight: "bold",
            fontSize: "16px",
            textAlign: "right",
            // marginBottom: "-1%",
          }}
        >
          {this.state.incidentCount.count} {"   "}
          {this.state.incidentCount.description}
        </p>
      </CardHeader>
    );
  }
}

function withSessionContext(Component) {
  return function WrapperComponent(props) {
    return (
      <SessionContext.Consumer>
        {(state) => <Component {...props} context={state} />}
      </SessionContext.Consumer>
    );
  };
}


IncidentCount.propTypes = {
  classes: PropTypes.object.isRequired,
  context: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withI18n(withSessionContext(withStyles(dashboardStyle)(IncidentCount)));
