import withStyles from "@material-ui/core/styles/withStyles";
import Lock from "@material-ui/icons/Lock";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";

import Api from "modules/api.js";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

class UserSettings extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      newEmail: "",
      newPassword: "",
      user: { email: "" },
      newEmailState: "error",
      newPasswordState: "error",
      error: "",
    };

    this.updatePassword = this.updatePassword.bind(this);
    this.updateEmail = this.updateEmail.bind(this);
    this.updateUserSettings = this.updateUserSettings.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadData() {
    Api.get("/api/user/currentuser").then((res) => {
      if (this._isMounted) {
        this.setState({
          user: res.data,
          newEmail: res.data.email,
        });
      }
    });
  }

  updateUserSettings() {
    if (this.state.newPasswordState === "success") {
      this.updatePassword();
    }
    if (this.state.newEmailState === "success") {
      this.updateEmail();
    }
  }

  updatePassword() {
    Api.post("/api/user/update_password", {
      email: this.state.user.email,
      password: this.state.newPassword,
    }).then(() => {
      this.loadData();
    });
  }

  updateEmail() {
    Api.post("/api/user/update_email", {
      old_email: this.state.user.email,
      new_email: this.state.newEmail,
    }).then(() => {
      this.loadData();
    });
  }

  verifyLength(value, length) {
    return value.length >= length;
  }

  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return emailRex.test(value);
  }

  change(event, stateName, type) {
    switch (type) {
    case "email":
      this.setState({ [stateName]: event.target.value });
      if (
        this.verifyEmail(event.target.value) &&
          event.target.value !== this.state.user.email
      ) {
        this.setState({ [stateName + "State"]: "success" });
      } else {
        this.setState({ [stateName + "State"]: "error" });
      }
      break;
    case "password":
      this.setState({ [stateName]: event.target.value });
      if (this.verifyLength(event.target.value, 4)) {
        this.setState({ [stateName + "State"]: "success" });
      } else {
        this.setState({ [stateName + "State"]: "error" });
      }
      break;
    default:
      break;
    }
  }
  render() {
    const { classes } = this.props;
    const { error } = this.state;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Card
              style={{
                width: "65%",
                marginRight: "auto",
                marginLeft: "auto",
              }}
            >
              <CardBody
                style={{
                  width: "85%",
                  marginRight: "auto",
                  marginLeft: "auto",
                  paddingTop: "4%",
                  paddingBottom: "5%",
                }}
              >
                <h3 className={classes.cardIconTitle}>Account Settings</h3>
                <br />
                {error && <p className="error-message">{error}</p>}
                <CustomInput
                  success={this.state.newEmailState === "success"}
                  error={this.state.newEmailState === "error"}
                  labelText="Email Address"
                  id="loginemail"
                  // defaultValue="foo"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.newEmail,
                    onChange: (event) =>
                      this.change(event, "newEmail", "email"),
                    type: "email",
                  }}
                />
                <CustomInput
                  success={this.state.newPasswordState === "success"}
                  error={this.state.newPasswordState === "error"}
                  labelText="New Password"
                  id="newpassword"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) =>
                      this.change(event, "newPassword", "password"),
                    type: "password",
                  }}
                />
                <GridContainer
                  style={{
                    width: "95%",
                    marginRight: "auto",
                    marginLeft: "auto",
                    paddingBottom: "1%",
                    paddingTop: "1%",
                  }}
                />
                <GridContainer
                  style={{
                    width: "100%",
                    paddingTop: "2%",
                    marginBottom: "2%",
                  }}
                >
                  <GridItem xs={12}>
                    <Button
                      style={{
                        width: "50%",
                        marginRight: "auto",
                        marginLeft: "27.5%",
                        paddingBottom: "3%",
                        paddingTop: "3%",
                      }}
                      disabled={
                        !(
                          this.state.newEmailState === "success" ||
                          this.state.newPasswordState === "success"
                        )
                      }
                      onClick={this.updateUserSettings}
                      color={
                        this.state.newEmailState === "success" ||
                        this.state.newPasswordState === "success"
                          ? "success"
                          : "error"
                      }
                      className={classes.updateConfigButton}
                    >
                      {this.state.newEmailState === "success" ||
                      this.state.newPasswordState === "success" ? (
                          "Save"
                        ) : (
                          <Lock />
                        )}
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

UserSettings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(userProfileStyles)(UserSettings);
