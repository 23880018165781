import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Pause from "@material-ui/icons/Pause";
import Play from "@material-ui/icons/PlayArrow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import Api from "modules/api.js";
const CustomTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#f6f6f6",
    fontSize: 18,
    color: "#444444",
  },
  body: {
    fontSize: 15,
    color: "#444444",
  },
}))(TableCell);

class DeviceStatusPage extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      devices: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadData() {
    Api.get("/api/devices").then((res) => {
      if (this._isMounted) {
        this.setState({
          devices: res.data,
        });
      }
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div
        style={{
          width: "88%",
          marginTop: "3%",
          marginBottom: "auto",
          marginRight: "auto",
          marginLeft: "auto",
        }}
        className={classes.tableResponsive}
      >
        <Paper className={classes.root}>
          <Table className={classes.table} stickyHeader>
            <TableHead>
              <TableRow>
                <CustomTableCell
                  align="center"
                  style={{
                    paddingRight: 0,
                  }}
                />
                <CustomTableCell>
                  <h6>
                    <b>store</b>
                  </h6>
                </CustomTableCell>
                <CustomTableCell>
                  <h6>
                    <b>view</b>
                  </h6>
                </CustomTableCell>
                <CustomTableCell align="right">
                  <h6>
                    <b>hostname</b>
                  </h6>
                </CustomTableCell>
                <CustomTableCell align="right">
                  <h6>
                    <b>should be online</b>
                  </h6>
                </CustomTableCell>
                <CustomTableCell align="right">
                  <h6>
                    <b>last seen</b>
                  </h6>
                </CustomTableCell>
                <CustomTableCell align="right">
                  <h6>
                    <b>should send send events</b>
                  </h6>
                </CustomTableCell>
                <CustomTableCell align="right">
                  <h6>
                    <b>last notification</b>
                  </h6>
                </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.devices.map((device) => (
                <TableRow key={device.id}>
                  <TableCell align="center" style={{ padding: "1%" }}>
                    {device.is_online ? (
                      device.has_issues ? (
                        <FiberManualRecord style={{ color: "orange" }} /> // case has issues
                      ) : (
                        <FiberManualRecord style={{ color: "#00F000" }} /> // case is online
                      )
                    ) : device.sending_updates ? (
                      <FiberManualRecord style={{ color: "#C80000" }} /> // case is known to be offline
                    ) : (
                      <FiberManualRecord style={{ color: "#333333" }} /> // case is unknown to be offline
                    )}
                  </TableCell>
                  <TableCell component="th" scope="device">
                    <b>{device.location}</b>
                  </TableCell>
                  <TableCell>{device.name}</TableCell>
                  <TableCell align="right">{device.hostname}</TableCell>
                  <TableCell align="center" style={{ padding: "1%" }}>
                    {device.should_be_online ? (<Play />) : (<Pause />)}
                  </TableCell>
                  <TableCell align="center" style={{ padding: "1%" }}>{device.last_seen ? device.last_seen : "—"}</TableCell>
                  <TableCell align="center" style={{ padding: "1%" }}>
                    {device.should_send_events ? (<Play />) : (<Pause />)}
                  </TableCell>
                  <TableCell align="center" style={{ padding: "1%" }}>{device.last_notification_on_issues ? device.last_notification_on_issues : "—"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

DeviceStatusPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(DeviceStatusPage);
