import React from "react";
import { AutoForm } from "uniforms-material";
import { JSONSchemaBridge } from "uniforms-bridge-json-schema";
import { NotificationManager } from "react-notifications";
import {createValidator} from "variables/schemas.jsx";


function definitionForm(mySchema, myID, myDefinition, updateFunction) {
  if (myID && myDefinition) {
    if (!mySchema){
      throw new Error("No Schema provided");
    }
    try {
      const validator = createValidator(mySchema, NotificationManager);
      const bridge = new JSONSchemaBridge(mySchema, validator);
      return (
        <div>
          <br />
          <AutoForm
            style={{float: "center", margin: "2%", overflow: "visible"}}
            schema={bridge}
            disabled={false}
            model={myDefinition}
            showInlineError
            onSubmit={(model) => updateFunction(myID, model)}
          />
        </div>
      );
    } catch (error) {
      console.error(error);
      return (<>
        <span>Could not load Form</span>
        <br />
        <pre>{myDefinition ? JSON.stringify(myDefinition, 2) : ""}</pre>
        <br />
        <pre>{error ? JSON.stringify(error, 2) : ""}</pre>
      </>);
    }
  } else {
    return <></>;
  }
}

export {definitionForm};
