import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

import Api from "modules/api.js";

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      Api.isUserAuthenticated() === false ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

PublicRoute.propTypes = {
  path: PropTypes.string,
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.elementType
  ])
};

export default PublicRoute;
