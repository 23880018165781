import React, { useState } from "react";

import Button from "components/CustomButtons/Button.jsx";
import Clear from "@material-ui/icons/Clear";
import Overlay from "react-overlay-component";
// import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

function DeleteConfirmOverlayButton({ ...props }) {
  const [isOpen, setOverlay] = useState(false);
  const {
    item,
    deleteFunction,
    ...rest
  } = props;

  if (item) {
    const configs = {
      animate: true,
      clickDismiss: true,
      escapeDismiss: true,
      focusOutline: true,
      contentClass: "overlay-container"
    };
    const closeOverlay = () => setOverlay(false);

    return (
      <div {...rest} style={{ width: "9%" }} >
        <Button
          style={{ float: "left", marginLeft: "-20%", paddingLeft: "40%", paddingRight: "30%", background:"#ef5350", boxShadow: "inset 5px 5px 8px 0 rgba(0, 0, 0, 0.05), inset -5px -5px 8px 0 rgba(255, 255, 255, 0.12)"}}
        onClick={() => {setOverlay(true)} }
        >
        <Clear style={{ float: "center", color: "#FFFFFF" }} />
        </Button>
        <div style={{ overflow: "visible"}}>
          <Overlay
            style={{backgroundColor: "rgba(50, 0, 50, 0.68"}}
            configs={configs}
            isOpen={isOpen}
            closeOverlay={closeOverlay}
          >
            <h4> Delete {item.name}? </h4>
            <br />
            This can not be reversed.
            <br />
            <br />
            {confirmedDeleteButton(item, deleteFunction, closeOverlay)}
            <br />
          </Overlay>
        </div>
      </div>
    )
  } else {
    return null
  }
};

function deleteItem(myDefinition, deleteFunction, closeOverlayFunction) {
  deleteFunction(myDefinition);
  closeOverlayFunction();
}

function confirmedDeleteButton(myDefinition, myDeleteFunction, closeOverlayFunction) {
  if (myDefinition) {
    return (
      <Button
        style={{ float: "middle", margin: "auto", width: "100%", background: "#000000", boxShadow: "inset 5px 5px 8px 0 rgba(90, 90, 90, 0.3), inset -5px -5px 8px 0 rgba(255, 255, 255, 0.2)"}}
        onClick={() => deleteItem(myDefinition, myDeleteFunction, closeOverlayFunction)}
      >
        Confirm Deletion
      </Button>
    )
  } else {
    return null
  }
}

DeleteConfirmOverlayButton.propTypes = {
  item: PropTypes.object.isRequired,
  deleteFunction: PropTypes.func.isRequired,
}

export {DeleteConfirmOverlayButton};
