import { NotificationManager } from "react-notifications";
import i18n from "i18next";

class Api {
  /**
   * Authenticate a user. Save a token string in Local Storage
   *
   * @param {string} token
   */

  static get(url, params = {}) {
    var token = this.isUserAuthenticated() ? this.getToken() : "";

    var esc = encodeURIComponent;
    var query =
      "?" +
      Object.keys(params)
        .map((k) => esc(k) + "=" + esc(params[k]))
        .join("&");

    return fetch(url + query, {
      ...Api.API_HEADERS_AND_MODE,
      ...{
        headers: { Authorization: token, "Accept-Language": i18n.language },
        method: "GET",
      },
    }).then((res) => {
      if (res.status === 401) {
        this.deauthenticateUser();
        window.location = "/login";
        return;
      }
      if (res.status >= 400) {
        return this.handleError(res);
      }
      return res.json();
    });
  }

  static post(url, body) {
    var token = this.isUserAuthenticated() ? this.getToken() : "";

    var cfg = Api.API_HEADERS_AND_MODE;
    cfg.headers.Authorization = token;
    cfg.headers["Accept-Language"] = i18n.language;
    return fetch(url, {
      ...cfg,
      ...{
        body: JSON.stringify(body),
        method: "POST",
      },
    }).then((res) => {
      if (res.status === 401) {
        this.deauthenticateUser();
        window.location = "/login";
        return;
      }
      if (res.status >= 400) {
        return this.handleError(res);
      }
      return res.json();
    });
  }

  static put(url, body) {
    var token = this.isUserAuthenticated() ? this.getToken() : "";

    var cfg = Api.API_HEADERS_AND_MODE;
    cfg.headers.Authorization = token;
    cfg.headers["Accept-Language"] = i18n.language;
    return fetch(url, {
      ...cfg,
      ...{
        body: JSON.stringify(body),
        method: "PUT",
      },
    }).then((res) => {
      if (res.status === 401) {
        this.deauthenticateUser();
        window.location = "/login";
        return;
      }
      if (res.status >= 400) {
        return this.handleError(res);
      }
      return res.json();
    });
  }

  static login(email, password) {
    return this.post("/api/auth/login", {
      email: email,
      password: password,
    }).then((data) => {
      this.authenticateUser(data.Authorization);
      if (!this.isUserAuthenticated()) {
        throw new Error("Token not saved!");
      }
    });
  }

  static handleError(res) {
    return res
      .clone()
      .json()
      .catch(() => {
        return res.text().then(function (text) {
          NotificationManager.error(text, "An Error has occured!");
          throw text;
        });
      })
      .then((err) => {
        if (err && err.message) {
          NotificationManager.error(err.message, "Error");
        }
        throw err;
      });
  }


  /**
   * Check if a user is authenticated - check if a token is saved in Local Storage
   *
   * @returns {boolean}
   */
  static isUserAuthenticated() {
    return localStorage.getItem("token") !== null;
  }

  /**
   * Deauthenticate a user. Remove a token from Local Storage.
   *
   */
  static deauthenticateUser() {
    localStorage.removeItem("token");
  }

  /**
   * Get a token value.
   *
   * @returns {string}
   */
  static getToken() {
    return localStorage.getItem("token");
  }

  static authenticateUser(token) {
    localStorage.setItem("token", token);
  }

  static getCurrentDevice() {
    return localStorage.getItem("device");
  }

  static setCurrentDevice(hostname) {
    //takes the devices hostname as string
    return localStorage.setItem("device", hostname);
  }
}

Api.API_HEADERS_AND_MODE = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  },
  mode: "cors"
};

export default Api;
